import { useMemo } from 'react';
import { Center, Container, Flex, HStack, Image, ModalBody, ModalContent, Spinner, VStack } from '@chakra-ui/react';
import { useAppDispatch } from 'hooks/store';
import { useAppNavigate } from 'hooks/useAppNavigate';
import { initializeRestart } from 'slices/updateSlice';

import { MainButton } from 'components/buttons/MainButton';
import { MainHeading } from 'components/texts/MainHeading';
import { TranslateText } from 'components/texts/TranslateText';

import { ModalProps } from '../Modal';

interface ErrorModalProps extends ModalProps {
  heading: string;
  description: string;
  description2?: string;
  imageSrc: string;
  id?: string;
  uploadData?: boolean;
  serviceButton?: boolean;
  endTrainingButton?: boolean;
  restartButton?: boolean;
  dataUploaded?: boolean;
  closeButton?: boolean;
  onExit: () => void;
}

export const ErrorModal = ({
  heading,
  imageSrc,
  id,
  description,
  description2,
  uploadData = false,
  serviceButton = false,
  endTrainingButton = true,
  restartButton = false,
  dataUploaded = false,
  closeButton = false,
  onExit,
  close,
}: ErrorModalProps) => {
  const navigate = useAppNavigate();
  const dispatch = useAppDispatch();

  const actions = useMemo(() => {
    const actions: {
      text: string;
      action: () => void;
    }[] = [];

    if (endTrainingButton) {
      actions.push({
        text: 'modals.alerts.exit',
        action: () => {
          onExit();
          close();
          navigate('/', { replace: true });
        },
      });
    }
    if (serviceButton) {
      actions.push({
        text: 'modals.alerts.deviceDisconnected.action',
        action: () => {
          close();
          navigate(`/specialist/help?errorCode=deviceDisconnected&id=${id}`);
        },
      });
    }
    if (restartButton) {
      actions.push({
        text: 'modals.alerts.uploadError.action',
        action: () => {
          dispatch(initializeRestart(id as 'software' | 'firmware'));
        },
      });
    }
    if (closeButton) {
      actions.push({
        text: 'update.close',
        action: () => {
          close();
        },
      });
    }
    return actions;
  }, [endTrainingButton, serviceButton, restartButton, closeButton, onExit, close, navigate, id, dispatch]);

  return (
    <ModalContent maxW={{ base: 'xl', '2xl': '4xl' }} borderRadius="rLg" p="2">
      <ModalBody borderWidth="1" borderStyle="solid" borderColor="errorColor" borderRadius="rLg">
        <Container
          variant="modalBox"
          w="full"
          minH={{ base: 'xs', '2xl': 'lg' }}
          display="flex"
          position="relative"
          py="4"
        >
          <VStack flex="1 0" justify="space-between">
            <Center>
              <MainHeading variant="subheading36" text={heading} />
            </Center>
            <Image src={imageSrc} maxH={{ base: '3xs', '2xl': 'sm' }} maxW={{ base: '3xs', '2xl': 'sm' }} mt="4" />
            <VStack gap="2" textAlign="center">
              {description && <TranslateText variant="openSans24" text={description} />}
              {description2 && <TranslateText variant="openSans24Bold" text={description2} />}
            </VStack>
            <Flex h="8" w="full" justifyContent="center" gap="4">
              {!dataUploaded && uploadData ? (
                <>
                  <Spinner thickness="1" speed="0.65s" emptyColor="white" color="egzotechPrimaryColor" boxSize="6" />
                  <TranslateText
                    color="egzotechPrimaryColor"
                    variant="openSans20"
                    fontSize="xl"
                    text="modals.completedExercise.saving"
                  />
                </>
              ) : null}
            </Flex>
            <HStack justifyContent="space-evenly" w="full">
              {actions.map((item, idx) => (
                <MainButton
                  key={item.text}
                  text={item.text}
                  isDisabled={!dataUploaded && uploadData}
                  variant={idx === actions.length - 1 ? 'mdErrorButton' : 'mdOutlinedErrorButton'}
                  onClick={item.action}
                  minW="32"
                />
              ))}
            </HStack>
            {id ? (
              <Center position="absolute" top="-1" left="0" right="0">
                <TranslateText variant="openSans16" color="gray.300">
                  {id}
                </TranslateText>
              </Center>
            ) : null}
          </VStack>
        </Container>
      </ModalBody>
    </ModalContent>
  );
};
