import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type DeviceState = {
  serialNumber: string | null;
  canDisconnectFromDevice: boolean;
};

const initialState: DeviceState = {
  serialNumber: null,
  canDisconnectFromDevice: true,
};

const deviceSlice = createSlice({
  name: 'device',
  initialState,
  reducers: {
    setSerialNumber: (state, { payload }: PayloadAction<string>) => {
      state.serialNumber = payload;
    },
    allowDisconnectingFromDevice: state => {
      state.canDisconnectFromDevice = true;
    },
    disallowDisconnectingFromDevice: state => {
      state.canDisconnectFromDevice = false;
    },
  },
});

export const { setSerialNumber, allowDisconnectingFromDevice, disallowDisconnectingFromDevice } = deviceSlice.actions;

export default deviceSlice.reducer;
