import { GeneratedEMGGameExerciseDefinition } from '../../../types/GeneratedExerciseDefinition';

export const getPredefinedStellaBioGameEMGCosmicMissionExercises = (): {
  [key: string]: GeneratedEMGGameExerciseDefinition;
} => ({
  game_cosmic_mission_emg_view_mvc: {
    calibrationFlow: 'cosmic-mission/stella',
    presentationTemplate: 'time-increment',
    tags: ['cosmic-mission'],
    type: 'emg-game',
    emg: {
      program: {
        minRequiredChannels: 1,
        maxSupportedChannels: 8,
        allowedCableChannelFeatures: ['emg', 'emg-pelvic'],
        emgCalibration: true,
        hasGuideline: false,
        steps: [],
      },
    },
    game: {
      id: 'cosmic_mission',
      program: {
        phases: [
          {
            speed: 100,
            routeWidth: 1,
            oponent: 'no',
            gameSteeringMode: 'normalControl',
          },
        ],
      },
      parameters: {
        phases: [
          {
            speed: {
              default: 100,
              unit: '%',
              values: [50, 75, 100, 125, 150],
              blockAfterStart: false,
            },
            routeWidth: {
              default: 1,
              unit: 'number',
              values: [0.5, 0.75, 1, 1.25, 1.5],
              blockAfterStart: false,
            },
            oponent: {
              default: 'no',
              unit: 'none',
              values: ['yes', 'no'],
              blockAfterStart: false,
            },
            gameSteeringMode: {
              default: 'normalControl',
              unit: 'none',
              values: ['normalControl', 'invertControl'],
              blockAfterStart: false,
            },
          },
        ],
      },
    },
  },
});
