import { apiRawFetch } from 'config/api';
import {
  CalendarResponseDTO,
  ChatMessageCreateDTO,
  ChatMessageFilterDTO,
  ChatMessageResponseDTO,
  ConsentsDTO,
  ConversationResponseDTO,
  EmailChangeDTO,
  FacilityPasswordSetDTO,
  FacilitySpecialistFilterDTO,
  FacilitySpecialistInvitationResponseDTO,
  FacilitySpecialistInviteDTO,
  FacilitySpecialistPatientFilterDTO,
  FacilitySpecialistResponseDTO,
  FavoriteTrainingTemplateManagingDTO,
  FromToDTO,
  Page,
  PasswordChangeDTO,
  PasswordResetByMasterPassword,
  PasswordResetRequestDTO,
  RecipientInfoResponseDTO,
  SpecialistUserProfileCreateDTO,
  TrainingDayMoveDTO,
  UserProfileResponseDTO,
  UserProfileUpdateDTO,
  UserResponseDTO,
} from 'types';
import { Language } from 'types/language';
import { SetNewPasswordType } from 'views/password/set';
import { RegisterProp } from 'views/register/specialist';

import { BackendApiBase } from './BackendApiBase';
import { EGZOTechHostApi } from './EGZOTechHostApi';
import { ExoClinicBackendOpenApiPaths, ExoClinicBackendOpenApiSchemas } from './ExoClinicBackendOpenApi';
import { mockedApi } from './mockedApi';
import { OpenApiRESTClient } from './OpenAPIRESTClient';
import { RESTClient } from './RESTClient';
import { prepareURLParams } from './URLParams';

export type AppSettingsPayload = {
  language?: Language | null;
  disableModuleIdentificationOnLost?: boolean;
  disableModuleIncompatibilityCheck?: boolean;
  disableExtensionDetached?: boolean;
};

export type Paged<T> = T & {
  pageSize?: number;
  pageNumber?: number;
};

export type AllowStringArrayAsSeparatedString<T extends object> = {
  [key in keyof T]: Required<T>[key] extends string[] ? Exclude<T[key], string[]> | string : T[key];
};

export type WithOptionalProperty<T, TKey extends keyof T> = Omit<T, TKey> & Partial<T>;

export class ResponseError extends Error {
  constructor(readonly response: Response) {
    super('The request response has resulted in an error.');
  }
}

export class ExoClinicBackendApi extends BackendApiBase {
  private readonly openApiClient: OpenApiRESTClient<ExoClinicBackendOpenApiPaths>;
  private readonly client: RESTClient;

  account = {
    resetPassword: async (body: PasswordResetRequestDTO) => {
      await this.client.post(`/reset-password`, body);
    },

    changePassword: async (body: PasswordChangeDTO) => {
      await this.client.put(`/user/password`, body);
    },

    changePassword2: async (body: SetNewPasswordType) => {
      await this.client.put(`reset-password/change-password`, body);
    },

    changeEmail: async (body: EmailChangeDTO) => {
      await this.client.post(`/account/email`, body);
    },

    commitChangeEmail: async (token: string) => {
      await this.client.put(`account/email/${token}`);
    },
    resetPasswordByMaster: async (data: PasswordResetByMasterPassword) => {
      await this.client.put(`user/master-password`, data);
    },
  };
  database = {
    importStatus: async () =>
      this.extractJsonBody(
        this.client.get<ExoClinicBackendOpenApiSchemas['ImportDatabaseStatusResponseDto']>('db/import'),
      ),
    startImport: () => this.client.post<ExoClinicBackendOpenApiSchemas['ExportDatabaseStatusResponseDto']>(`db/import`),
    stopImport: () => this.client.delete(`db/import`),
    exportStatus: () =>
      this.extractJsonBody(
        this.client.get<ExoClinicBackendOpenApiSchemas['ExportDatabaseStatusResponseDto']>('db/export'),
      ),
    startExport: () => this.client.post<ExoClinicBackendOpenApiSchemas['ExportDatabaseStatusResponseDto']>(`db/export`),
    restartAfterImport: () => this.client.post<{ description: 'Success' }>(`db/import/restart`),
  };

  conversations = {
    all: (params?: Paged<object>) =>
      this.extractJsonBody(this.client.get<Page<ConversationResponseDTO>>(`conversations`, prepareURLParams(params))),

    single: (conversationId: string) =>
      this.extractJsonBody(this.client.get<ConversationResponseDTO>(`conversations/${conversationId}`)),

    create: (userProfileId: string, message: ChatMessageCreateDTO) =>
      this.extractJsonBody(
        this.client.post<ConversationResponseDTO>(`/conversations/with-user/${userProfileId}`, message),
      ),

    leadSpecialist: () => this.extractJsonBody(this.client.get<string>(`/conversations/lead-specialist`)),

    recipent: (userProfileId: string) =>
      this.extractJsonBody(this.client.get<RecipientInfoResponseDTO>(`/conversations/recipient/${userProfileId}`)),

    conversation: (conversationId: string) => ({
      messages: (params?: Paged<object>) =>
        this.extractJsonBody(
          this.client.get<Page<ChatMessageResponseDTO>>(
            `conversations/messages/${conversationId}`,
            prepareURLParams(params),
          ),
        ),

      newMessages: (params: Paged<ChatMessageFilterDTO>) =>
        this.extractJsonBody(
          this.client.get<Page<ChatMessageResponseDTO>>(
            `conversations/messages/update/${conversationId}`,
            prepareURLParams(params),
          ),
        ),

      send: (message: ChatMessageCreateDTO) =>
        this.extractJsonBody(
          this.client.patch<ChatMessageResponseDTO>(`conversations/${conversationId}/messages`, message),
        ),
    }),
  };

  facilities = {
    create: async (body: ExoClinicBackendOpenApiSchemas['CreateFacilityRequestDto']) => {
      await this.openApiClient.post(`/facility`, body);
    },

    setPassword: async (body: FacilityPasswordSetDTO) => {
      await this.openApiClient.patch(`/facility/set-password`, body);
    },

    facility: (
      facilityId: ExoClinicBackendOpenApiPaths['/facility/{facilityId}/patients']['get']['parameters']['path']['facilityId'],
    ) => ({
      patients: (
        params?: ExoClinicBackendOpenApiPaths['/facility/{facilityId}/patients']['get']['parameters']['query'],
      ) =>
        this.extractJsonBody(
          this.client.get<ExoClinicBackendOpenApiSchemas['PatientResponseDtoPagedPayloadDto']>(
            `facility/${facilityId}/patients`,
            prepareURLParams(params),
          ),
        ),

      statistics: () =>
        this.extractJsonBody(
          this.client.get<ExoClinicBackendOpenApiSchemas['FacilityStatisticsResponseDto']>(
            `/facilities/statistics/${facilityId}`,
          ),
        ),
    }),
  };

  facilitySpecialists = {
    all: (facilityId: string, params: FacilitySpecialistFilterDTO) =>
      this.extractJsonBody(
        this.client.get<Page<FacilitySpecialistResponseDTO>>(
          `facility-specialists/${facilityId}`,
          prepareURLParams(params),
        ),
      ),

    invitations: (facilityId: string, params: FacilitySpecialistFilterDTO) =>
      this.extractJsonBody(
        this.client.get<Page<FacilitySpecialistInvitationResponseDTO>>(
          `facility-specialists/invitations/${facilityId}`,
          prepareURLParams(params),
        ),
      ),

    invite: (facilityId: string, body: FacilitySpecialistInviteDTO) =>
      this.extractJsonBody(
        this.client.post<Page<FacilitySpecialistInvitationResponseDTO>>(
          `facility-specialists/invitations/${facilityId}`,
          body,
        ),
      ),

    invitation: (invitationId: string) => ({
      resend: async () => {
        await this.client.post(`facility-specialists/invitations/resend/${invitationId}`, {});
      },

      withdraw: async () => {
        await this.client.post(`facility-specialists/invitations/withdraw/${invitationId}`, {});
      },

      accept: (body: RegisterProp) =>
        this.extractJsonBody(
          this.client.post<UserResponseDTO>(`facility-specialists/invitations/${invitationId}/accept`, body),
        ),
    }),
  };

  patient = {
    get: (userProfileId: string) =>
      this.extractJsonBody(
        this.client.get<ExoClinicBackendOpenApiSchemas['FacilityPatientInfoResponseDto']>(`patient/${userProfileId}`),
      ),

    update: (userProfileId: string, body: ExoClinicBackendOpenApiSchemas['UpdatePatientRequestDto']) =>
      this.extractJsonBody(this.openApiClient.patch('/patient/{patientId}', body, { patientId: userProfileId })),
  };

  facility = (facilityId: string) => ({
    createPatient: (body: ExoClinicBackendOpenApiSchemas['CreateNewPatientRequestDto']) =>
      this.extractJsonBody(this.openApiClient.post('/facility/{facilityId}/patient', body, { facilityId })),
    createAnonymousPatient: () =>
      this.extractJsonBody(
        this.openApiClient.post('/facility/{facilityId}/patient/anonymous', undefined, { facilityId }),
      ),
    patients: (params?: Paged<FacilitySpecialistPatientFilterDTO>) =>
      this.extractJsonBody(
        this.openApiClient.get('/facility/{facilityId}/specialist/patients', {
          facilityId,
          pageNumber: params?.pageNumber,
          pageSize: params?.pageSize,
          searchContent: params?.searchContent,
          patientFilterType: params?.patientFilterType as unknown as 'telemedicine' | 'stationary',
        }),
      ),
  });
  facilitySpecialistPatient = {
    // TODO: Not implemented in BE
    telemedicineInvitation: (body: {
      token: string;
      password: string;
      passwordRepeat?: string;
      consents?: ConsentsDTO;
    }) =>
      this.extractJsonBody(
        this.client.patch<UserResponseDTO>(`/facility-specialist-patients/telemedicine-invitation`, body),
      ),
    // TODO: Not implemented in BE
    userProfile: (userProfileId: string) => ({
      photo: (photo: File) => {
        const formData = new FormData();
        formData.append('photo', photo);

        return this.extractJsonBody(
          this.client.patch<UserResponseDTO>(`/facility-specialist-patients/photo/${userProfileId}`, formData),
        );
      },
    }),
  };
  info = {
    git: () => {
      return this.extractJsonBody(this.client.get<ExoClinicBackendOpenApiSchemas['GitInfoResponseDto']>(`/info/git`));
    },
    api: () => {
      return this.extractJsonBody(this.client.get<ExoClinicBackendOpenApiSchemas['ApiInfoResponseDto']>(`/info/api`));
    },
  };

  patientCards = {
    patientCard: (patientCardId: string) => ({
      edit: (body: ExoClinicBackendOpenApiPaths['/patient/{patientId}']['patch']['body']) =>
        this.extractJsonBody(
          this.client.patch<ExoClinicBackendOpenApiSchemas['PatientCardResponseDto']>(`patient/${patientCardId}`, body),
        ),
    }),

    comments: {
      getById: (commentId: number) =>
        this.extractJsonBody(
          this.client.get<ExoClinicBackendOpenApiSchemas['PatientCommentResponseDto']>(`patient/comment/${commentId}`),
        ),
      add: (patientId: string, body: ExoClinicBackendOpenApiPaths['/patient/comment/{patientId}']['post']['body']) =>
        this.extractJsonBody(
          this.client.post<ExoClinicBackendOpenApiSchemas['PatientCommentResponseDto']>(
            `patient/comment/${patientId}`,
            body,
          ),
        ),
      delete: async (commentId: number) => {
        await this.client.delete(`patient/comment/${commentId}`);
      },
    },
  };

  session = {
    login: (body: ExoClinicBackendOpenApiSchemas['LoginRequestDto']) =>
      this.extractJsonBody(this.openApiClient.post('/session/login', body)),
    logout: async () => {
      await this.client.delete('/session/logout');
    },
  };

  trainings = {
    //TODO: not implemented in BE
    userProfile: (userProfileId: string) => ({
      all: (params: FromToDTO) =>
        this.extractJsonBody(
          this.client.get<CalendarResponseDTO>(`trainings/${userProfileId}`, prepareURLParams(params)),
        ),

      //TODO: not implemented in BE
      moveFutureTrainings: (params: Partial<TrainingDayMoveDTO> & { numberOfDays: number }) =>
        this.extractJsonBody(
          this.client.post<ExoClinicBackendOpenApiSchemas['TrainingResponseDto'][]>(
            `/training/move-future-trainings/${userProfileId}`,
            undefined,
            prepareURLParams(params),
          ),
        ),

      //TODO: not implemented in BE
      moveTrainingDay: (body: TrainingDayMoveDTO) =>
        this.extractJsonBody(
          this.client.post<ExoClinicBackendOpenApiSchemas['TrainingResponseDto'][]>(
            `/training/move-training-day/${userProfileId}`,
            body,
          ),
        ),
    }),

    getById: (params: ExoClinicBackendOpenApiPaths['/training/{trainingId}']['get']['parameters']['path']) =>
      this.extractJsonBody<ExoClinicBackendOpenApiSchemas['TrainingResponseDto']>(
        this.client.get('/training/' + params.trainingId),
      ),

    create: (body: ExoClinicBackendOpenApiPaths['/training']['post']['body']) =>
      this.extractJsonBody(this.client.post<ExoClinicBackendOpenApiSchemas['TrainingResponseDto']>(`/training`, body)),

    update: (
      params: ExoClinicBackendOpenApiPaths['/training/{trainingId}']['patch']['parameters']['path'],
      body: ExoClinicBackendOpenApiPaths['/training/{trainingId}']['patch']['body'],
    ) =>
      this.extractJsonBody(
        this.client.patch<ExoClinicBackendOpenApiSchemas['TrainingResponseDto']>(
          `/training/${params.trainingId}`,
          body,
        ),
      ),

    //TODO: not implemented in BE
    moveTraining: (id: string, params: Partial<TrainingDayMoveDTO>) =>
      this.extractJsonBody(
        this.client.post<ExoClinicBackendOpenApiSchemas['TrainingResponseDto']>(
          `/training/move-training/${id}`,
          undefined,
          prepareURLParams(params),
        ),
      ),

    start: async (
      params: ExoClinicBackendOpenApiPaths['/training/{trainingId}/start']['patch']['parameters']['path'],
    ) => {
      await this.client.patch('/training/' + params.trainingId + '/start');
    },

    finish: async (
      params: ExoClinicBackendOpenApiPaths['/training/{trainingId}/start']['patch']['parameters']['path'],
    ) => {
      await this.client.patch('/training/' + params.trainingId + '/finish');
    },

    recent: (params: ExoClinicBackendOpenApiPaths['/training/recent/{userProfileId}']['get']['parameters']) =>
      this.extractJsonBody(
        this.client.get<ExoClinicBackendOpenApiSchemas['TrainingResponseDtoPagedPayloadDto']>(
          '/training/recent/' + params.path.userProfileId,
          prepareURLParams(params.query),
        ),
      ),
  };

  trainingTemplates = {
    all: async (params: ExoClinicBackendOpenApiPaths['/training-templates']['get']['parameters']['query']) =>
      this.extractJsonBody(
        this.client.get<ExoClinicBackendOpenApiSchemas['TrainingTemplateResponseDtoPagedPayloadDto']>(
          `/training-templates`,
          prepareURLParams(params),
        ),
      ),

    packages: () =>
      this.extractJsonBody(
        this.client.get<ExoClinicBackendOpenApiSchemas['ClassificationPackageResponseDto'][]>(
          `/training-templates/packages`,
        ),
      ),

    deviceTypes: () =>
      this.extractJsonBody(
        this.client.get<ExoClinicBackendOpenApiSchemas['DeviceTypeResponseDto'][]>(`/training-templates/device-types`),
      ),

    filters: () =>
      this.extractJsonBody(
        this.client.get<ExoClinicBackendOpenApiSchemas['TrainingTemplateFiltersResponseDto']>(
          `/training-templates/filters`,
        ),
      ),

    //TODO: not supported in BE
    favorite: (params: ExoClinicBackendOpenApiPaths['/training-templates']['get']['parameters']['query']) =>
      this.extractJsonBody(
        this.client.get<ExoClinicBackendOpenApiSchemas['TrainingTemplateResponseDtoPagedPayloadDto']>(
          `/training-templates/favorite`,
          prepareURLParams(params),
        ),
      ),

    //TODO: not supported in BE
    addFavorite: async (body: FavoriteTrainingTemplateManagingDTO) => {
      await this.client.patch(`training-templates/favorite/add`, body);
    },

    //TODO: not supported in BE
    removeFavorite: async (body: FavoriteTrainingTemplateManagingDTO) => {
      await this.client.patch(`training-templates/favorite/remove`, body);
    },
    icd10Diagnosis: () =>
      this.extractJsonBody(
        this.client.get<ExoClinicBackendOpenApiSchemas['Icd10DiagnoseResponseDto'][]>('patient/icd10-diagnosis'),
      ),
  };

  users = {
    current: () =>
      this.extractJsonBody(this.client.get<ExoClinicBackendOpenApiSchemas['UserInfoResponseDto']>('/user/current')),

    updatePhoto: (photo: File) => {
      const formData = new FormData();
      formData.append('photo', photo);

      return this.extractJsonBody(this.client.patch<UserResponseDTO>(`/users/update-photo`, formData));
    },

    lock: (id: string) => this.extractJsonBody(this.client.patch<unknown>(`/users/lock/${id}`)),

    unlock: (id: string) => this.extractJsonBody(this.client.patch<unknown>(`/users/unlock/${id}`)),
  };

  userProfiles = {
    userProfile: (userProfileId: string) => ({
      update: (body: Partial<UserProfileUpdateDTO>) =>
        this.extractJsonBody(
          this.client.patch<ExoClinicBackendOpenApiSchemas['UserInfoResponseDto']>(`/user/current`, body),
        ),

      archive: (params: { archived: boolean }) =>
        this.extractJsonBody(
          this.client.patch<unknown>(`user-profiles/${userProfileId}/archive`, undefined, prepareURLParams(params)),
        ),

      anonimize: () => this.extractJsonBody(this.client.patch<unknown>(`/user-profiles/anonymous/${userProfileId}`)),
    }),

    updateSpecialist: (id: string, body: SpecialistUserProfileCreateDTO) =>
      this.extractJsonBody(this.client.patch<UserProfileResponseDTO>(`/user-profiles/specialist/${id}`, body)),
  };
  service = {
    auth: async (body: { password: string }) => {
      return await this.client.post('/service/auth', body);
    },
  };
  device = {
    wifi: {
      getWifiNetworksList: () => {
        const deviceApiUrl = EGZOTechHostApi.instance?.options?.deviceApiUrl ?? '/';

        return this.extractJsonBody(
          this.client.get<ExoClinicBackendOpenApiSchemas['WiFiScanResultResponseDto']>(
            deviceApiUrl + 'device/wifi/networks',
            undefined,
            { credentials: 'omit' },
          ),
        );
      },
      connect: async (body: ExoClinicBackendOpenApiSchemas['ConnectToWiFiRequestDto']) => {
        const deviceApiUrl = EGZOTechHostApi.instance?.options?.deviceApiUrl ?? '/';

        return await this.extractJsonBody(
          this.client.post<ExoClinicBackendOpenApiSchemas['WifiConnectResponseDto']>(
            deviceApiUrl + `device/wifi/connect`,
            body,
            undefined,
            { credentials: 'omit' },
          ),
        );
      },
      disconnect: async (body: ExoClinicBackendOpenApiSchemas['DisconnectWiFiRequestDto']) => {
        const deviceApiUrl = EGZOTechHostApi.instance?.options?.deviceApiUrl ?? '/';

        return await this.client.delete(deviceApiUrl + 'device/wifi/disconnect', body, undefined, {
          credentials: 'omit',
        });
      },
      forget: async (body: ExoClinicBackendOpenApiSchemas['ForgetWifiRequestDto']) => {
        const deviceApiUrl = EGZOTechHostApi.instance?.options?.deviceApiUrl ?? '/';

        return await this.client.delete(deviceApiUrl + 'device/wifi/forget', body, undefined, { credentials: 'omit' });
      },
      status: async () => {
        const deviceApiUrl = EGZOTechHostApi.instance?.options?.deviceApiUrl ?? '/';

        return await this.extractJsonBody(
          this.client.get<ExoClinicBackendOpenApiSchemas['WiFiStatusResponseDto']>(
            deviceApiUrl + 'device/wifi/status',
            undefined,
            { credentials: 'omit' },
          ),
        );
      },
    },
    update: {
      firmware: {
        status: () => {
          const deviceApiUrl = EGZOTechHostApi.instance?.options?.deviceApiUrl ?? '/';

          return this.extractJsonBody(
            this.client.get<ExoClinicBackendOpenApiSchemas['UpdateStatusResponseDto']>(
              deviceApiUrl + `device/update/firmware/status`,
            ),
          );
        },
        update: () => {
          const deviceApiUrl = EGZOTechHostApi.instance?.options?.deviceApiUrl ?? '/';

          return this.extractJsonBody(this.client.post(deviceApiUrl + `device/update/firmware`));
        },
        restart: () => {
          return this.extractJsonBody(this.client.post(`/device/update/firmware/restart`));
        },
      },
      status: () => {
        const deviceApiUrl = EGZOTechHostApi.instance?.options?.deviceApiUrl ?? '/';

        return this.extractJsonBody(
          this.client.get<ExoClinicBackendOpenApiSchemas['UpdateStatusResponseDto']>(
            deviceApiUrl + `device/update/status`,
            undefined,
            { credentials: 'omit' },
          ),
        );
      },
      update: () => {
        const deviceApiUrl = EGZOTechHostApi.instance?.options?.deviceApiUrl ?? '/';

        return this.client.post(deviceApiUrl + `device/update`, undefined, undefined, { credentials: 'omit' });
      },
      restart: () => {
        const deviceApiUrl = EGZOTechHostApi.instance?.options?.deviceApiUrl ?? '/';

        return this.extractJsonBody(
          this.client.post(deviceApiUrl + `device/update/restart`, undefined, undefined, { credentials: 'omit' }),
        );
      },
      available: () => {
        const deviceApiUrl = EGZOTechHostApi.instance?.options?.deviceApiUrl ?? '/';

        return this.extractJsonBody(
          this.client.get<ExoClinicBackendOpenApiPaths['/device/update/available']['get']['response']>(
            deviceApiUrl + `device/update/available`,
            undefined,
            {
              credentials: 'omit',
            },
          ),
        );
      },
      current: () => {
        const deviceApiUrl = EGZOTechHostApi.instance?.options?.deviceApiUrl ?? '/';

        return this.extractJsonBody(
          this.client.get<ExoClinicBackendOpenApiPaths['/device/update/current']['get']['response']>(
            deviceApiUrl + `device/update/current`,
            undefined,
            {
              credentials: 'omit',
            },
          ),
        );
      },
    },
    localStorage: {
      get: () => {
        return this.extractJsonBody(this.client.get<AppSettingsPayload>('/device/local-storage'));
      },
      update: (body: AppSettingsPayload) => {
        return this.client.put('/device/local-storage', body);
      },
    },
    serialNumber: {
      get: () => {
        const deviceApiUrl = EGZOTechHostApi.instance?.options?.deviceApiUrl ?? '';

        return this.extractJsonBody(
          this.client.get<ExoClinicBackendOpenApiPaths['/device/serial-number']['get']['response']>(
            deviceApiUrl + 'device/serial-number',
            undefined,
            {
              credentials: 'omit',
            },
          ),
        );
      },
    },
    detect: {
      get: () => {
        const deviceApiUrl = EGZOTechHostApi.instance?.options?.deviceApiUrl ?? '/';

        return this.extractJsonBody(
          this.client.get<ExoClinicBackendOpenApiPaths['/device/detect']['get']['response']>(
            deviceApiUrl + 'device/detect',
            undefined,
            {
              credentials: 'omit',
            },
          ),
        );
      },
      config: () => {
        return this.extractJsonBody(
          this.client.get<ExoClinicBackendOpenApiPaths['/device/detect/config']['get']['response']>(
            '/device/detect/config',
          ),
        );
      },
      setConfig: async (body: ExoClinicBackendOpenApiPaths['/device/detect/config']['patch']['body']) => {
        // FIXME: These imports are dynamic because normal imports break whole app due to dependency circle
        // in Modal.tsx nad modalSlice.ts, this should be fixed in the future and needs a bit of refactoring
        // in these files.
        const { store } = await import('config/store');
        const { deviceApi } = await import('queries/device');

        // We want to use here mutation from RTK Query because we need to invalidate caches after we change
        // the detection configuration. We are doing it outside react, because DeviceManager use this
        // function outside a component.
        const result = await store.dispatch(deviceApi.endpoints.setDetectConfiguration.initiate(body));

        if ('data' in result) {
          return result.data.serialNumbers;
        }

        this.handleError(result.error);
        throw result.error;
      },
    },
    config: {
      sendZebraApiCommand: async (body: ExoClinicBackendOpenApiPaths['/device/config']['patch']['body']) => {
        return this.extractJsonBody(
          this.client.patch<ExoClinicBackendOpenApiPaths['/device/config']['patch']['response']>(
            `/device/config`,
            body,
          ),
        );
      },
      changeTabletLanguage: async (body: ExoClinicBackendOpenApiPaths['/device/config/lang']['patch']['body']) => {
        return this.extractJsonBody(
          this.client.patch<ExoClinicBackendOpenApiPaths['/device/config/lang']['patch']['response']>(
            `/device/config/lang`,
            body,
          ),
        );
      },
    },
    startApp: async (body: ExoClinicBackendOpenApiSchemas['StartDeviceApplicationRequestDto']) => {
      const deviceApiUrl = EGZOTechHostApi.instance?.options?.deviceApiUrl ?? '';

      return await this.client.post(deviceApiUrl + 'device/start-app', body, undefined, {
        credentials: 'omit',
      });
    },
  };

  constructor(client?: RESTClient) {
    super();
    this.client =
      client ??
      new RESTClient((method, path, body, params, options) => {
        const headers = new Headers(options?.headers);

        if (typeof body === 'string') {
          headers.set('Content-Type', 'application/json');
        }

        const mockedApiEnv = EGZOTechHostApi.instance?.options?.mockedApi;
        if (mockedApiEnv && mockedApi.isMocked(method, path, params)) {
          return mockedApi.mockedFetch(method, path);
        }
        if (path.startsWith('/device/wifi/')) {
          headers.set('api-version', '1');
        }
        return apiRawFetch(path + (params ? `?${params.toString()}` : ''), {
          ...options,
          body,
          method,
          headers,
        })
          .then(response => this.handleResponseError(response))
          .catch(reason => {
            this.handleError(reason);
            throw reason;
          });
      });

    this.openApiClient = new OpenApiRESTClient(this.client);
  }
}

export const exoClinicApi = new ExoClinicBackendApi();
export const exoClinicApiWithoutError = new ExoClinicBackendApi();
