import { GeneratedEMSExerciseDefinition } from '../../types/GeneratedExerciseDefinition';

const emsExerciseIds = [
  'ems_active_recovery',
  'ems_agonist_antagonist',
  'ems_circulation',
  'ems_endurance_training',
  'ems_exercise_prep',
  'ems_fecal_incontinence',
  'ems_incontinence_mixed',
  'ems_incontinence_stress',
  'ems_incontinence_urge',
  'ems_massages',
  'ems_mild_atrophy',
  'ems_muscle_atrophy',
  'ems_muscle_relaxation_pelvic_bursts',
  'ems_muscle_relaxation_pelvic_continuous',
  'ems_muscle_spasm_relaxation',
  'ems_muscle_strengthening',
  'ems_power_training',
  'ems_severe_atrophy',
  'ems_user_program',
  'ems_user_program_pelvic',
  'fes_arm_extension_support',
  'fes_grasp_and_release',
  'fes_hand_to_mouth',
  'fes_open_and_close',
  'tens_acupuncture',
  'tens_burst',
  'tens_conventional',
  'tens_frequency_modulated',
  'tens_user_program',
] as const;

export type EMSExerciseId = (typeof emsExerciseIds)[number];

export const getPredefinedStellaBioEMSExercise = (): { [key in EMSExerciseId]: GeneratedEMSExerciseDefinition } => ({
  ems_mild_atrophy: {
    calibrationFlow: 'ems/stella',
    presentationTemplate: 'time',
    type: 'ems',
    tags: ['ems'],
    ems: {
      program: {
        defaultChannelValues: [
          {
            channelIndex: 0,
            fallTime: 0,
            delay: 0,
            pulseFrequency: 20,
            pulseShape: 102,
            pulseWidth: 10000,
            riseTime: 0,
            runTime: 1200000000,
            plateauTime: 3000000,
            pauseTime: 6000000,
          },
        ],
        maxIntensity: [0.08],
        phases: [
          {
            channels: [],
          },
        ],
        phasesRepetition: 1,
        stimCalibration: [
          {
            channelIndex: 0,
            fallTime: 0,
            delay: 0,
            pulseFrequency: 20,
            pulseShape: 102,
            pulseWidth: 10000,
            riseTime: 0,
            runTime: 9000000,
            plateauTime: 3000000,
            pauseTime: 6000000,
          },
        ],
        programTime: 1200000000,
        minRequiredChannels: 1,
        maxSupportedChannels: 8,
        tags: ['ems'],
        allowedCableChannelFeatures: ['ems'],
      },
      parameters: {
        phases: [
          {
            pulseFrequency: {
              unit: 'Hz',
              default: 20,
              values: [1, 2, 5, 10, 20],
              blockAfterStart: true,
            },
            pulseWidth: {
              unit: 'us',
              default: 10000,
              values: [1000, 2000, 5000, 10000, 20000],
              blockAfterStart: true,
            },
            runTime: {
              unit: 'us',
              default: 1200000000,
              values: [
                300000000, 600000000, 900000000, 1200000000, 1500000000, 1800000000, 2100000000, 2400000000, 2700000000,
                3000000000, 3300000000, 3600000000,
              ],
              blockAfterStart: true,
            },
          },
        ],
      },
    },
  },
  ems_severe_atrophy: {
    calibrationFlow: 'ems/stella',
    presentationTemplate: 'time',
    type: 'ems',
    tags: ['ems'],
    ems: {
      program: {
        defaultChannelValues: [
          {
            channelIndex: 0,
            fallTime: 0,
            delay: 0,
            pulseFrequency: 0.1,
            pulseShape: 104,
            pulseWidth: 200000,
            riseTime: 0,
            runTime: 900000000,
            plateauTime: 10000000,
            pauseTime: 0,
            pulseSlopeTime: 50000,
          },
        ],
        maxIntensity: [0.08],
        phases: [
          {
            channels: [],
          },
        ],
        phasesRepetition: 1,
        stimCalibration: [
          {
            channelIndex: 0,
            fallTime: 0,
            delay: 0,
            pulseFrequency: 0.1,
            pulseShape: 104,
            pulseWidth: 200000,
            riseTime: 0,
            runTime: 5000000,
            plateauTime: 10000000,
            pauseTime: 0,
            pulseSlopeTime: 50000,
          },
        ],
        programTime: 900000000,
        minRequiredChannels: 1,
        maxSupportedChannels: 4,
        tags: ['ems'],
        allowedCableChannelFeatures: ['ems'],
      },
      parameters: {
        phases: [
          {
            pulseFrequency: {
              unit: 'Hz',
              default: 0.1,
              values: [0.1, 0.2, 0.5],
              blockAfterStart: true,
            },
            pulseWidth: {
              unit: 'us',
              default: 200000,
              values: [50000, 100000, 150000, 200000, 250000, 300000],
              blockAfterStart: true,
            },
            runTime: {
              unit: 'us',
              default: 900000000,
              values: [
                300000000, 600000000, 900000000, 1200000000, 1500000000, 1800000000, 2100000000, 2400000000, 2700000000,
                3000000000, 3300000000, 3600000000,
              ],
              blockAfterStart: true,
            },
          },
        ],
      },
    },
  },
  ems_muscle_spasm_relaxation: {
    calibrationFlow: 'ems/stella',
    presentationTemplate: 'time',
    type: 'ems',
    tags: ['ems'],
    ems: {
      program: {
        defaultChannelValues: [
          {
            channelIndex: 0,
            fallTime: 2000000,
            delay: 0,
            pulseFrequency: 80,
            pulseShape: 100,
            pulseWidth: 250,
            riseTime: 2000000,
            runTime: 1200000000,
            plateauTime: 8000000,
            pauseTime: 4000000,
          },
        ],
        maxIntensity: [0.1],
        phases: [
          {
            channels: [],
          },
        ],
        phasesRepetition: 1,
        stimCalibration: [
          {
            channelIndex: 0,
            fallTime: 2000000,
            delay: 0,
            pulseFrequency: 80,
            pulseShape: 100,
            pulseWidth: 250,
            riseTime: 2000000,
            runTime: 16000000,
            plateauTime: 8000000,
            pauseTime: 4000000,
          },
        ],
        programTime: 1200000000,
        minRequiredChannels: 1,
        maxSupportedChannels: 8,
        tags: ['ems'],
        allowedCableChannelFeatures: ['ems'],
      },
      parameters: {
        phases: [
          {
            runTime: {
              unit: 'us',
              default: 1200000000,
              values: [
                300000000, 600000000, 900000000, 1200000000, 1500000000, 1800000000, 2100000000, 2400000000, 2700000000,
                3000000000, 3300000000, 3600000000,
              ],
              blockAfterStart: true,
            },
          },
        ],
      },
    },
  },
  ems_muscle_atrophy: {
    calibrationFlow: 'ems/stella',
    presentationTemplate: 'phases-time',
    type: 'ems',
    tags: ['ems'],
    ems: {
      program: {
        defaultChannelValues: [
          {
            channelIndex: 0,
            fallTime: 0,
            delay: 0,
            pulseFrequency: 10,
            pulseShape: 100,
            pulseWidth: 300,
            riseTime: 0,
            runTime: 300000000,
          },
        ],
        maxIntensity: [0.1],
        phases: [
          {
            channels: [],
          },
          {
            channels: [
              {
                channelIndex: 0,
                fallTime: 1000000,
                pulseFrequency: 40,
                riseTime: 2000000,
                runTime: 900000000,
                plateauTime: 8000000,
                pauseTime: 16000000,
              },
            ],
          },
          {
            channels: [
              {
                channelIndex: 0,
                fallTime: 1000000,
                pulseFrequency: 60,
                riseTime: 2000000,
                runTime: 900000000,
                plateauTime: 8000000,
                pauseTime: 16000000,
              },
            ],
          },
          {
            channels: [
              {
                channelIndex: 0,
                pulseFrequency: 3,
              },
            ],
          },
        ],
        phasesRepetition: 1,
        stimCalibration: [
          {
            channelIndex: 0,
            fallTime: 0,
            delay: 0,
            pulseFrequency: 10,
            pulseShape: 100,
            pulseWidth: 300,
            riseTime: 0,
            runTime: 5000000,
          },
        ],
        programTime: 2400000000,
        minRequiredChannels: 1,
        maxSupportedChannels: 8,
        tags: ['ems'],
        allowedCableChannelFeatures: ['ems'],
      },
    },
  },
  ems_circulation: {
    calibrationFlow: 'ems/stella',
    presentationTemplate: 'time',
    type: 'ems',
    tags: ['ems'],
    ems: {
      program: {
        defaultChannelValues: [
          {
            channelIndex: 0,
            fallTime: 0,
            delay: 0,
            pulseFrequency: 8,
            pulseShape: 100,
            pulseWidth: 300,
            riseTime: 0,
            runTime: 600000000,
          },
        ],
        maxIntensity: [0.1],
        phases: [
          {
            channels: [],
          },
        ],
        phasesRepetition: 1,
        stimCalibration: [
          {
            channelIndex: 0,
            fallTime: 0,
            delay: 0,
            pulseFrequency: 8,
            pulseShape: 100,
            pulseWidth: 300,
            riseTime: 0,
            runTime: 5000000,
          },
        ],
        programTime: 600000000,
        minRequiredChannels: 1,
        maxSupportedChannels: 8,
        tags: ['ems'],
        allowedCableChannelFeatures: ['ems'],
      },
      parameters: {
        phases: [
          {
            pulseWidth: {
              unit: 'us',
              default: 300,
              values: [50, 100, 150, 200, 250, 300, 350, 400, 450, 500],
              blockAfterStart: true,
            },
          },
        ],
      },
    },
  },
  ems_fecal_incontinence: {
    calibrationFlow: 'ems/stella',
    presentationTemplate: 'time',
    type: 'ems',
    tags: ['ems', 'pelvic'],
    ems: {
      program: {
        defaultChannelValues: [
          {
            channelIndex: 0,
            fallTime: 1000000,
            delay: 0,
            pulseFrequency: 35,
            pulseShape: 100,
            pulseWidth: 200,
            riseTime: 1000000,
            runTime: 1200000000,
            plateauTime: 5000000,
            pauseTime: 5000000,
          },
        ],
        maxIntensity: [0.1],
        phases: [
          {
            channels: [],
          },
        ],
        phasesRepetition: 1,
        stimCalibration: [
          {
            channelIndex: 0,
            fallTime: 1000000,
            delay: 0,
            pulseFrequency: 35,
            pulseShape: 100,
            pulseWidth: 200,
            riseTime: 1000000,
            runTime: 12000000,
            plateauTime: 5000000,
            pauseTime: 5000000,
          },
        ],
        programTime: 1200000000,
        minRequiredChannels: 1,
        maxSupportedChannels: 2,
        tags: ['ems', 'pelvic'],
        allowedCableChannelFeatures: ['ems-pelvic'],
      },
      parameters: {
        phases: [
          {
            runTime: {
              unit: 'us',
              default: 1200000000,
              values: [
                300000000, 600000000, 900000000, 1200000000, 1500000000, 1800000000, 2100000000, 2400000000, 2700000000,
                3000000000, 3300000000, 3600000000,
              ],
              blockAfterStart: true,
            },
          },
        ],
      },
    },
  },
  ems_incontinence_mixed: {
    calibrationFlow: 'ems/stella',
    presentationTemplate: 'time',
    type: 'ems',
    tags: ['ems', 'pelvic'],
    ems: {
      program: {
        defaultChannelValues: [
          {
            channelIndex: 0,
            fallTime: 2000000,
            delay: 0,
            pulseFrequency: 10,
            pulseShape: 100,
            pulseWidth: 200,
            riseTime: 2000000,
            runTime: 600000000,
            plateauTime: 5000000,
            pauseTime: 10000000,
          },
        ],
        maxIntensity: [0.1],
        phases: [
          {
            channels: [],
          },
          {
            channels: [
              {
                channelIndex: 0,
                pulseFrequency: 50,
              },
            ],
          },
        ],
        phasesRepetition: 1,
        stimCalibration: [
          {
            channelIndex: 0,
            fallTime: 2000000,
            delay: 0,
            pulseFrequency: 10,
            pulseShape: 100,
            pulseWidth: 200,
            riseTime: 2000000,
            runTime: 19000000,
            plateauTime: 5000000,
            pauseTime: 10000000,
          },
        ],
        programTime: 1200000000,
        minRequiredChannels: 1,
        maxSupportedChannels: 2,
        tags: ['ems', 'pelvic'],
        allowedCableChannelFeatures: ['ems-pelvic'],
      },
    },
  },
  ems_incontinence_urge: {
    calibrationFlow: 'ems/stella',
    presentationTemplate: 'time',
    type: 'ems',
    tags: ['ems', 'pelvic'],
    ems: {
      program: {
        defaultChannelValues: [
          {
            channelIndex: 0,
            fallTime: 1000000,
            delay: 0,
            pulseFrequency: 10,
            pulseShape: 100,
            pulseWidth: 200,
            riseTime: 1000000,
            runTime: 900000000,
            plateauTime: 5000000,
            pauseTime: 10000000,
          },
        ],
        maxIntensity: [0.1],
        phases: [
          {
            channels: [],
          },
        ],
        phasesRepetition: 1,
        stimCalibration: [
          {
            channelIndex: 0,
            fallTime: 1000000,
            delay: 0,
            pulseFrequency: 10,
            pulseShape: 100,
            pulseWidth: 200,
            riseTime: 1000000,
            runTime: 17000000,
            plateauTime: 5000000,
            pauseTime: 10000000,
          },
        ],
        programTime: 900000000,
        minRequiredChannels: 1,
        maxSupportedChannels: 2,
        tags: ['ems', 'pelvic'],
        allowedCableChannelFeatures: ['ems-pelvic'],
      },
      parameters: {
        phases: [
          {
            runTime: {
              unit: 'us',
              default: 900000000,
              values: [
                300000000, 600000000, 900000000, 1200000000, 1500000000, 1800000000, 2100000000, 2400000000, 2700000000,
                3000000000, 3300000000, 3600000000,
              ],
              blockAfterStart: true,
            },
          },
        ],
      },
    },
  },
  ems_incontinence_stress: {
    calibrationFlow: 'ems/stella',
    presentationTemplate: 'time',
    type: 'ems',
    tags: ['ems', 'pelvic'],
    ems: {
      program: {
        defaultChannelValues: [
          {
            channelIndex: 0,
            fallTime: 1000000,
            delay: 0,
            pulseFrequency: 50,
            pulseShape: 100,
            pulseWidth: 200,
            riseTime: 1000000,
            runTime: 1200000000,
            plateauTime: 5000000,
            pauseTime: 10000000,
          },
        ],
        maxIntensity: [0.1],
        phases: [
          {
            channels: [],
          },
        ],
        phasesRepetition: 1,
        stimCalibration: [
          {
            channelIndex: 0,
            fallTime: 1000000,
            delay: 0,
            pulseFrequency: 50,
            pulseShape: 100,
            pulseWidth: 200,
            riseTime: 1000000,
            runTime: 17000000,
            plateauTime: 5000000,
            pauseTime: 10000000,
          },
        ],
        programTime: 1200000000,
        minRequiredChannels: 1,
        maxSupportedChannels: 2,
        tags: ['ems', 'pelvic'],
        allowedCableChannelFeatures: ['ems-pelvic'],
      },
      parameters: {
        phases: [
          {
            runTime: {
              unit: 'us',
              default: 1200000000,
              values: [
                300000000, 600000000, 900000000, 1200000000, 1500000000, 1800000000, 2100000000, 2400000000, 2700000000,
                3000000000, 3300000000, 3600000000,
              ],
              blockAfterStart: true,
            },
          },
        ],
      },
    },
  },
  ems_muscle_relaxation_pelvic_continuous: {
    calibrationFlow: 'ems/stella',
    presentationTemplate: 'time',
    type: 'ems',
    tags: ['ems', 'pelvic'],
    ems: {
      program: {
        defaultChannelValues: [
          {
            channelIndex: 0,
            fallTime: 0,
            riseTime: 0,
            delay: 0,
            pulseFrequency: 2,
            pulseShape: 100,
            pulseWidth: 150,
            runTime: 1200000000,
          },
        ],
        maxIntensity: [0.07],
        phases: [
          {
            channels: [],
          },
        ],
        phasesRepetition: 1,
        stimCalibration: [
          {
            channelIndex: 0,
            riseTime: 0,
            fallTime: 0,
            delay: 0,
            pulseFrequency: 2,
            pulseShape: 100,
            pulseWidth: 150,
            runTime: 5000000,
          },
        ],
        programTime: 1200000000,
        minRequiredChannels: 1,
        maxSupportedChannels: 2,
        tags: ['ems', 'pelvic'],
        allowedCableChannelFeatures: ['ems-pelvic'],
      },
      parameters: {
        phases: [
          {
            pulseFrequency: {
              unit: 'Hz',
              default: 2,
              values: [1, 2, 4, 8, 10],
              blockAfterStart: true,
            },
            pulseWidth: {
              unit: 'us',
              default: 150,
              values: [50, 70, 100, 150, 200, 250, 300, 400],
              blockAfterStart: true,
            },
            runTime: {
              unit: 'us',
              default: 1200000000,
              values: [300000000, 600000000, 900000000, 1200000000, 1500000000, 1800000000],
              blockAfterStart: true,
            },
          },
        ],
      },
    },
  },
  ems_muscle_relaxation_pelvic_bursts: {
    calibrationFlow: 'ems/stella',
    presentationTemplate: 'time',
    type: 'ems',
    tags: ['ems', 'pelvic'],
    ems: {
      program: {
        defaultChannelValues: [
          {
            channelIndex: 0,
            fallTime: 1000000,
            riseTime: 1000000,
            plateauTime: 6000000,
            pauseTime: 10000000,
            delay: 0,
            pulseFrequency: 2,
            pulseShape: 100,
            pulseWidth: 220,
            runTime: 1200000000,
          },
        ],
        maxIntensity: [0.07],
        phases: [
          {
            channels: [],
          },
        ],
        phasesRepetition: 1,
        stimCalibration: [
          {
            channelIndex: 0,
            fallTime: 1000000,
            riseTime: 1000000,
            plateauTime: 6000000,
            pauseTime: 10000000,
            delay: 0,
            pulseFrequency: 2,
            pulseShape: 100,
            pulseWidth: 220,
            runTime: 5000000,
          },
        ],
        programTime: 1200000000,
        minRequiredChannels: 1,
        maxSupportedChannels: 2,
        tags: ['ems', 'pelvic'],
        allowedCableChannelFeatures: ['ems-pelvic'],
      },
      parameters: {
        phases: [
          {
            pulseFrequency: {
              unit: 'Hz',
              default: 2,
              values: [2, 4, 8],
              blockAfterStart: true,
            },
            pulseWidth: {
              unit: 'us',
              default: 220,
              values: [200, 220, 250],
              blockAfterStart: true,
            },
            runTime: {
              unit: 'us',
              default: 1200000000,
              values: [300000000, 600000000, 900000000, 1200000000, 1500000000, 1800000000],
              blockAfterStart: true,
            },
          },
        ],
      },
    },
  },
  ems_agonist_antagonist: {
    calibrationFlow: 'ems/stella',
    presentationTemplate: 'phases-time',
    type: 'ems',
    tags: ['ems'],
    ems: {
      program: {
        defaultChannelValues: [
          {
            channelIndex: 0,
            fallTime: 0,
            delay: 0,
            pulseFrequency: 10,
            pulseShape: 100,
            pulseWidth: 300,
            riseTime: 0,
            runTime: 300000000,
          },
          {
            channelIndex: 1,
            fallTime: 0,
            delay: 0,
            pulseFrequency: 10,
            pulseShape: 100,
            pulseWidth: 300,
            riseTime: 0,
            runTime: 300000000,
          },
          {
            channelIndex: 2,
            fallTime: 0,
            delay: 0,
            pulseFrequency: 10,
            pulseShape: 100,
            pulseWidth: 300,
            riseTime: 0,
            runTime: 300000000,
          },
          {
            channelIndex: 3,
            fallTime: 0,
            delay: 0,
            pulseFrequency: 10,
            pulseShape: 100,
            pulseWidth: 300,
            riseTime: 0,
            runTime: 300000000,
          },
        ],
        maxIntensity: [0.1, 0.1, 0.1, 0.1],
        phases: [
          {
            channels: [],
          },
          {
            channels: [
              {
                channelIndex: 0,
                fallTime: 1000000,
                pulseFrequency: 40,
                riseTime: 2000000,
                runTime: 900000000,
                plateauTime: 8000000,
                pauseTime: 16000000,
              },
              {
                channelIndex: 1,
                fallTime: 1000000,
                delay: 13000000,
                pulseFrequency: 40,
                riseTime: 2000000,
                runTime: 900000000,
                plateauTime: 8000000,
                pauseTime: 16000000,
              },
              {
                channelIndex: 2,
                fallTime: 1000000,
                pulseFrequency: 40,
                riseTime: 2000000,
                runTime: 900000000,
                plateauTime: 8000000,
                pauseTime: 16000000,
              },
              {
                channelIndex: 3,
                fallTime: 1000000,
                delay: 13000000,
                pulseFrequency: 40,
                riseTime: 2000000,
                runTime: 900000000,
                plateauTime: 8000000,
                pauseTime: 16000000,
              },
            ],
          },
          {
            channels: [
              {
                channelIndex: 0,
                fallTime: 1000000,
                pulseFrequency: 60,
                riseTime: 2000000,
                runTime: 900000000,
                plateauTime: 8000000,
                pauseTime: 16000000,
              },
              {
                channelIndex: 1,
                fallTime: 1000000,
                delay: 13000000,
                pulseFrequency: 60,
                riseTime: 2000000,
                runTime: 900000000,
                plateauTime: 8000000,
                pauseTime: 16000000,
              },
              {
                channelIndex: 2,
                fallTime: 1000000,
                pulseFrequency: 60,
                riseTime: 2000000,
                runTime: 900000000,
                plateauTime: 8000000,
                pauseTime: 16000000,
              },
              {
                channelIndex: 3,
                fallTime: 1000000,
                delay: 13000000,
                pulseFrequency: 60,
                riseTime: 2000000,
                runTime: 900000000,
                plateauTime: 8000000,
                pauseTime: 16000000,
              },
            ],
          },
          {
            channels: [
              {
                channelIndex: 0,
                pulseFrequency: 3,
              },
              {
                channelIndex: 1,
                pulseFrequency: 3,
              },
              {
                channelIndex: 2,
                pulseFrequency: 3,
              },
              {
                channelIndex: 3,
                pulseFrequency: 3,
              },
            ],
          },
        ],
        phasesRepetition: 1,
        stimCalibration: [
          {
            channelIndex: 0,
            fallTime: 0,
            delay: 0,
            pulseFrequency: 10,
            pulseShape: 100,
            pulseWidth: 300,
            riseTime: 0,
            runTime: 5000000,
          },
          {
            channelIndex: 1,
            fallTime: 0,
            delay: 0,
            pulseFrequency: 10,
            pulseShape: 100,
            pulseWidth: 300,
            riseTime: 0,
            runTime: 5000000,
          },
          {
            channelIndex: 2,
            fallTime: 0,
            delay: 0,
            pulseFrequency: 10,
            pulseShape: 100,
            pulseWidth: 300,
            riseTime: 0,
            runTime: 5000000,
          },
          {
            channelIndex: 3,
            fallTime: 0,
            delay: 0,
            pulseFrequency: 10,
            pulseShape: 100,
            pulseWidth: 300,
            riseTime: 0,
            runTime: 5000000,
          },
        ],
        programTime: 2400000000,
        minRequiredChannels: 4,
        maxSupportedChannels: 4,
        tags: ['ems'],
        allowedCableChannelFeatures: ['ems'],
      },
    },
  },
  ems_power_training: {
    calibrationFlow: 'ems/stella',
    presentationTemplate: 'time',
    type: 'ems',
    tags: ['ems'],
    ems: {
      program: {
        defaultChannelValues: [
          {
            channelIndex: 0,
            fallTime: 0,
            delay: 0,
            pulseFrequency: 10,
            pulseShape: 100,
            pulseWidth: 300,
            riseTime: 0,
            runTime: 300000000,
          },
        ],
        maxIntensity: [0.1],
        phases: [
          {
            channels: [],
          },
          {
            channels: [
              {
                channelIndex: 0,
                fallTime: 1000000,
                pulseFrequency: 60,
                riseTime: 2000000,
                runTime: 600000000,
                plateauTime: 10000000,
                pauseTime: 40000000,
              },
            ],
          },
          {
            channels: [
              {
                channelIndex: 0,
                fallTime: 1000000,
                pulseFrequency: 90,
                riseTime: 2000000,
                runTime: 600000000,
                plateauTime: 7000000,
                pauseTime: 28000000,
              },
            ],
          },
          {
            channels: [
              {
                channelIndex: 0,
                pulseFrequency: 3,
              },
            ],
          },
        ],
        phasesRepetition: 1,
        stimCalibration: [
          {
            channelIndex: 0,
            fallTime: 0,
            delay: 0,
            pulseFrequency: 10,
            pulseShape: 100,
            pulseWidth: 300,
            riseTime: 0,
            runTime: 5000000,
          },
        ],
        programTime: 1800000000,
        minRequiredChannels: 1,
        maxSupportedChannels: 8,
        tags: ['ems'],
        allowedCableChannelFeatures: ['ems'],
      },
    },
  },
  ems_muscle_strengthening: {
    calibrationFlow: 'ems/stella',
    presentationTemplate: 'time',
    type: 'ems',
    tags: ['ems'],
    ems: {
      program: {
        defaultChannelValues: [
          {
            channelIndex: 0,
            fallTime: 3000000,
            delay: 0,
            pulseFrequency: 50,
            pulseShape: 100,
            pulseWidth: 300,
            riseTime: 2000000,
            runTime: 600000000,
            plateauTime: 5000000,
            pauseTime: 10000000,
          },
        ],
        maxIntensity: [0.1],
        phases: [
          {
            channels: [],
          },
        ],
        phasesRepetition: 1,
        stimCalibration: [
          {
            channelIndex: 0,
            fallTime: 3000000,
            delay: 0,
            pulseFrequency: 50,
            pulseShape: 100,
            pulseWidth: 300,
            riseTime: 2000000,
            runTime: 20000000,
            plateauTime: 5000000,
            pauseTime: 10000000,
          },
        ],
        programTime: 600000000,
        minRequiredChannels: 1,
        maxSupportedChannels: 8,
        tags: ['ems'],
        allowedCableChannelFeatures: ['ems'],
      },
      parameters: {
        phases: [
          {
            runTime: {
              unit: 'us',
              default: 600000000,
              values: [
                300000000, 600000000, 900000000, 1200000000, 1500000000, 1800000000, 2100000000, 2400000000, 2700000000,
                3000000000, 3300000000, 3600000000,
              ],
              blockAfterStart: true,
            },
          },
        ],
      },
    },
  },
  ems_exercise_prep: {
    calibrationFlow: 'ems/stella',
    presentationTemplate: 'time',
    type: 'ems',
    tags: ['ems'],
    ems: {
      program: {
        defaultChannelValues: [
          {
            channelIndex: 0,
            fallTime: 1000000,
            delay: 0,
            pulseFrequency: 10,
            pulseShape: 100,
            pulseWidth: 300,
            riseTime: 2000000,
            runTime: 600000000,
          },
        ],
        maxIntensity: [0.1],
        phases: [
          {
            channels: [],
          },
        ],
        phasesRepetition: 1,
        stimCalibration: [
          {
            channelIndex: 0,
            fallTime: 1000000,
            delay: 0,
            pulseFrequency: 10,
            pulseShape: 100,
            pulseWidth: 300,
            riseTime: 2000000,
            runTime: 5000000,
          },
        ],
        programTime: 600000000,
        minRequiredChannels: 1,
        maxSupportedChannels: 8,
        tags: ['ems'],
        allowedCableChannelFeatures: ['ems'],
      },
      parameters: {
        phases: [
          {
            runTime: {
              unit: 'us',
              default: 600000000,
              values: [
                300000000, 600000000, 900000000, 1200000000, 1500000000, 1800000000, 2100000000, 2400000000, 2700000000,
                3000000000, 3300000000, 3600000000,
              ],
              blockAfterStart: true,
            },
          },
        ],
      },
    },
  },
  ems_active_recovery: {
    calibrationFlow: 'ems/stella',
    presentationTemplate: 'time',
    type: 'ems',
    tags: ['ems'],
    ems: {
      program: {
        defaultChannelValues: [
          {
            channelIndex: 0,
            fallTime: 0,
            delay: 0,
            pulseFrequency: 100,
            pulseShape: 100,
            pulseWidth: 200,
            riseTime: 0,
            runTime: 600000000,
            plateauTime: 2000000,
            pauseTime: 1000000,
          },
        ],
        maxIntensity: [0.1],
        phases: [
          {
            channels: [],
          },
        ],
        phasesRepetition: 1,
        stimCalibration: [
          {
            channelIndex: 0,
            fallTime: 0,
            delay: 0,
            pulseFrequency: 100,
            pulseShape: 100,
            pulseWidth: 200,
            riseTime: 0,
            runTime: 5000000,
            plateauTime: 2000000,
            pauseTime: 1000000,
          },
        ],
        programTime: 600000000,
        minRequiredChannels: 1,
        maxSupportedChannels: 8,
        tags: ['ems'],
        allowedCableChannelFeatures: ['ems'],
      },
      parameters: {
        phases: [
          {
            runTime: {
              unit: 'us',
              default: 600000000,
              values: [
                300000000, 600000000, 900000000, 1200000000, 1500000000, 1800000000, 2100000000, 2400000000, 2700000000,
                3000000000, 3300000000, 3600000000,
              ],
              blockAfterStart: true,
            },
          },
        ],
      },
    },
  },
  ems_massages: {
    calibrationFlow: 'ems/stella',
    presentationTemplate: 'time',
    type: 'ems',
    tags: ['ems'],
    ems: {
      program: {
        defaultChannelValues: [
          {
            channelIndex: 0,
            fallTime: 0,
            delay: 0,
            pulseFrequency: 80,
            pulseShape: 100,
            pulseWidth: 250,
            riseTime: 0,
            runTime: 20000000,
          },
        ],
        maxIntensity: [0.1],
        phases: [
          {
            channels: [],
          },
          {
            channels: [
              {
                channelIndex: 0,
                pulseFrequency: 75,
              },
            ],
          },
          {
            channels: [
              {
                channelIndex: 0,
                pulseFrequency: 10,
              },
            ],
          },
          {
            channels: [
              {
                channelIndex: 0,
                pulseFrequency: 70,
              },
            ],
          },
          {
            channels: [
              {
                channelIndex: 0,
                pulseFrequency: 65,
              },
            ],
          },
        ],
        phasesRepetition: 18,
        stimCalibration: [
          {
            channelIndex: 0,
            fallTime: 0,
            delay: 0,
            pulseFrequency: 80,
            pulseShape: 100,
            pulseWidth: 250,
            riseTime: 0,
            runTime: 5000000,
          },
        ],
        programTime: 1800000000,
        minRequiredChannels: 1,
        maxSupportedChannels: 8,
        tags: ['ems'],
        allowedCableChannelFeatures: ['ems'],
      },
      parameters: {
        phases: [],
        phaseRepetition: {
          unit: 'number',
          default: 18,
          values: [3, 6, 9, 12, 15, 18, 21, 24, 27, 30, 33, 36],
          blockAfterStart: true,
        },
      },
    },
  },
  ems_endurance_training: {
    calibrationFlow: 'ems/stella',
    presentationTemplate: 'phases-time',
    type: 'ems',
    tags: ['ems'],
    ems: {
      program: {
        defaultChannelValues: [
          {
            channelIndex: 0,
            fallTime: 0,
            delay: 0,
            pulseFrequency: 10,
            pulseShape: 100,
            pulseWidth: 300,
            riseTime: 0,
            runTime: 300000000,
          },
        ],
        maxIntensity: [0.1],
        phases: [
          {
            channels: [],
          },
          {
            channels: [
              {
                channelIndex: 0,
                fallTime: 1000000,
                pulseFrequency: 40,
                riseTime: 2000000,
                runTime: 1200000000,
                plateauTime: 8000000,
                pauseTime: 16000000,
              },
            ],
          },
          {
            channels: [
              {
                channelIndex: 0,
                pulseFrequency: 3,
              },
            ],
          },
        ],
        phasesRepetition: 1,
        stimCalibration: [
          {
            channelIndex: 0,
            fallTime: 0,
            delay: 0,
            pulseFrequency: 10,
            pulseShape: 100,
            pulseWidth: 300,
            riseTime: 0,
            runTime: 5000000,
          },
        ],
        programTime: 1800000000,
        minRequiredChannels: 1,
        maxSupportedChannels: 8,
        tags: ['ems'],
        allowedCableChannelFeatures: ['ems'],
      },
    },
  },
  fes_grasp_and_release: {
    calibrationFlow: 'ems/stella',
    presentationTemplate: {
      time: 'time',
      repetitions: 'repetitions-labelled-time',
    },
    type: 'ems',
    tags: ['ems', 'fes'],
    ems: {
      program: {
        defaultChannelValues: [
          {
            channelIndex: 0,
            fallTime: 1000000,
            delay: 0,
            pulseFrequency: 35,
            pulseShape: 100,
            pulseWidth: 200,
            riseTime: 1000000,
            runTime: 1200000000,
            plateauTime: 10000000,
            pauseTime: 10000000,
          },
          {
            channelIndex: 1,
            fallTime: 1000000,
            delay: 3000000,
            pulseFrequency: 35,
            pulseShape: 100,
            pulseWidth: 200,
            riseTime: 1000000,
            runTime: 1200000000,
            plateauTime: 7000000,
            pauseTime: 13000000,
          },
          {
            channelIndex: 2,
            fallTime: 1000000,
            delay: 6000000,
            pulseFrequency: 35,
            pulseShape: 100,
            pulseWidth: 200,
            riseTime: 1000000,
            runTime: 1200000000,
            plateauTime: 4000000,
            pauseTime: 16000000,
          },
        ],
        maxIntensity: [0.1, 0.1, 0.1],
        phases: [
          {
            channels: [],
          },
        ],
        phasesRepetition: 3,
        stimCalibration: [
          {
            channelIndex: 0,
            fallTime: 1000000,
            delay: 0,
            pulseFrequency: 35,
            pulseShape: 100,
            pulseWidth: 200,
            riseTime: 1000000,
            runTime: 22000000,
            plateauTime: 10000000,
            pauseTime: 10000000,
          },
          {
            channelIndex: 1,
            fallTime: 1000000,
            delay: 0,
            pulseFrequency: 35,
            pulseShape: 100,
            pulseWidth: 200,
            riseTime: 1000000,
            runTime: 22000000,
            plateauTime: 7000000,
            pauseTime: 13000000,
          },
          {
            channelIndex: 2,
            fallTime: 1000000,
            delay: 0,
            pulseFrequency: 35,
            pulseShape: 100,
            pulseWidth: 200,
            riseTime: 1000000,
            runTime: 22000000,
            plateauTime: 4000000,
            pauseTime: 16000000,
          },
        ],
        programTime: 1200000000,
        minRequiredChannels: 3,
        maxSupportedChannels: 3,
        tags: ['ems'],
        allowedCableChannelFeatures: ['ems'],
      },
      parameters: {
        durationBasedOn: {
          default: 'time',
          unit: 'none',
          values: ['time', 'repetitions'],
          blockAfterStart: true,
        },
        phaseRepetition: {
          unit: 'number',
          default: 18,
          values: [3, 6, 9, 12, 15, 18, 21, 24, 27, 30, 33, 36],
          blockAfterStart: true,
          availableIf: 'ems.durationBasedOn=repetitions',
        },
        phases: [
          {
            pulseWidth: {
              unit: 'us',
              default: 200,
              values: [50, 100, 150, 200, 250, 300, 350, 500],
              blockAfterStart: true,
            },
            runTime: {
              unit: 'us',
              default: 1200000000,
              values: [
                600000000, 900000000, 1200000000, 1500000000, 1800000000, 2100000000, 2400000000, 2700000000,
                3000000000, 3300000000, 3600000000,
              ],
              blockAfterStart: true,
              availableIf: 'ems.durationBasedOn=time',
            },
          },
        ],
      },
    },
  },
  fes_open_and_close: {
    calibrationFlow: 'ems/stella',
    presentationTemplate: {
      time: 'time',
      repetitions: 'repetitions-labelled-time',
    },
    type: 'ems',
    tags: ['ems', 'fes'],
    ems: {
      program: {
        defaultChannelValues: [
          {
            channelIndex: 0,
            fallTime: 1000000,
            delay: 0,
            pulseFrequency: 35,
            pulseShape: 100,
            pulseWidth: 200,
            riseTime: 1000000,
            runTime: 1200000000,
            plateauTime: 6000000,
            pauseTime: 20000000,
          },
          {
            channelIndex: 1,
            fallTime: 1000000,
            delay: 8000000,
            pulseFrequency: 35,
            pulseShape: 100,
            pulseWidth: 200,
            riseTime: 1000000,
            runTime: 1200000000,
            plateauTime: 8000000,
            pauseTime: 18000000,
          },
          {
            channelIndex: 2,
            fallTime: 1000000,
            delay: 10000000,
            pulseFrequency: 35,
            pulseShape: 100,
            pulseWidth: 200,
            riseTime: 1000000,
            runTime: 1200000000,
            plateauTime: 6000000,
            pauseTime: 20000000,
          },
        ],
        maxIntensity: [0.1, 0.1, 0.1],
        phases: [
          {
            channels: [],
          },
        ],
        phasesRepetition: 3,
        stimCalibration: [
          {
            channelIndex: 0,
            fallTime: 1000000,
            delay: 0,
            pulseFrequency: 35,
            pulseShape: 100,
            pulseWidth: 200,
            riseTime: 1000000,
            runTime: 28000000,
            plateauTime: 6000000,
            pauseTime: 20000000,
          },
          {
            channelIndex: 1,
            fallTime: 1000000,
            delay: 0,
            pulseFrequency: 35,
            pulseShape: 100,
            pulseWidth: 200,
            riseTime: 1000000,
            runTime: 28000000,
            plateauTime: 8000000,
            pauseTime: 18000000,
          },
          {
            channelIndex: 2,
            fallTime: 1000000,
            delay: 0,
            pulseFrequency: 35,
            pulseShape: 100,
            pulseWidth: 200,
            riseTime: 1000000,
            runTime: 28000000,
            plateauTime: 6000000,
            pauseTime: 20000000,
          },
        ],
        programTime: 1200000000,
        minRequiredChannels: 3,
        maxSupportedChannels: 3,
        tags: ['ems'],
        allowedCableChannelFeatures: ['ems'],
      },
      parameters: {
        durationBasedOn: {
          default: 'time',
          unit: 'none',
          values: ['time', 'repetitions'],
          blockAfterStart: true,
        },
        phaseRepetition: {
          unit: 'number',
          default: 18,
          values: [3, 6, 9, 12, 15, 18, 21, 24, 27, 30, 33, 36],
          blockAfterStart: true,
          availableIf: 'ems.durationBasedOn=repetitions',
        },
        phases: [
          {
            pulseWidth: {
              unit: 'us',
              default: 200,
              values: [50, 100, 150, 200, 250, 300, 350, 500],
              blockAfterStart: true,
            },
            runTime: {
              unit: 'us',
              default: 1200000000,
              values: [
                600000000, 900000000, 1200000000, 1500000000, 1800000000, 2100000000, 2400000000, 2700000000,
                3000000000, 3300000000, 3600000000,
              ],
              blockAfterStart: true,
              availableIf: 'ems.durationBasedOn=time',
            },
          },
        ],
      },
    },
  },
  fes_arm_extension_support: {
    calibrationFlow: 'ems/stella',
    presentationTemplate: {
      time: 'time',
      repetitions: 'repetitions-labelled-time',
    },
    type: 'ems',
    tags: ['ems', 'fes'],
    ems: {
      program: {
        defaultChannelValues: [
          {
            channelIndex: 0,
            fallTime: 1000000,
            delay: 0,
            pulseFrequency: 35,
            pulseShape: 100,
            pulseWidth: 200,
            riseTime: 1000000,
            runTime: 1200000000,
            plateauTime: 7000000,
            pauseTime: 9000000,
          },
          {
            channelIndex: 1,
            fallTime: 1000000,
            delay: 1000000,
            pulseFrequency: 35,
            pulseShape: 100,
            pulseWidth: 200,
            riseTime: 1000000,
            runTime: 1200000000,
            plateauTime: 5000000,
            pauseTime: 11000000,
          },
          {
            channelIndex: 2,
            fallTime: 1000000,
            delay: 2000000,
            pulseFrequency: 35,
            pulseShape: 100,
            pulseWidth: 200,
            riseTime: 1000000,
            runTime: 1200000000,
            plateauTime: 4000000,
            pauseTime: 12000000,
          },
        ],
        maxIntensity: [0.1, 0.1, 0.1],
        phases: [
          {
            channels: [],
          },
        ],
        phasesRepetition: 3,
        stimCalibration: [
          {
            channelIndex: 0,
            fallTime: 1000000,
            delay: 0,
            pulseFrequency: 35,
            pulseShape: 100,
            pulseWidth: 200,
            riseTime: 1000000,
            runTime: 18000000,
            plateauTime: 7000000,
            pauseTime: 9000000,
          },
          {
            channelIndex: 1,
            fallTime: 1000000,
            delay: 0,
            pulseFrequency: 35,
            pulseShape: 100,
            pulseWidth: 200,
            riseTime: 1000000,
            runTime: 18000000,
            plateauTime: 5000000,
            pauseTime: 11000000,
          },
          {
            channelIndex: 2,
            fallTime: 1000000,
            delay: 0,
            pulseFrequency: 35,
            pulseShape: 100,
            pulseWidth: 200,
            riseTime: 1000000,
            runTime: 18000000,
            plateauTime: 4000000,
            pauseTime: 12000000,
          },
        ],
        programTime: 1200000000,
        minRequiredChannels: 3,
        maxSupportedChannels: 3,
        tags: ['ems'],
        allowedCableChannelFeatures: ['ems'],
      },
      parameters: {
        durationBasedOn: {
          default: 'time',
          unit: 'none',
          values: ['time', 'repetitions'],
          blockAfterStart: true,
        },
        phaseRepetition: {
          unit: 'number',
          default: 18,
          values: [3, 6, 9, 12, 15, 18, 21, 24, 27, 30, 33, 36],
          blockAfterStart: true,
          availableIf: 'ems.durationBasedOn=repetitions',
        },
        phases: [
          {
            pulseWidth: {
              unit: 'us',
              default: 200,
              values: [50, 100, 150, 200, 250, 300, 350, 500],
              blockAfterStart: true,
            },
            runTime: {
              unit: 'us',
              default: 1200000000,
              values: [
                600000000, 900000000, 1200000000, 1500000000, 1800000000, 2100000000, 2400000000, 2700000000,
                3000000000, 3300000000, 3600000000,
              ],
              blockAfterStart: true,
              availableIf: 'ems.durationBasedOn=time',
            },
          },
        ],
      },
    },
  },
  fes_hand_to_mouth: {
    calibrationFlow: 'ems/stella',
    presentationTemplate: {
      time: 'time',
      repetitions: 'repetitions-labelled-time',
    },
    type: 'ems',
    tags: ['ems', 'fes'],
    ems: {
      program: {
        defaultChannelValues: [
          {
            channelIndex: 0,
            fallTime: 1000000,
            delay: 0,
            pulseFrequency: 35,
            pulseShape: 100,
            pulseWidth: 200,
            riseTime: 1000000,
            runTime: 1200000000,
            plateauTime: 7000000,
            pauseTime: 9000000,
          },
          {
            channelIndex: 1,
            fallTime: 1000000,
            delay: 1000000,
            pulseFrequency: 35,
            pulseShape: 100,
            pulseWidth: 200,
            riseTime: 1000000,
            runTime: 1200000000,
            plateauTime: 5000000,
            pauseTime: 11000000,
          },
          {
            channelIndex: 2,
            fallTime: 1000000,
            delay: 1000000,
            pulseFrequency: 35,
            pulseShape: 100,
            pulseWidth: 200,
            riseTime: 1000000,
            runTime: 1200000000,
            plateauTime: 5000000,
            pauseTime: 11000000,
          },
          {
            channelIndex: 3,
            fallTime: 1000000,
            delay: 1000000,
            pulseFrequency: 35,
            pulseShape: 100,
            pulseWidth: 200,
            riseTime: 1000000,
            runTime: 1200000000,
            plateauTime: 5000000,
            pauseTime: 11000000,
          },
        ],
        maxIntensity: [0.1, 0.1, 0.1, 0.1],
        phases: [
          {
            channels: [],
          },
        ],
        phasesRepetition: 3,
        stimCalibration: [
          {
            channelIndex: 0,
            fallTime: 1000000,
            delay: 0,
            pulseFrequency: 35,
            pulseShape: 100,
            pulseWidth: 200,
            riseTime: 1000000,
            runTime: 18000000,
            plateauTime: 7000000,
            pauseTime: 9000000,
          },
          {
            channelIndex: 1,
            fallTime: 1000000,
            delay: 0,
            pulseFrequency: 35,
            pulseShape: 100,
            pulseWidth: 200,
            riseTime: 1000000,
            runTime: 18000000,
            plateauTime: 5000000,
            pauseTime: 11000000,
          },
          {
            channelIndex: 2,
            fallTime: 1000000,
            delay: 0,
            pulseFrequency: 35,
            pulseShape: 100,
            pulseWidth: 200,
            riseTime: 1000000,
            runTime: 18000000,
            plateauTime: 5000000,
            pauseTime: 11000000,
          },
          {
            channelIndex: 3,
            fallTime: 1000000,
            delay: 0,
            pulseFrequency: 35,
            pulseShape: 100,
            pulseWidth: 200,
            riseTime: 1000000,
            runTime: 18000000,
            plateauTime: 5000000,
            pauseTime: 11000000,
          },
        ],
        programTime: 1200000000,
        minRequiredChannels: 4,
        maxSupportedChannels: 4,
        tags: ['ems'],
        allowedCableChannelFeatures: ['ems'],
      },
      parameters: {
        durationBasedOn: {
          default: 'time',
          unit: 'none',
          values: ['time', 'repetitions'],
          blockAfterStart: true,
        },
        phaseRepetition: {
          unit: 'number',
          default: 18,
          values: [3, 6, 9, 12, 15, 18, 21, 24, 27, 30, 33, 36],
          blockAfterStart: true,
          availableIf: 'ems.durationBasedOn=repetitions',
        },
        phases: [
          {
            pulseWidth: {
              unit: 'us',
              default: 200,
              values: [50, 100, 150, 200, 250, 300, 350, 500],
              blockAfterStart: true,
            },
            runTime: {
              unit: 'us',
              default: 1200000000,
              values: [
                600000000, 900000000, 1200000000, 1500000000, 1800000000, 2100000000, 2400000000, 2700000000,
                3000000000, 3300000000, 3600000000,
              ],
              blockAfterStart: true,
              availableIf: 'ems.durationBasedOn=time',
            },
          },
        ],
      },
    },
  },
  tens_conventional: {
    calibrationFlow: 'ems/stella',
    presentationTemplate: 'time',
    type: 'ems',
    tags: ['ems'],
    ems: {
      program: {
        defaultChannelValues: [
          {
            channelIndex: 0,
            fallTime: 0,
            delay: 0,
            pulseFrequency: 80,
            pulseShape: 100,
            pulseWidth: 150,
            riseTime: 0,
            runTime: 900000000,
          },
        ],
        maxIntensity: [0.1],
        phases: [
          {
            channels: [],
          },
        ],
        phasesRepetition: 1,
        stimCalibration: [
          {
            channelIndex: 0,
            fallTime: 0,
            delay: 0,
            pulseFrequency: 80,
            pulseShape: 100,
            pulseWidth: 150,
            riseTime: 0,
            runTime: 5000000,
          },
        ],
        programTime: 900000000,
        minRequiredChannels: 1,
        maxSupportedChannels: 8,
        tags: ['ems'],
        allowedCableChannelFeatures: ['ems', 'ems-pelvic'],
      },
      parameters: {
        phases: [
          {
            runTime: {
              unit: 'us',
              default: 900000000,
              values: [
                300000000, 600000000, 900000000, 1200000000, 1500000000, 1800000000, 2100000000, 2400000000, 2700000000,
                3000000000, 3300000000, 3600000000,
              ],
              blockAfterStart: true,
            },
          },
        ],
      },
    },
  },
  tens_frequency_modulated: {
    calibrationFlow: 'ems/stella',
    presentationTemplate: 'time',
    type: 'ems',
    tags: ['ems'],
    ems: {
      program: {
        defaultChannelValues: [
          {
            channelIndex: 0,
            fallTime: 0,
            delay: 0,
            pulseFrequency: 2,
            pulseShape: 200,
            pulseWidth: 200,
            riseTime: 0,
            runTime: 900000000,
            plateauTime: 10000000,
          },
        ],
        maxIntensity: [0.1],
        phases: [
          {
            channels: [],
          },
        ],
        phasesRepetition: 1,
        stimCalibration: [
          {
            channelIndex: 0,
            fallTime: 0,
            delay: 0,
            pulseFrequency: 2,
            pulseShape: 200,
            pulseWidth: 200,
            riseTime: 0,
            runTime: 5000000,
            plateauTime: 10000000,
          },
        ],
        programTime: 900000000,
        minRequiredChannels: 1,
        maxSupportedChannels: 2,
        tags: ['ems'],
        allowedCableChannelFeatures: ['ems', 'ems-pelvic'],
      },
      parameters: {
        phases: [
          {
            runTime: {
              unit: 'us',
              default: 900000000,
              values: [
                300000000, 600000000, 900000000, 1200000000, 1500000000, 1800000000, 2100000000, 2400000000, 2700000000,
                3000000000, 3300000000, 3600000000,
              ],
              blockAfterStart: true,
            },
          },
        ],
      },
    },
  },
  tens_acupuncture: {
    calibrationFlow: 'ems/stella',
    presentationTemplate: 'time',
    type: 'ems',
    tags: ['ems'],
    ems: {
      program: {
        defaultChannelValues: [
          {
            channelIndex: 0,
            fallTime: 0,
            delay: 0,
            pulseFrequency: 4,
            pulseShape: 100,
            pulseWidth: 200,
            riseTime: 0,
            runTime: 900000000,
          },
        ],
        maxIntensity: [0.1],
        phases: [
          {
            channels: [],
          },
        ],
        phasesRepetition: 1,
        stimCalibration: [
          {
            channelIndex: 0,
            fallTime: 0,
            delay: 0,
            pulseFrequency: 4,
            pulseShape: 100,
            pulseWidth: 200,
            riseTime: 0,
            runTime: 5000000,
          },
        ],
        programTime: 900000000,
        minRequiredChannels: 1,
        maxSupportedChannels: 8,
        tags: ['ems'],
        allowedCableChannelFeatures: ['ems', 'ems-pelvic'],
      },
      parameters: {
        phases: [
          {
            runTime: {
              unit: 'us',
              default: 900000000,
              values: [
                300000000, 600000000, 900000000, 1200000000, 1500000000, 1800000000, 2100000000, 2400000000, 2700000000,
                3000000000, 3300000000, 3600000000,
              ],
              blockAfterStart: true,
            },
          },
        ],
      },
    },
  },
  tens_burst: {
    calibrationFlow: 'ems/stella',
    presentationTemplate: 'time',
    type: 'ems',
    tags: ['ems'],
    ems: {
      program: {
        defaultChannelValues: [
          {
            channelIndex: 0,
            fallTime: 0,
            delay: 0,
            pulseFrequency: 80,
            pulseShape: 100,
            pulseWidth: 200,
            riseTime: 0,
            runTime: 900000000,
            plateauTime: 400000,
            pauseTime: 100000,
          },
        ],
        maxIntensity: [0.1],
        phases: [
          {
            channels: [],
          },
        ],
        phasesRepetition: 1,
        stimCalibration: [
          {
            channelIndex: 0,
            fallTime: 0,
            delay: 0,
            pulseFrequency: 80,
            pulseShape: 100,
            pulseWidth: 200,
            riseTime: 0,
            runTime: 5000000,
            plateauTime: 400000,
            pauseTime: 100000,
          },
        ],
        programTime: 900000000,
        minRequiredChannels: 1,
        maxSupportedChannels: 8,
        tags: ['ems'],
        allowedCableChannelFeatures: ['ems', 'ems-pelvic'],
      },
      parameters: {
        phases: [
          {
            runTime: {
              unit: 'us',
              default: 900000000,
              values: [
                300000000, 600000000, 900000000, 1200000000, 1500000000, 1800000000, 2100000000, 2400000000, 2700000000,
                3000000000, 3300000000, 3600000000,
              ],
              blockAfterStart: true,
            },
          },
        ],
      },
    },
  },
  tens_user_program: {
    calibrationFlow: 'ems/stella',
    presentationTemplate: 'time',
    type: 'ems',
    tags: ['ems'],
    ems: {
      program: {
        defaultChannelValues: [
          {
            channelIndex: 0,
            fallTime: 0,
            delay: 0,
            pulseFrequency: 80,
            pulseShape: 100,
            pulseWidth: 200,
            riseTime: 0,
            runTime: 900000000,
          },
        ],
        maxIntensity: [0.1],
        phases: [
          {
            channels: [],
          },
        ],
        phasesRepetition: 1,
        stimCalibration: [
          {
            channelIndex: 0,
            fallTime: 0,
            delay: 0,
            pulseFrequency: 80,
            pulseShape: 100,
            pulseWidth: 200,
            riseTime: 0,
            runTime: 5000000,
          },
        ],
        programTime: 900000000,
        minRequiredChannels: 1,
        maxSupportedChannels: 8,
        tags: ['ems'],
        allowedCableChannelFeatures: ['ems', 'ems-pelvic'],
      },
      parameters: {
        phases: [
          {
            pulseFrequency: {
              unit: 'Hz',
              default: 80,
              values: [5, 10, 20, 25, 40, 50, 80, 100],
              blockAfterStart: true,
            },
            pulseWidth: {
              unit: 'us',
              default: 200,
              values: [50, 100, 150, 200, 250, 300, 350, 400, 450, 500],
              blockAfterStart: true,
            },
            runTime: {
              unit: 'us',
              default: 900000000,
              values: [
                300000000, 600000000, 900000000, 1200000000, 1500000000, 1800000000, 2100000000, 2400000000, 2700000000,
                3000000000, 3300000000, 3600000000,
              ],
              blockAfterStart: true,
            },
          },
        ],
      },
    },
  },
  ems_user_program: {
    calibrationFlow: 'ems/stella',
    presentationTemplate: 'time',
    type: 'ems',
    tags: ['ems'],
    ems: {
      program: {
        defaultChannelValues: [
          {
            channelIndex: 0,
            fallTime: 1000000,
            delay: 0,
            pulseFrequency: 50,
            pulseShape: 100,
            pulseWidth: 250,
            riseTime: 1000000,
            runTime: 1800000000,
            plateauTime: 1000000,
            pauseTime: 1000000,
          },
        ],
        maxIntensity: [0.1],
        phases: [
          {
            channels: [],
          },
        ],
        phasesRepetition: 1,
        stimCalibration: [
          {
            channelIndex: 0,
            fallTime: 1000000,
            delay: 0,
            pulseFrequency: 50,
            pulseShape: 100,
            pulseWidth: 250,
            riseTime: 1000000,
            runTime: 5000000,
            plateauTime: 1000000,
            pauseTime: 1000000,
          },
        ],
        programTime: 1800000000,
        minRequiredChannels: 1,
        maxSupportedChannels: 8,
        tags: ['ems'],
        allowedCableChannelFeatures: ['ems'],
      },
      parameters: {
        phases: [
          {
            pauseTime: {
              unit: 'us',
              default: 1000000,
              values: [
                1000000, 2000000, 3000000, 4000000, 5000000, 6000000, 7000000, 8000000, 9000000, 10000000, 11000000,
                12000000, 13000000, 14000000, 15000000, 16000000, 17000000, 18000000, 19000000, 20000000,
              ],
              blockAfterStart: true,
            },
            fallTime: {
              unit: 'us',
              default: 1000000,
              values: [0, 1000000, 2000000, 3000000, 4000000],
              blockAfterStart: true,
            },
            pulseFrequency: {
              unit: 'Hz',
              default: 50,
              values: [5, 10, 20, 25, 40, 50, 80, 100],
              blockAfterStart: true,
            },
            pulseWidth: {
              unit: 'us',
              default: 250,
              values: [50, 100, 150, 200, 250, 300, 350, 400, 450, 500],
              blockAfterStart: true,
            },
            riseTime: {
              unit: 'us',
              default: 1000000,
              values: [0, 1000000, 2000000, 3000000, 4000000],
              blockAfterStart: true,
            },
            runTime: {
              unit: 'us',
              default: 1800000000,
              values: [
                300000000, 600000000, 900000000, 1200000000, 1500000000, 1800000000, 2100000000, 2400000000, 2700000000,
                3000000000, 3300000000, 3600000000,
              ],
              blockAfterStart: true,
            },
            plateauTime: {
              unit: 'us',
              default: 1000000,
              values: [
                1000000, 2000000, 3000000, 4000000, 5000000, 6000000, 7000000, 8000000, 9000000, 10000000, 11000000,
                12000000, 13000000, 14000000, 15000000, 16000000, 17000000, 18000000, 19000000, 20000000,
              ],
              blockAfterStart: true,
            },
          },
        ],
      },
    },
  },
  ems_user_program_pelvic: {
    calibrationFlow: 'ems/stella',
    presentationTemplate: 'time',
    type: 'ems',
    tags: ['ems', 'pelvic'],
    ems: {
      program: {
        defaultChannelValues: [
          {
            channelIndex: 0,
            fallTime: 1000000,
            delay: 0,
            pulseFrequency: 35,
            pulseShape: 100,
            pulseWidth: 200,
            riseTime: 1000000,
            runTime: 1200000000,
            plateauTime: 1000000,
            pauseTime: 2000000,
          },
        ],
        maxIntensity: [0.1],
        phases: [
          {
            channels: [],
          },
        ],
        phasesRepetition: 1,
        stimCalibration: [
          {
            channelIndex: 0,
            fallTime: 1000000,
            delay: 0,
            pulseFrequency: 35,
            pulseShape: 100,
            pulseWidth: 200,
            riseTime: 1000000,
            runTime: 5000000,
            plateauTime: 1000000,
            pauseTime: 2000000,
          },
        ],
        programTime: 1200000000,
        minRequiredChannels: 1,
        maxSupportedChannels: 2,
        tags: ['ems', 'pelvic'],
        allowedCableChannelFeatures: ['ems-pelvic'],
      },
      parameters: {
        phases: [
          {
            pauseTime: {
              unit: 'us',
              default: 2000000,
              values: [
                1000000, 2000000, 3000000, 4000000, 5000000, 6000000, 7000000, 8000000, 9000000, 10000000, 11000000,
                12000000, 13000000, 14000000, 15000000, 16000000, 17000000, 18000000, 19000000, 20000000,
              ],
              blockAfterStart: true,
            },
            fallTime: {
              unit: 'us',
              default: 1000000,
              values: [0, 1000000, 2000000, 3000000, 4000000],
              blockAfterStart: true,
            },
            pulseFrequency: {
              unit: 'Hz',
              default: 35,
              values: [1, 2, 5, 8, 10, 20, 25, 35, 40, 50],
              blockAfterStart: true,
            },
            pulseWidth: {
              unit: 'us',
              default: 200,
              values: [50, 70, 100, 150, 200, 250, 300, 350, 400, 450, 500, 700, 1000],
              blockAfterStart: true,
            },
            riseTime: {
              unit: 'us',
              default: 1000000,
              values: [0, 1000000, 2000000, 3000000, 4000000],
              blockAfterStart: true,
            },
            runTime: {
              unit: 'us',
              default: 1200000000,
              values: [
                300000000, 600000000, 900000000, 1200000000, 1500000000, 1800000000, 2100000000, 2400000000, 2700000000,
                3000000000, 3300000000, 3600000000,
              ],
              blockAfterStart: true,
            },
            plateauTime: {
              unit: 'us',
              default: 1000000,
              values: [
                1000000, 2000000, 3000000, 4000000, 5000000, 6000000, 7000000, 8000000, 9000000, 10000000, 11000000,
                12000000, 13000000, 14000000, 15000000, 16000000, 17000000, 18000000, 19000000, 20000000,
              ],
              blockAfterStart: true,
            },
          },
        ],
      },
    },
  },
});
