export type TimeLinePoint = [number, number];

export type ChannelIndex = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7;
export class ChannelMap<T> {
  _channels: Partial<Record<ChannelIndex, T>> = {};

  constructor() {}

  get channels() {
    return this._channels;
  }

  channelExists(channelIndex: ChannelIndex) {
    return !!this._channels[channelIndex];
  }

  get(channelIndex: ChannelIndex) {
    const ret = this._channels[channelIndex];
    if (ret === undefined) {
      throw new Error('ChannelMap::You can not get values from non-existent channel');
    }
    return ret;
  }

  set(channel: ChannelIndex, value: T) {
    this._channels[channel] = value;
  }

  keys() {
    return Object.keys(this._channels).map(v => Number(v) as ChannelIndex);
  }

  values() {
    return Object.values(this._channels) as T[];
  }

  entries() {
    return Object.entries(this._channels).map(
      ([k, v], i) => [Number(k) as ChannelIndex, v, i] as [ChannelIndex, T, number],
    );
  }

  map(fn: (value: T, channelIndex?: ChannelIndex, index?: number) => T) {
    return Object.entries(this._channels).map(([k, v], i) => fn(v as T, Number(k) as ChannelIndex, i));
  }
}

export function toChannelMap<T>(items: T[], channels?: number[]) {
  const channelMap = new ChannelMap<T>();

  items.forEach((item, index) => channelMap.set((channels ? channels[index] : index) as ChannelIndex, item));

  return channelMap;
}

export type ChannelMap3<T> = Map<ChannelIndex, T>;
