import React, { useMemo, useReducer } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Button, Flex, Image, Table, TableContainer, Tbody } from '@chakra-ui/react';
import Ilustration from 'assets/images/EmptyLastTrainingListIcon.svg';
import colors from 'config/theme/colors';
import { fadeinAnimation } from 'helpers/skeletonFadeInAnimation';
import { useDevice } from 'libs/exo-session-manager/react';
import { useGetRecentTrainingsQuery } from 'queries/trainings';

import { InfiniteScrollBox } from 'components/common/InfiniteScrollBox';
import RecentTrainingSkeleton from 'components/skeletons/trainings/RecentTrainingSkeleton';
import { TranslateText } from 'components/texts/TranslateText';

import { RecentTrainingHeader } from './_components/RecentTrainingHeader';
import { RecentTrainingItem } from './_components/RecentTrainingItem';

const RecentTraining = () => {
  const { patientId } = useParams();
  const { selectedDevice } = useDevice();
  const [page, nextPage] = useReducer(x => x + 1, 0);
  const { currentData, isFetching } = useGetRecentTrainingsQuery({
    path: { userProfileId: patientId ?? '' },
    query: { pageNumber: page, pageSize: 20 },
  });
  const navigate = useNavigate();

  const isData = useMemo(
    () => !isFetching && currentData?.content && currentData?.content.length > 0,
    [currentData, isFetching],
  );

  const handleLoadMore = () => {
    if (!currentData?.last && currentData?.pageable?.pageNumber === page) {
      nextPage();
    }
  };

  return (
    <>
      {Object.keys(currentData ?? {}).length > 0 ? (
        <div>
          <InfiniteScrollBox
            CustomLoadMoreElement={() =>
              !currentData?.last ? (
                <Table mt={2} gap={2}>
                  <Tbody>
                    <RecentTrainingSkeleton />
                    <RecentTrainingSkeleton />
                  </Tbody>
                </Table>
              ) : null
            }
            loadMore={handleLoadMore}
            hasMore={!currentData?.last}
            loading={isFetching}
            px="0"
            py="0"
          >
            <>
              {isData ? (
                <TableContainer bg="white" borderRadius="rMd" shadow="sm" overflowY="hidden">
                  <Table variant="simple">
                    <RecentTrainingHeader />
                    <Tbody fontSize="xl" sx={fadeinAnimation}>
                      {currentData?.content?.map(item => {
                        if (item.trainingResult === 'done' || item.trainingResult === 'catchedUp') {
                          return (
                            <RecentTrainingItem
                              key={item.id}
                              training={item}
                              deviceType={selectedDevice?.type ?? 'unspecified'}
                            />
                          );
                        }
                      })}
                    </Tbody>
                  </Table>
                </TableContainer>
              ) : (
                <Flex flexDirection="column" alignItems="center" mt={{ base: 20, 'xl+': 40 }}>
                  <Box>
                    <Image src={Ilustration} />
                  </Box>
                  <TranslateText
                    mt={10}
                    variant="openSans24Bold"
                    color="gray.400"
                    text={'application.recentTrainingsWillAppearHere'}
                  />
                  <Button
                    type="button"
                    px={20}
                    py={7}
                    bg="white"
                    borderRadius="rSm"
                    mt={{ base: 20, 'xl+': 40 }}
                    onClick={() => navigate('../')}
                  >
                    <TranslateText
                      variant="openSans24Bold"
                      color={colors.egzotechPrimaryColor}
                      text={'application.startFirstTraining'}
                    />
                  </Button>
                </Flex>
              )}
            </>
          </InfiniteScrollBox>
        </div>
      ) : (
        <Table mt={2} gap={2}>
          <Tbody>
            <RecentTrainingSkeleton />
            <RecentTrainingSkeleton />
          </Tbody>
        </Table>
      )}
    </>
  );
};

export default RecentTraining;
