import { useEffect } from 'react';
import { useSignals } from '@preact/signals-react/runtime';
import { __ } from 'helpers/i18n';
import { Signal, useComputed, useSignal } from 'helpers/signal';
import { EMGRealtimeChartDataSource } from 'libs/chart-datasources/EMGChartDataSource';
import { EMGExerciseDefinition } from 'libs/exo-session-manager/core';

import { TimelineChart } from 'components/timeline-charts/TimelineChart';

/**
 * Width in seconds of emg chart
 */
export const EMG_WINDOW_WIDTH = 20;

export const EMGTimelineChart = (props: {
  chartDataSource: EMGRealtimeChartDataSource;
  emgExerciseDefinition?: EMGExerciseDefinition;
  yMax: number;
  yMaxMvc: number;
  hideChannels?: number[];
  selectedSegment?: Signal<number | undefined>;
}) => {
  useSignals();
  const { chartDataSource, yMax, yMaxMvc, hideChannels, emgExerciseDefinition, selectedSegment } = props;

  const time = useSignal(chartDataSource.timeSource.duration, '');

  useEffect(() => {
    const interval = setInterval(() => {
      time.value = chartDataSource.timeSource.duration;
    }, 50);

    return () => {
      clearInterval(interval);
    };

    // initialize interval only once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const chartDataSources = useComputed(() => {
    return [chartDataSource];
  }, 'EMGTimelineChart.chartDataSources');

  return (
    <TimelineChart
      chartMode="realtime"
      time={time}
      chartDataSources={chartDataSources}
      selectedSegment={selectedSegment}
      options={{
        shiftDatasetsOnAxisY: false,
        showTimeMark: false,
        windowWidth: EMG_WINDOW_WIDTH,
        yAxisUnit: __('units.microvolts'),
        yAxisMax: yMax,
        yAxisMvc: yMaxMvc,
        hideChannels,
        emgExerciseDefinition,
      }}
    />
  );
};
