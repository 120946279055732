import { MultiChannelSignalRecorder, Recordable, SignalRecorder } from '@egzotech/exo-session/features/common';
import { SampleBasedTimer } from 'libs/exo-session-manager/core/common/SampleBasedTimer';

export type DefaultSignalRecorderId =
  | `${'knee' | 'toes' | 'heel' | 'torque' | 'extension'}-force`
  | `${'knee' | 'ankle' | 'main'}-angle`;

export type Recordings<T extends string = DefaultSignalRecorderId> = {
  [key in T]?: {
    samples: Float32Array;
    timePoints: Uint32Array;
  };
} & {
  emg?: Record<
    number,
    {
      samples: Float32Array;
      timePoints: Uint32Array;
    }
  >;
};

export type RecorderController<T extends string = DefaultSignalRecorderId> =
  | {
      id: T;
      recorder: SignalRecorder;
    }
  | {
      id: 'emg';
      recorder: MultiChannelSignalRecorder;
    };

export class SignalRecorderController {
  private recorders: RecorderController[] = [];
  private intervalId: NodeJS.Timer | null = null;

  private _startTime = 0;

  static INTERVAL_TIME = 50;

  get started() {
    return this._startTime !== 0;
  }

  get timestamp() {
    if (this.timeSource) {
      return this.timeSource.duration * 1000;
    }
    return Date.now() - this._startTime;
  }

  private getTimestampForChannel(channel: number) {
    return this.timeSource ? this.timeSource.getTimestampForChannel(channel) * 1000 : this.timestamp;
  }

  constructor(
    private recordables: Recordable<'single' | 'multi'>[],
    private channels?: number[],
    private timeSource?: SampleBasedTimer,
  ) {
    recordables.forEach(recordable => {
      if (recordable.recordableType === 'multi') {
        if (recordable.recordableId === 'emg' && channels) {
          this.recorders.push({ id: recordable.recordableId, recorder: new MultiChannelSignalRecorder(channels) });
        }
      } else {
        this.recorders.push({ id: recordable.recordableId as DefaultSignalRecorderId, recorder: new SignalRecorder() });
      }
    });
  }

  start() {
    this.recorders.forEach(v => v.recorder.start());
    this._startTime = Date.now();
    this.record();
  }

  pause() {
    this.recorders.forEach(v => v.recorder.pause());
    if (this.intervalId !== null) {
      clearInterval(this.intervalId);
      this.intervalId = null;
    }
  }

  resume() {
    this.recorders.forEach(v => v.recorder.resume());
    this.record();
  }

  reset() {
    this.pause();
    this.recorders.forEach(v => v.recorder.stop());
  }

  private record() {
    this.intervalId = setInterval(() => {
      this.recordables.forEach(recordable => {
        if (recordable.recordableType === 'multi' && recordable.recordableId === 'emg') {
          const emgRecorder = this.recorders.find(({ id }) => id === recordable.recordableId)?.recorder as
            | MultiChannelSignalRecorder
            | undefined;
          const snapshot = (recordable as Recordable<'multi'>).getSnapshot();

          // Timestamp for each channel can be different
          for (const _shapshotChannel in snapshot) {
            const snapshotChannel = Number(_shapshotChannel);
            emgRecorder?.record(
              { [Number(snapshotChannel)]: snapshot[snapshotChannel] } as Record<number, number>,
              this.getTimestampForChannel(snapshotChannel),
            );
          }
        } else {
          const recorder = this.recorders.find(({ id }) => id === recordable.recordableId)?.recorder as SignalRecorder;
          const snapshot = (recordable as Recordable<'single'>).getSnapshot();
          const lastSnapshotValue = snapshot?.at(-1);
          if (recorder && typeof lastSnapshotValue === 'number') {
            recorder.record(lastSnapshotValue, this.timestamp);
          }
        }
      });
    }, SignalRecorderController.INTERVAL_TIME);
  }

  retrieveRange({ min, max, recorderId }: { min: number; max: number; recorderId?: 'emg' }): ({
    samples: Float32Array;
    timePoints: Uint32Array;
  } | null)[];
  retrieveRange({ min, max, recorderId }: { min: number; max: number; recorderId?: DefaultSignalRecorderId }): {
    samples: Float32Array;
    timePoints: Uint32Array;
  } | null;
  retrieveRange({
    min,
    max,
    recorderId = 'emg',
  }: {
    min: number;
    max: number;
    recorderId?: DefaultSignalRecorderId | 'emg';
  }) {
    const recorder = this.recorders.find(rec => rec.id === recorderId)?.recorder;

    if (!recorder) {
      throw new Error('EMG recorder is not present');
    }

    return recorder.retrieveRange({ min, max });
  }

  stop() {
    this.pause();
    this._startTime = 0;
    const recordings: Recordings = {};
    this.recorders.forEach(v => {
      if (v.id === 'emg') {
        const emgRecorder = v.recorder as MultiChannelSignalRecorder;
        const emg = emgRecorder.stop().reduce(
          (prev, curr, i) => {
            if (curr && this.channels) {
              prev[this.channels[i]] = curr;
              return prev;
            }
            return prev;
          },
          {} as Record<
            number,
            {
              samples: Float32Array;
              timePoints: Uint32Array;
            }
          >,
        );
        recordings.emg = emg;
      } else {
        const recorder = v.recorder as SignalRecorder;
        const record = recorder.stop();
        if (record) {
          recordings[v.id] = record;
        }
      }
    });
    return recordings;
  }
}
