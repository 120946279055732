import { MuscleId } from 'config/muscles';
import { MuscleToChannelMap } from 'views/+patientId/training/+trainingId/_components/ChannelMuscleSelector';
import { ChannelToRoleMap } from 'views/+patientId/training/+trainingId/_components/ChannelRoleSelector';

import { CalibrationFlow } from '../common/CalibrationFlow';

export const onConditionalFunctionsCallback = {
  skipRoleScreen: (flow: CalibrationFlow) => {
    const muscles = flow.getStateData('channel-muscle-selector')?.muscleToChannel as MuscleToChannelMap | null;

    const exerciseType = flow.context.selectedProgram.type;
    if (!muscles) {
      throw new Error('Something went wrong. You should have muscles data.');
    }

    if (exerciseType === 'emg') {
      const channelWithMuscles = Object.entries(muscles)
        .map(v => ({ channel: v[1], muscle: v[0] as MuscleId }))
        .sort((a, b) => a.channel - b.channel);
      const initialMap = {} as ChannelToRoleMap;
      for (const channelWithMuscle of channelWithMuscles) {
        initialMap[channelWithMuscle.channel] = ['emg'];
      }
      flow.setStateData(
        {
          channelRolesInstances: { ...{ [0]: initialMap } },
        },
        'channel-role-selector',
      );
      return true;
    }
    return false;
  },
};
