import { DeepReadonly } from '@egzotech/exo-session';
import { CPMProgram, CPMProgramPhase } from '@egzotech/exo-session/features/cpm';
import { MovementType } from '@egzotech/exo-session/features/motor';
import { DurationBasedOn, TriggeringType } from 'libs/exo-session-manager/core/settings/SettingsTemplates';

import { SensorsName, triggeringMethod } from '../types';

import { MotorPlacement, ProgramParameterDefinition, ProgramParameters } from './GeneratedProgramDefinition';

/**
 * Program parameters structure for CPM programs
 */
export interface CPMParameters {
  maxTime?: ProgramParameterDefinition<'s'>;
  maxTorque?: ProgramParameterDefinition<'kg'>;
  maxBackwardForceLimit?: ProgramParameterDefinition<'kg'>;
  triggeringType?: ProgramParameterDefinition<'none', TriggeringType>;
  durationBasedOn?: ProgramParameterDefinition<'none', DurationBasedOn>;
  phases?: ({
    time?: ProgramParameterDefinition<'s'>;
    repetitions?: ProgramParameterDefinition<'number'>;
    speed?: ProgramParameterDefinition<'deg/s'>;
    pauseTimeInROMMin?: ProgramParameterDefinition<'s'>;
    pauseTimeInROMMax?: ProgramParameterDefinition<'s'>;
    movementType?: ProgramParameterDefinition<'none', MovementType>;
    forceTriggerSource?: ProgramParameterDefinition<'none', SensorsName>;
    triggeringMethod?: ProgramParameterDefinition<'none', triggeringMethod>;
  } | null)[];
}

export type CPMProgramParameters = ProgramParameters & CPMParameters;

/**
 * The interface that defines additional configuration specified in parameters sheet (not used by device itself)
 */
export interface CPMProgramAdditionalConfiguration {
  maxTime?: number;
  maxTorque?: number;
  maxBackwardForceLimit?: number;
  triggeringType?: TriggeringType;
  durationBasedOn?: DurationBasedOn;
  phases: Array<
    CPMProgramPhase & {
      /**
       * Duration of pause while device is not moving when reached to the min angle
       */
      pauseTimeInROMMin?: number;
      /**
       * Duration of pause while device is not moving when reached to the max angle
       */
      pauseTimeInROMMax?: number;

      /**
       * Behaviour of triggers in EMG based programs
       */
      triggeringMethod?: triggeringMethod;
      forceTriggerSource?: SensorsName;
    }
  >;
}

/**
 * The interface that defines the full definition of the CPM program specified in parameters sheet
 */
export interface CPMProgramDefinitionPrimary {
  program: CPMProgram & CPMProgramAdditionalConfiguration;
  parameters?: CPMProgramParameters;
}

export interface CPMProgramDefinitionSynchronized {
  program: null;
  synchronized: MotorPlacement;
}

export interface CPMProgramDefinitionPassive {
  program: null;
}

export type CPMProgramDefinition =
  | CPMProgramDefinitionPrimary
  | CPMProgramDefinitionSynchronized
  | CPMProgramDefinitionPassive;

export type GeneratedCPMProgramDefinitionPrimary = DeepReadonly<CPMProgramDefinitionPrimary>;
export type GeneratedCPMProgramDefinitionSynchronized = DeepReadonly<CPMProgramDefinitionSynchronized>;
export type GeneratedCPMProgramDefinitionPassive = DeepReadonly<CPMProgramDefinitionPassive>;
export type GeneratedCPMProgramDefinition = DeepReadonly<CPMProgramDefinition>;

export function isGeneratedCPMProgramDefinitionPrimary(
  obj: GeneratedCPMProgramDefinition,
): obj is GeneratedCPMProgramDefinitionPrimary {
  return obj.program !== null && !('synchronized' in obj);
}

export function isGeneratedCPMProgramDefinitionSynchronized(
  obj: GeneratedCPMProgramDefinition,
): obj is GeneratedCPMProgramDefinitionSynchronized {
  return obj.program === null && 'synchronized' in obj;
}

export function isGeneratedCPMProgramDefinitionPassive(
  obj: GeneratedCPMProgramDefinition,
): obj is GeneratedCPMProgramDefinitionSynchronized {
  return obj.program === null && !('synchronized' in obj);
}
