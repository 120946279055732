import { StimChannelConfiguration } from '@egzotech/exo-session/features/electrostim';
import { logger } from 'helpers/logger';
import { EMSExerciseDefinition } from 'libs/exo-session-manager/core';

import { RealtimeChartDataSource as RealtimeChartDataSource } from './ChartDataSource';
import { ChannelIndex, ChannelMap, TimeLinePoint } from './types';

const TIME_REDUCTOR = 1000 * 1000; // convert us to seconds
type PreparedElectrostimData = {
  channelIndex: ChannelIndex;
  programTime: number;
  phases: StimChannelConfiguration[];
};

type PreparedElectrostimProgram = ChannelMap<PreparedElectrostimData>;

export type NormalizedPhase = {
  runTime: number;
  phaseIndex: number;
  elements: {
    delayTime: number;
    riseTime: number;
    plateauTime: number;
    fallTime: number;
    pauseTime: number;
  };
};
/**
 * ElectrostimChartDataSource is responsible for:
 *  - analyzing all electrostim programs
 *  - creating an envelope of signal shape
 *
 * Output data format is suited for EChart library
 */
export class ElectrostimChartDataSource extends RealtimeChartDataSource {
  private _normalizedPhases: ChannelMap<NormalizedPhase[]> = new ChannelMap();

  get normalizedPhases() {
    return this._normalizedPhases;
  }

  constructor() {
    super();
  }

  dispose() {
    super.dispose();
  }

  setProgram(exerciseDefinition: EMSExerciseDefinition, channelMapping: Record<number, number>) {
    const preparedProgram = this.prepareElectrostimProgram(exerciseDefinition, channelMapping);

    logger.info('ElectrostimChartTimelineGenerator.setProgram', 'preparedProgram', preparedProgram);
    this.analyzeElectrostimProgram(preparedProgram);
  }

  private prepareElectrostimProgram(exerciseDefinition: EMSExerciseDefinition, channelMapping: Record<number, number>) {
    const phases: PreparedElectrostimProgram = new ChannelMap();
    const emsProgram = exerciseDefinition.ems.program;

    const programTime = emsProgram.programTime;

    for (const _channelIndex of Object.keys(channelMapping)) {
      const channelIndex = parseInt(_channelIndex) as ChannelIndex;
      const sourceChannel = channelMapping[channelIndex];

      const defaultChannelValues = emsProgram.defaultChannelValues.find(v => v.channelIndex == sourceChannel);
      if (!defaultChannelValues) {
        continue;
      }
      const updatedChannelValues = { ...defaultChannelValues, channelIndex };

      for (const [_phaseIdx, phase] of Object.entries(emsProgram.phases)) {
        const phaseIdx = Number(_phaseIdx);

        const stimChannel = {
          ...updatedChannelValues,
          ...phase.channels[sourceChannel],
        };

        if (!phases.channelExists(channelIndex)) {
          phases.set(channelIndex, {
            programTime,
            channelIndex,
            phases: [],
          });
        }
        phases.get(channelIndex).phases[phaseIdx] = stimChannel;
      }
    }
    return phases;
  }

  private analyzeElectrostimProgram(program: PreparedElectrostimProgram) {
    for (const [_, channelData] of program.entries()) {
      const programTime = channelData.programTime / TIME_REDUCTOR;

      let channelTimeOffset = 0;
      const channelIndex = channelData.channelIndex as ChannelIndex;

      this.timelines.set(channelIndex, []);
      this._normalizedPhases.set(channelIndex, []);

      do {
        for (const [phaseIndex, phase] of Object.entries(channelData.phases)) {
          const prevPoint = this.timelines.get(channelIndex)?.at(-1);
          if (prevPoint) {
            channelTimeOffset = channelTimeOffset = prevPoint[0];
          }
          const normalizedPhase = this.normalizePhase(phase, Number(phaseIndex));
          this._normalizedPhases.get(channelIndex).push(...normalizedPhase);

          const timeline = this.electrostimConvertPhaseToPoints(normalizedPhase, channelTimeOffset);
          channelTimeOffset = timeline.at(-1)?.[0] ?? channelTimeOffset;

          this.timelines.get(channelIndex)?.push(...timeline);
        }
      } while (!programTime || channelTimeOffset < programTime);
    }
  }

  private normalizePhase(stimChannel: StimChannelConfiguration, phaseIndex: number): NormalizedPhase[] {
    const phaseElements = {
      delayTime: stimChannel.delay / TIME_REDUCTOR,
      riseTime: stimChannel.riseTime / TIME_REDUCTOR,
      plateauTime: (stimChannel.plateauTime ?? 0) / TIME_REDUCTOR,
      fallTime: stimChannel.fallTime / TIME_REDUCTOR,
      pauseTime: ((stimChannel.pauseTime ?? 0) - stimChannel.delay) / TIME_REDUCTOR,
    };
    const phaseRunTime = stimChannel.runTime / TIME_REDUCTOR;

    let stepRunTime = Object.values(phaseElements).reduce((acc, val) => acc + val, 0);

    if (!stepRunTime) {
      // If there is no phase parameters like plateau/rise/fall/pause/delay
      // Then assume that the signal envelope is constant for the whole phase
      stepRunTime = phaseRunTime;
      phaseElements.plateauTime = phaseRunTime;
    } else if (stimChannel.plateauTime === undefined) {
      // If the plateauTime is not defined then assume than the signal envelope
      // always fill the whole phase, so we have to calculate the plateauTime
      phaseElements.plateauTime = phaseRunTime - stepRunTime;
      stepRunTime = phaseRunTime;
    }

    const result = [];
    let currentPhaseTime = 0;

    while (currentPhaseTime < phaseRunTime) {
      let item: NormalizedPhase | undefined = undefined;
      const elements = Object.entries(phaseElements)
        .map(([k, v]) => {
          const elementTime = currentPhaseTime + v > phaseRunTime ? phaseRunTime - currentPhaseTime : v;
          return [k, elementTime] as [keyof NormalizedPhase['elements'], number];
        })
        .reduce((acc, [k, v]) => {
          acc[k] = v;
          return acc;
        }, {} as NormalizedPhase['elements']);

      if (currentPhaseTime + stepRunTime <= phaseRunTime) {
        item = {
          runTime: stepRunTime,
          phaseIndex,
          elements,
        };
        currentPhaseTime += stepRunTime;
      } else {
        item = {
          runTime: stepRunTime,
          phaseIndex,
          elements: {
            pauseTime: phaseRunTime - currentPhaseTime,
            plateauTime: 0,
            riseTime: 0,
            fallTime: 0,
            delayTime: 0,
          },
        };
        currentPhaseTime += phaseRunTime - currentPhaseTime;
      }
      result.push(item);
    }

    return result;
  }

  private electrostimConvertPhaseToPoints(_normalizedPhases: NormalizedPhase[], timeOffset = 0): TimeLinePoint[] {
    const timeline: TimeLinePoint[] = [];

    let offsetInNormalizedPhases = 0;
    timeline.push([timeOffset + offsetInNormalizedPhases, 0]);
    for (const phase of _normalizedPhases) {
      const hasElectrostimulation = (['riseTime', 'plateauTime', 'fallTime'] as const).reduce(
        (acc, cur) => acc + phase.elements[cur],
        0,
      );
      const hasRest = (['pauseTime', 'fallTime', 'delayTime'] as const).reduce(
        (acc, cur) => acc + phase.elements[cur],
        0,
      );

      for (const key of Object.keys(phase.elements) as (keyof typeof phase.elements)[]) {
        const elementTime = phase.elements[key];
        offsetInNormalizedPhases += elementTime;
        switch (key) {
          case 'riseTime':
          case 'plateauTime':
            timeline.push([timeOffset + offsetInNormalizedPhases, hasElectrostimulation ? 100 : 0]);
            break;

          case 'delayTime':
          case 'fallTime':
          case 'pauseTime':
            timeline.push([timeOffset + offsetInNormalizedPhases, hasRest ? 0 : 100]);
            break;
        }
      }
    }

    return timeline;
  }
}
