import { ReadonlySignal, Signal, signal } from 'helpers/signal';

import { ChannelIndex, ChannelMap, TimeLinePoint } from './types';

export function binarySearch(
  data: TimeLinePoint[],
  time: number,
  fn: (data: TimeLinePoint[], index: number, time: number) => number,
) {
  let minIndex = 0;
  let maxIndex = data.length - 1;

  while (maxIndex >= minIndex) {
    const index = Math.floor((maxIndex - minIndex) / 2) + minIndex;
    const result = fn(data, index, time);
    if (!result) {
      return index;
    }
    if (result < 0) {
      maxIndex = index - 1;
    } else {
      minIndex = index + 1;
    }
  }
  return null;
}

interface ChartDataSource {
  timelinesSignal: ReadonlySignal<ChannelMap<TimeLinePoint[]>>;
  timelines: ChannelMap<TimeLinePoint[]>;
  dispose(): void;
  reset(): void;
}

export class StaticChartDataSource implements ChartDataSource {
  protected _timelinesSignal = signal(new ChannelMap<TimeLinePoint[]>(), 'StaticChartDataSource._timelinesSignal');

  get timelines() {
    return this._timelinesSignal.peek();
  }

  get timelinesSignal() {
    return this._timelinesSignal;
  }

  constructor() {}
  dispose() {}

  reset() {
    this._timelinesSignal.value = new ChannelMap<TimeLinePoint[]>();
  }
}

export class RealtimeChartDataSource implements ChartDataSource {
  protected _timelinesSignal = signal(new ChannelMap<TimeLinePoint[]>(), 'RealtimeChartDataSource._timelinesSignal');

  get timelines() {
    return this._timelinesSignal.peek();
  }

  get timelinesSignal() {
    return this._timelinesSignal;
  }

  protected _time: Signal<number> = signal(0, '');

  get time() {
    return this._time;
  }

  constructor() {}
  dispose() {}

  reset() {
    this._timelinesSignal.value = new ChannelMap<TimeLinePoint[]>();
  }

  getTimelineValue(channelIndex: ChannelIndex, time: number) {
    if (!this.timelines.channelExists(channelIndex)) {
      return null;
    }
    const index = this.findIndexOfPoint(time, channelIndex);
    if (!index) {
      return null;
    }

    const prevPoint = this.timelines.get(channelIndex)[index - 1];
    const currPoint = this.timelines.get(channelIndex)[index];

    const dy = currPoint[1] - prevPoint[1];
    const dx = currPoint[0] - prevPoint[0];

    const px = time - prevPoint[0];
    const py = dx === 0 ? prevPoint[1] : (dy / dx) * px;

    return py + prevPoint[1];
  }

  private findIndexOfPoint(time: number, channel: ChannelIndex) {
    if (!this.timelines.get(channel)) {
      return null;
    }
    const timeline = this.timelines.get(channel) ?? [];

    const index = binarySearch(timeline, time, (data, index, time) => {
      return time < data[index > 0 ? index - 1 : 0][0] ? -1 : time > data[index][0] ? 1 : 0;
    });
    return index;
  }
}
