const MICROVOLTS_UNITS = {
  uV: 1,
  mV: 1e3,
  V: 1e6,
} as const;

export const toMicroVolts = (value: number, unit: keyof typeof MICROVOLTS_UNITS) => {
  return value * MICROVOLTS_UNITS[unit];
};

const MILISECONDS_UNITS = {
  ns: 1e-6,
  us: 1e-3,
  ms: 1,
  s: 1e3,
  m: 60,
  h: 3600,
  d: 3600 * 24,
} as const;

export const toMiliseconds = (value: number, unit: keyof typeof MILISECONDS_UNITS) => {
  return value * MILISECONDS_UNITS[unit];
};

export const convertCmToM = (cm: number): number => cm / 100;
export const convertMToCm = (m: number): number => m * 100;

/**
 * Calculates sensitivity based on what speed should be achieved for what weight. For example if we want to move
 * the device 10 deg/s when 10kg is applied to tensometer then we should pass those values to this function.
 * @param weight Weight in kg
 * @param speed Expected speed in deg/s
 */
export function calculateSensitivity(weight: number, speed: number) {
  return speed / weight;
}

export const toPercentage = (value: number, maxValue: number) => {
  if (isNaN(value) || isNaN(maxValue) || maxValue === 0) {
    throw new Error('Inputs must be valid numbers, and maxValue must not be zero');
  }
  return (value / maxValue) * 100;
};
