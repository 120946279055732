import { useCallback, useMemo } from 'react';
import { BiLeftArrow, BiRightArrow } from 'react-icons/bi';
import { BoxProps, Flex, IconButton, Text } from '@chakra-ui/react';
import { useSignals } from '@preact/signals-react/runtime';
import { ModalType } from 'containers/modals/Modal';
import { DataZoomComponentOption } from 'echarts';
import { __ } from 'helpers/i18n';
import { Signal, useComputed } from 'helpers/signal';
import { useAppDispatch } from 'hooks/store';
import { ChannelMap, RealtimeChartDataSource, StaticChartDataSource, TimeLinePoint } from 'libs/chart-datasources';
import { useEMGExerciseInstance } from 'libs/exo-session-manager/react';
import { openModal } from 'slices/modalSlice';

import { AddNoteIcon } from 'components/icons';
import {
  DEFAULT_WINDOW_WIDTH,
  TimeLineChartOptions,
  useRealtimeTimelineChart,
  useStaticTimelineChart,
} from 'components/timeline-charts/hooks';

type TimelineChartProps = BoxProps & {
  chartMode: 'realtime' | 'static';
  chartDataSources: Signal<RealtimeChartDataSource[] | StaticChartDataSource[]>;
  options: TimeLineChartOptions;
  time?: Signal<number>;
  selectedSegment?: Signal<number | undefined>;
};

type RealtimeTimelineChartProps = TimelineChartProps & {
  timeLinePoints: ChannelMap<TimeLinePoint[]>[];
  time: Signal<number>;
};

type StaticTimelineChartProps = TimelineChartProps & {
  timeLinePoints: ChannelMap<TimeLinePoint[]>[];
  time?: never;
};

function RealtimeTimelineChart({
  timeLinePoints,
  options,
  time,
  selectedSegment,
  chartDataSources: _chartDataSources,
  chartMode: _chartMode,
  ...boxProps
}: RealtimeTimelineChartProps) {
  useSignals();
  const { chartRef } = useRealtimeTimelineChart(timeLinePoints[0], time, options, selectedSegment);
  return <Flex ref={chartRef} w="full" h="full" justify="center" align="center" {...boxProps} />;
}

function StaticTimelineChart({
  timeLinePoints,
  selectedSegment,
  options,
  chartDataSources: _chartDataSources,
  chartMode: _chartMode,
  ...boxProps
}: StaticTimelineChartProps) {
  useSignals();
  const dispatch = useAppDispatch();
  const emgExercise = useEMGExerciseInstance();

  const xMax = useMemo(() => {
    const lastPoint = timeLinePoints.at(-1);
    const firstAvailableChannel = lastPoint
      ? Object.values(lastPoint.channels).find(channel => channel?.length > 0)
      : undefined;
    const lastChannelValue = firstAvailableChannel?.at(-1)?.[0];
    return lastChannelValue !== undefined ? lastChannelValue / 1000 : 0;
  }, [timeLinePoints]);

  const zoomIsEnabled = useMemo(
    () => xMax > (options.windowWidth ?? DEFAULT_WINDOW_WIDTH),
    [options.windowWidth, xMax],
  );

  const { chartRef, echartInstance, echartOptions, recalculateGraphicPosition, currentSegment } =
    useStaticTimelineChart(timeLinePoints, xMax, options, selectedSegment);

  const name = useComputed(
    () =>
      typeof currentSegment.value === 'number'
        ? emgExercise?.emgSegmentAnalyzer?.segments.value[currentSegment.value].name ?? ''
        : '',
    'AddNoteModal.name',
  );

  const description = useComputed(
    () =>
      typeof currentSegment.value === 'number'
        ? emgExercise?.emgSegmentAnalyzer?.segments.value[currentSegment.value].description ?? ''
        : '',
    'AddNoteModal.description',
  );

  const handleArrowClick = useCallback(
    (start: number, end: number) => {
      if (!echartOptions.current) return;
      echartOptions.current.dataZoom = [
        {
          type: 'slider',
          show: false,
          start,
          end,
        },
      ];
      echartInstance.current?.setOption<echarts.EChartsOption>(echartOptions.current);
      recalculateGraphicPosition();
    },
    [echartInstance, echartOptions, recalculateGraphicPosition],
  );

  return (
    <Flex w="full" h="full" position="relative" justify="center" align="center" {...boxProps}>
      {name.value ? (
        <Text
          variant="openSans30Bold"
          color="egzotechPrimaryColor"
          position="absolute"
          left={{ '2xl': '136px', base: '24', 'xl+': '28' }}
          bottom={{ '2xl': '-10', base: '-8', 'xl+': '-9' }}
        >
          {name}
        </Text>
      ) : emgExercise.programData.running.value ? (
        <Text
          variant="openSans30Bold"
          color="egzotechPrimaryColor"
          position="absolute"
          left={{ '2xl': '136px', base: '24', 'xl+': '28' }}
          bottom={{ '2xl': '-10', base: '-8', 'xl+': '-9' }}
        >
          {__('exercise.realtimeView')}
        </Text>
      ) : null}
      <IconButton
        zIndex={20}
        aria-label="add-note"
        variant="exerciseButton"
        position="absolute"
        w={{ base: '10', '2xl': '24', 'xl+': '14' }}
        h={{ base: '10', '2xl': '24', 'xl+': '14' }}
        right={{ '2xl': '24', base: '14', 'xl+': '68px' }}
        bottom={{ '2xl': '-36', base: '-74px', 'xl+': '-96px' }}
        isDisabled={emgExercise.programData.running.value}
        onClick={() => {
          const selected = currentSegment.value;
          if (typeof selected === 'number') {
            dispatch(
              openModal({
                type: ModalType.ADD_NOTE,
                additionalData: {
                  initialName: name.value,
                  initialDescription: description.value,
                  save: (title: string, description: string) => {
                    emgExercise?.emgSegmentAnalyzer?.setSegmentName(selected, title);
                    emgExercise?.emgSegmentAnalyzer?.setSegmentDescription(selected, description);
                  },
                },
              }),
            );
          }
        }}
        icon={
          <AddNoteIcon
            width={{ '2xl': '1.2em', 'xl+': '1em', base: '0.8em' }}
            height={{ '2xl': '1.2em', 'xl+': '1em', base: '0.8em' }}
            color="white"
          />
        }
      />
      {zoomIsEnabled && (
        <IconButton
          zIndex={20}
          aria-label="arrow-left"
          variant="exerciseButton"
          position="absolute"
          w={{ base: '8', '2xl': '16' }}
          h={{ base: '8', '2xl': '16' }}
          left={{ '2xl': '2', base: '1', 'xl+': '2' }}
          top={{ '2xl': '8', base: '12', 'xl+': '12' }}
          isDisabled={emgExercise.programData.running.value}
          onClick={() => {
            const prev = (echartOptions.current?.dataZoom as DataZoomComponentOption[] | undefined)?.at(-1);
            if (prev?.start === 0) return;
            const start = typeof prev?.start === 'number' ? Math.max(prev.start - 10, 0) : 0;
            const end = typeof prev?.end === 'number' ? prev.end - 10 : 100;
            handleArrowClick(start, end);
          }}
          icon={<BiLeftArrow color="white" style={{ height: '0.5em', width: '0.5em' }} />}
        />
      )}
      <Flex ref={chartRef} w="full" h="full" justify="center" align="center" overflow="hidden" />
      {zoomIsEnabled && (
        <IconButton
          zIndex={20}
          aria-label="arrow-right"
          variant="exerciseButton"
          position="absolute"
          w={{ base: '8', '2xl': '16' }}
          h={{ base: '8', '2xl': '16' }}
          right={{ '2xl': '2', base: '1', 'xl+': '2' }}
          top={{ '2xl': '8', base: '12', 'xl+': '12' }}
          isDisabled={emgExercise.programData.running.value}
          onClick={() => {
            const prev = (echartOptions.current?.dataZoom as DataZoomComponentOption[] | undefined)?.at(-1);
            if (prev?.end === 100) return;
            const start = typeof prev?.start === 'number' ? prev.start + 10 : 0;
            const end = typeof prev?.end === 'number' ? Math.min(prev.end + 10, 100) : 100;
            handleArrowClick(start, end);
          }}
          icon={<BiRightArrow color="white" style={{ height: '0.5em', width: '0.5em' }} />}
        />
      )}
    </Flex>
  );
}

export function TimelineChart({ time, ...props }: TimelineChartProps) {
  useSignals();
  const timeLinePoints = useComputed(
    () => props.chartDataSources.value.map(data => data.timelinesSignal.value),
    'TimelineChart.timeLinePoints',
  );
  if (props.chartMode === 'realtime' && time) {
    return <RealtimeTimelineChart time={time} timeLinePoints={timeLinePoints.value} {...props} />;
  }

  return <StaticTimelineChart timeLinePoints={timeLinePoints.value} {...props} />;
}
