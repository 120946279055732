import { DeepReadonly, ExoSession, TimerGroup } from '@egzotech/exo-session';
import { Recordable } from '@egzotech/exo-session/features/common';
import { Logger } from '@egzotech/universal-logger-js';
import { Timers } from 'config/timers';
import { effect, signal } from 'helpers/signal';
import { EMGRealtimeChartDataSource } from 'libs/chart-datasources/EMGChartDataSource';
import {
  EMGArrayElement,
  EMGProgram,
  EMGProgramData,
  EMGProgramStep,
  exerciseActionTracker,
  GeneratedExerciseDefinition,
  SampleBasedTimer,
  SettingsBuilder,
} from 'libs/exo-session-manager/core';
import { ConnectedChannelToMuscle } from 'types';

import { EMG_WINDOW_WIDTH } from 'components/timeline-charts';

import { CalibrationFlowStatesTypedData } from '../common/CalibrationFlow';
import { EmgSegmentAnalyzer, isExerciseSegmentAnalyzable } from '../common/EMGSegmentAnalyzer';
import EMGSignal from '../common/EMGSignal';
import { Recordings, SignalRecorderController } from '../common/SignalRecorderController';
import { TriggerableGroup } from '../common/TriggerableGroup';
import { DeviceType } from '../global/DeviceManager';
import { EMGExerciseDefinition, GeneratedEMGExerciseDefinition } from '../types/GeneratedExerciseDefinition';

import { Exercise, ExerciseFeature, ExericseInterruptType } from './Exercise';
import { FinishedReason } from './FinishedReason';

const cache = new Map();

/** Memoization function that caches result based on the first argument */
export function memoizeWithEmgExercise<T, R>(func: (firstArg: T, ...additionalArgs: any[]) => R) {
  return (firstArg: T, ...additionalArgs: any[]): R => {
    if (cache.has(firstArg)) {
      return cache.get(firstArg)!;
    }

    const result = func(firstArg, ...additionalArgs);
    cache.set(firstArg, result);

    return result;
  };
}

export function calculateStepTime(step: EMGProgramStep) {
  return step.initialRelaxation + step.repetitions * (step.workTime + step.restTime);
}

export function getPlannedExerciseTime(steps: EMGProgram['steps']) {
  return steps.reduce((acc, curr) => acc + calculateStepTime(curr), 0);
}

export function generateGuideline(steps: EMGProgram['steps']) {
  const guideline: EMGArrayElement[] = [];
  let timeOffset = 0;
  let countRepetition = 0;
  for (const step of steps) {
    timeOffset += step.initialRelaxation;
    for (let repetition = 0; repetition < step.repetitions; repetition++) {
      guideline.push({
        repetition: countRepetition,
        time: timeOffset,
        threshold: step.threshold,
        width: step.workTime,
      });
      timeOffset += step.workTime + step.restTime;
      countRepetition++;
    }
  }

  return guideline;
}

export class EMGExercise implements Exercise {
  static readonly logger = Logger.getInstance('EMGExercise');
  readonly prepared = signal(false, 'EMGExercise.prepared');
  readonly finished = signal(false, 'EMGExercise.finished');
  readonly interrupt = signal<ExericseInterruptType>(null, 'EMGExercise.interrupt');

  private _programData: EMGProgramData;
  private _exerciseData = {
    totalDuration: signal(0, 'EMGExercise.totalDuration'),
    totalRepetition: signal(0, 'EMGExercise.totalRepetition'),
  };
  private _calibrationData: DeepReadonly<CalibrationFlowStatesTypedData> | null = null;
  private _recordings: Recordings = {};
  private _recorderController: SignalRecorderController | null = null;
  private _emgSignal: EMGSignal | null = null;
  readonly triggerableGroup = new TriggerableGroup();
  private _timeInterval: NodeJS.Timeout | null = null;
  private _emgTimer = new SampleBasedTimer(this.session);
  private _resettableEmgTimer = new SampleBasedTimer(this.session);
  private _timers = new TimerGroup([this._emgTimer, this._resettableEmgTimer]);
  private _chartDataSource: EMGRealtimeChartDataSource | null = null;
  private _effectCleanups: (() => void)[] = [];
  private _settingsBuilder: SettingsBuilder;
  emgSegmentAnalyzer: EmgSegmentAnalyzer | null = null;

  editableDefinition: EMGExerciseDefinition;

  constructor(
    readonly definition: GeneratedEMGExerciseDefinition,
    readonly exerciseName: string | null,
    readonly session: ExoSession,
    readonly deviceType: DeviceType,
  ) {
    this._settingsBuilder = new SettingsBuilder(definition);
    this._programData = {
      active: signal(false, 'EMGExercise._programData.active'),
      started: signal(false, 'EMGExercise._programData.started'),
      running: signal(false, 'EMGExercise._programData.running'),
      finishedReason: signal('exerciseFinished', 'EMGExercise._programData.finishedReason'),
      currentDuration: signal(0, 'EMGExercise._programData.currentDuration'),
      currentRepetition: signal(0, 'EMGExercise._programData.currentRepetition'),
      guideline: signal([], 'EMGExercise.totalRepetition'),
      steps: signal([], 'EMGExercise._programData.steps'),
      isEmgProgramReset: signal(false, 'EMGExercise._programData.isEmgProgramReset'),
    };
    this.editableDefinition = structuredClone(definition) as EMGExerciseDefinition;
    this.updateExerciseDataByDefinition();
  }

  get programData() {
    return this._programData;
  }

  get emgSignal() {
    return this._emgSignal;
  }

  get recordings() {
    return this._recordings;
  }

  get settings() {
    return this._settingsBuilder;
  }

  get exerciseData() {
    return this._exerciseData;
  }
  get connectedEMGChannels() {
    if (!this._calibrationData) {
      throw new Error('This exercise was never prepared');
    }
    const connectedChannelsToMuscles = this._calibrationData['connect-electrodes']?.connectedChannelsToMuscles as
      | ConnectedChannelToMuscle[]
      | undefined;

    return connectedChannelsToMuscles ? connectedChannelsToMuscles.map(ch => ch.channelIndex) : [];
  }

  get timeSource() {
    return this._emgTimer;
  }

  get chartDataSource() {
    return this._chartDataSource;
  }

  get primaryChannelMVC() {
    // TODO: implement primary channel logic
    const primaryChannel = this.connectedEMGChannels[0];
    const mvcRecord = (this._calibrationData?.['emg-calibration']?.mvc as Record<number, number>) ?? {};

    return mvcRecord[primaryChannel] ?? 0;
  }

  private updateExerciseDataByDefinition() {
    this._exerciseData.totalDuration.value = getPlannedExerciseTime(this.editableDefinition.emg.program.steps);
    this._exerciseData.totalRepetition.value = this.editableDefinition.emg.program.steps.reduce(
      (acc, curr) => acc + curr.repetitions,
      0,
    );
    this._programData.guideline.value = generateGuideline(this.editableDefinition.emg.program.steps);
    this._programData.steps.value = this.editableDefinition.emg.program.steps;
  }

  private initializeInterval() {
    if (this._timeInterval !== null) {
      return;
    }

    this._timeInterval = setInterval(this.onTimeChange.bind(this), Timers.EXERCISE_DATA_REFRESH_INTERVAL);
  }

  private registerEffects() {
    this._effectCleanups.push(
      effect(() => {
        const currentDuration = this._programData.currentDuration.value;
        this.determineCurrentRepetition(currentDuration);
      }),
    );

    this._effectCleanups.push(
      effect(() => {
        const currentDuration = this._programData.currentDuration.value;
        if (
          this._exerciseData.totalDuration.peek() &&
          currentDuration >= this._exerciseData.totalDuration.peek() &&
          this._programData.active.peek()
        ) {
          this.end();
        }
      }),
    );

    //TODO: here we can add more effects, ex. observe when program steps are changed and update definition rather then creating SettingsBuilder
  }

  private determineCurrentRepetition(currentDuration: number) {
    if (currentDuration < this.exerciseData.totalDuration.peek()) {
      const findRepetition =
        this._programData.guideline.peek().find(emgArrayElement => currentDuration <= emgArrayElement.time)
          ?.repetition ?? 0;
      this._programData.currentRepetition.value = findRepetition;
    } else {
      this._programData.currentRepetition.value = this._exerciseData.totalRepetition.peek();
    }
  }

  init() {}

  private onTimeChange() {
    this._programData.currentDuration.value = this._emgTimer.duration;
  }

  prepare(calibrationData: DeepReadonly<CalibrationFlowStatesTypedData>): void {
    if (this._programData.active.peek()) {
      EMGExercise.logger.debug('start', 'Program is already started');
      return;
    }
    this._calibrationData = calibrationData;

    this.editableDefinition.emg.program.steps = (calibrationData['emg-steps']?.steps ??
      this.editableDefinition.emg.program.steps) as EMGProgramStep[];
    this.updateExerciseDataByDefinition();
    this._settingsBuilder = new SettingsBuilder(
      (calibrationData['basing-settings']?.definition as GeneratedExerciseDefinition | undefined) ??
        this.editableDefinition,
    );

    const recordables: Recordable<'single' | 'multi'>[] = [];

    if (!this._emgSignal) {
      this._emgSignal = EMGSignal.createFromCalibrationFlow(this.session, calibrationData, [this.triggerableGroup]);
      recordables.push(...this._emgSignal.getRecordables());
    }

    if (recordables.length > 0) {
      this._recorderController = new SignalRecorderController(recordables, this.connectedEMGChannels, this._emgTimer);
    }
    this._programData.active.value = true;
    this.finished.value = false;
    this._chartDataSource = new EMGRealtimeChartDataSource(
      this.session,
      this._resettableEmgTimer,
      this._emgSignal.channelMask,
      EMG_WINDOW_WIDTH,
    );

    this._emgTimer.setChannelMask(this._emgSignal.channelMask);
    this._resettableEmgTimer.setChannelMask(this._emgSignal.channelMask);
    this.registerEffects();
    this._settingsBuilder.updateDefinition(this.editableDefinition);
    if (this.exerciseName && isExerciseSegmentAnalyzable(this.exerciseName)) {
      this.emgSegmentAnalyzer = new EmgSegmentAnalyzer(
        this.exerciseName,
        this.connectedEMGChannels,
        this._recorderController,
        this._emgTimer,
        this._programData.isEmgProgramReset,
      );
      this.emgSegmentAnalyzer.onSegmentStart = _idx => {
        this._chartDataSource?.reset();
        this._chartDataSource?.initializeData();
      };
    }
    this.prepared.value = true;
  }

  setProgram(): void {}

  supports(feature: ExerciseFeature): boolean {
    switch (feature) {
      case 'cable':
        return true;
      default:
        return false;
    }
  }

  play(): void {
    if (this.finished.peek()) {
      EMGExercise.logger.warn('play', 'Cannot play program that was finished');
      return;
    }
    if (!this._programData.active.peek()) {
      EMGExercise.logger.debug('play', 'Cannot play program that is ended or not yet started');
      return;
    }

    if (this._programData.running.peek()) {
      EMGExercise.logger.debug('play', 'Program is already running');
      return;
    }

    this._emgSignal?.enable(this._emgSignal.channels);

    if (this._recorderController?.started) {
      this._recorderController?.resume();
    } else {
      this._recorderController?.start();
    }

    if (!exerciseActionTracker.timePoints.exercise.start) {
      exerciseActionTracker.timePoints.exercise.start = new Date();
    }
    exerciseActionTracker.add('activity', 'play');

    this.initializeInterval();
    if (this.exerciseName && isExerciseSegmentAnalyzable(this.exerciseName)) {
      this._resettableEmgTimer.idle();
      this.emgSegmentAnalyzer?.start();
    }
    this._timers.play();
    this._programData.isEmgProgramReset.value = false;
    this._programData.started.value = true;
    this._programData.running.value = true;
  }

  pause(options?: { endPause?: boolean }): void {
    if (!this._programData.active.peek()) {
      EMGExercise.logger.warn('pause', 'Cannot pause program that is ended or not yet started');
      return;
    }
    if (!this._programData.running.peek()) {
      EMGExercise.logger.debug('play', 'Program is already stopped');
      return;
    }

    this._emgSignal?.disable();
    this._recorderController?.pause();

    if (!options?.endPause) {
      exerciseActionTracker.add('activity', 'pause');
    }
    this._programData.running.value = false;
    this._timers.pause();
    this.emgSegmentAnalyzer?.end();
  }

  end(reason: FinishedReason = 'exerciseFinished'): void {
    this.pause({ endPause: true });

    if (!this._programData.active.peek()) {
      EMGExercise.logger.debug('end', 'Cannot end program that is not started');
      return;
    }
    if (this.finished.peek()) {
      EMGExercise.logger.debug('end', 'Program is already finished');
      return;
    }

    if (this._recorderController) {
      this._recordings = this._recorderController.stop();
    }
    this._timers.pause();
    this._programData.finishedReason.value = reason;
    this._programData.running.value = false;
    this._programData.active.value = false;
    this.finished.value = true;
  }

  destroy(): void {
    this.end();
    this._emgSignal?.dispose();
    this._chartDataSource?.dispose();
    this._recorderController = null;
    if (this._timeInterval) {
      clearInterval(this._timeInterval);
    }
    this._effectCleanups.forEach(cleanup => cleanup?.());
    cache.clear();
  }

  clearInterrupt(): void {}
}
