import { Box, Center, HStack, Image, ModalBody, ModalContent, VStack } from '@chakra-ui/react';
import firstInstructionImg from 'assets/images/emg-instructions/emg_instruction_1.svg';
import secondInstructionImg from 'assets/images/emg-instructions/emg_instruction_2.svg';
import thirdInstructionImg from 'assets/images/emg-instructions/emg_instruction_3.svg';

import { MainButton } from 'components/buttons/MainButton';
import { MainHeading } from 'components/texts/MainHeading';
import { TranslateText } from 'components/texts/TranslateText';

import { ModalProps } from './Modal';

interface EmgSegmentsInstructionModalProps extends ModalProps {
  heading?: string;
  description?: string;
  description2?: string;
  description3?: string;
  markAsSeen: () => void;
}

export const EmgSegmentsInstructionModal = ({
  heading,
  description,
  description2,
  description3,
  close,
  markAsSeen,
}: EmgSegmentsInstructionModalProps) => {
  return (
    <ModalContent minW={{ base: '3xl', '2xl': '6xl' }} minH={{ base: 'xl', '2xl': '3xl' }} borderRadius="rLg" p="2">
      <ModalBody display="flex" w="full" h="full" gap="2" justifyContent="space-around" flexDirection="column">
        <Center>
          <MainHeading variant="subheading36" text={heading} />
        </Center>
        <HStack textAlign="center" mt="4" w="full" h="xs">
          <VStack w="full" h="full" justify="start" align="center">
            <Image
              src={firstInstructionImg}
              maxH={{ base: '3xs', '2xl': 'sm' }}
              maxW={{ base: '3xs', '2xl': 'sm' }}
              mt="4"
            />
            <Box h="8" w="8" borderRadius="full" backgroundColor="egzotechPrimaryColor" color="white" p="1">
              1
            </Box>
            <TranslateText variant="openSans24" maxW="64" maxH="64" text={description} mt="2" />
          </VStack>
          <VStack w="full" h="full" justify="start" align="center">
            <Image
              src={secondInstructionImg}
              maxH={{ base: '3xs', '2xl': 'sm' }}
              maxW={{ base: '3xs', '2xl': 'sm' }}
              mt="4"
            />
            <Box h="8" w="8" borderRadius="full" backgroundColor="egzotechPrimaryColor" color="white" p="1">
              2
            </Box>
            <TranslateText variant="openSans24" maxW="64" maxH="64" text={description2} mt="2" />
          </VStack>
          <VStack w="full" h="full" justify="start" align="center">
            <Image
              src={thirdInstructionImg}
              maxH={{ base: '3xs', '2xl': 'sm' }}
              maxW={{ base: '3xs', '2xl': 'sm' }}
              mt="4"
            />
            <Box h="8" w="8" borderRadius="full" backgroundColor="egzotechPrimaryColor" color="white" p="1">
              3
            </Box>
            <TranslateText variant="openSans24" maxW="64" maxH="64" text={description3} mt="2" />
          </VStack>
        </HStack>
        <HStack w="full" justifyContent="space-evenly" mt="2">
          <MainButton
            text="exercise.warningScreen.confirm"
            variant="mdPrimaryButton"
            onClick={() => {
              markAsSeen();
              close();
            }}
            minW="32"
          />
        </HStack>
      </ModalBody>
    </ModalContent>
  );
};
