import { ExoSession } from '@egzotech/exo-session';
import { ExoEMGFeature } from '@egzotech/exo-session/features/emg';
import { RealtimeChartDataSource, StaticChartDataSource } from 'libs/chart-datasources/ChartDataSource';
import { ChannelIndex, ChannelMap, TimeLinePoint } from 'libs/chart-datasources/types';
import { SampleBasedTimer } from 'libs/exo-session-manager/core';

/**
 * ElectrostimChartDataSource is responsible for:
 *  - preparing live data from Echart based on events from ExoEMGFeature
 *
 *
 * Output data format is suited for EChart library
 */
export class EMGRealtimeChartDataSource extends RealtimeChartDataSource {
  private _interval: NodeJS.Timeout | null = null;
  private _emgFeature: ExoEMGFeature;

  get timeSource() {
    return this._timeSource;
  }

  constructor(
    private _session: ExoSession,
    private _timeSource: SampleBasedTimer,
    private _channels: number[],
    private _windowWidth: number,
  ) {
    super();
    this._emgFeature = this._session.activate(ExoEMGFeature);
    this._emgFeature.enable(_channels);
    this._emgFeature.onEmg = (_data, _timeDelta) => {
      for (const [channelIndex, sample] of Object.entries(_data)) {
        const dataSource = this.timelines.get(Number(channelIndex) as ChannelIndex);
        dataSource.push([this._timeSource.getTimestampForChannel(Number(channelIndex)), sample * 1000 * 1000]);

        // remove not visible data from the dataset
        const lastTimeStamp = dataSource.at(-1)?.[0] ?? 0;
        let index = 0;
        while (index < dataSource.length && dataSource[index][0] < lastTimeStamp - this._windowWidth / 2) {
          dataSource.splice(index++, 1);
        }
      }
    };
    this.initializeData();
  }

  initializeData() {
    for (const channelIndex of this._channels) {
      const array: TimeLinePoint[] = [];
      (array as any)[Symbol.toStringTag] = 'Function';
      this.timelines.set(channelIndex as ChannelIndex, array);
    }
  }

  setTimelines(timelines: ChannelMap<TimeLinePoint[]>) {
    this._timelinesSignal.value = timelines;
  }

  dispose() {
    this._emgFeature.dispose();
    if (this._interval !== null) {
      clearInterval(this._interval);
      this._interval = null;
    }
  }
}

export class EMGStaticChartDataSource extends StaticChartDataSource {
  constructor(
    public readonly index: number,
    public readonly _recording: ({
      samples: Float32Array;
      timePoints: Uint32Array;
    } | null)[],
    public readonly _channels: number[],
  ) {
    super();
    this.setDataSource(_recording);
  }

  setDataSource(
    recording: ({
      samples: Float32Array;
      timePoints: Uint32Array;
    } | null)[],
  ) {
    for (let i = 0; i < this._channels.length; i++) {
      const timeLinePoints: TimeLinePoint[] = [];
      (timeLinePoints as any)[Symbol.toStringTag] = 'Function';
      const rec = recording[i];
      if (rec) {
        for (let index = 0; index < rec.samples.length; index++) {
          const sample = rec.samples[index];
          const timePoint = rec.timePoints[index];
          timeLinePoints.push([timePoint, sample]);
        }
      }
      this.timelines.set(this._channels[i] as ChannelIndex, timeLinePoints);
    }
  }
}
