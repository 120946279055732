import { CalibrationFlowStateIdentifier } from '../common/CalibrationFlow';

import { CalibrationFlowDefinitionStates } from './types';

export function prepareChannelsAndConnectElectrodesFlow(
  prev: CalibrationFlowStateIdentifier | null,
  next: CalibrationFlowStateIdentifier | null,
  stepIndex: number,
): CalibrationFlowDefinitionStates {
  return {
    'channel-muscle-selector': {
      prev,
      next: 'channel-role-selector',
      stepIndex,
      disableAlerts: ['disconnectedElectrode'],
    },
    'channel-role-selector': {
      prev: 'channel-muscle-selector',
      next: 'connect-electrodes',
      stepIndex: stepIndex + 1,
      disableAlerts: ['disconnectedElectrode'],
      condition: 'skipRoleScreen',
    },
    'connect-electrodes': {
      prev: 'channel-role-selector',
      next,
      stepIndex: stepIndex + 2,
      disableAlerts: ['disconnectedElectrode'],
    },
  } as const;
}
