import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useBreakpointValue } from '@chakra-ui/react';
import { useSignals } from '@preact/signals-react/runtime';
import colors, { Channel } from 'config/theme/colors';
import * as echarts from 'echarts';
import { MarkArea2DDataItemOption } from 'echarts/types/src/component/marker/MarkAreaModel';
import { Signal, useSignal, useSignalEffect } from 'helpers/signal';
import { extractLastNumber } from 'helpers/string';
import { toMicroVolts, toPercentage } from 'helpers/units';
import { ChannelMap, TimeLinePoint } from 'libs/chart-datasources';
import { useEMGExerciseInstance } from 'libs/exo-session-manager/react';

import { DEFAULT_WINDOW_WIDTH, TimeLineChartOptions } from './useRealtimeTimelineChart';

export const markAreaUI = {
  selected: {
    color: colors.egzotechPrimaryColor,
    opacity: 0.2,
  },
  default: {
    color: colors.backgroundColor,
    opacity: {
      even: 0,
      odd: 0.4,
    },
  },
} as const;

const R = 10;

export function getMarkAreaColor(index: number | undefined, selected: boolean) {
  return typeof index === 'number' && selected ? markAreaUI.selected.color : markAreaUI.default.color;
}

export function getMarkAreaOpacity(index: number | undefined, selected: boolean, isEven: boolean) {
  return typeof index === 'number' && selected
    ? markAreaUI.selected.opacity
    : isEven
    ? markAreaUI.default.opacity.even
    : markAreaUI.default.opacity.odd;
}

export const useStaticTimelineChart = (
  arrTimelines: ChannelMap<TimeLinePoint[]>[],
  xMax: number,
  options: TimeLineChartOptions,
  selectedSegment?: Signal<number | undefined>,
) => {
  useSignals();
  const { windowWidth = DEFAULT_WINDOW_WIDTH, yAxisUnit = '%', yAxisMax: yMax = 100, hideChannels = [] } = options;
  const emgExercise = useEMGExerciseInstance();
  const chartRef = useRef<HTMLDivElement>(null);
  const echartInstance = useRef<echarts.ECharts | null>(null);
  const echartOptions = useRef<echarts.EChartsOption | null>(null);
  const fontSize = useBreakpointValue({
    base: 16,
    '2xl': 20,
  });
  const internalSelectedSegment = useSignal(selectedSegment?.value, 'useStaticTimelineChart.selectedSegment');

  const segmentEndXPoints = useMemo(
    () =>
      arrTimelines
        .map(timeLinePoint => {
          const firstAvailableChannel = Object.values(timeLinePoint.channels).find(channel => channel?.length > 0);
          return firstAvailableChannel?.at(-1)?.[0];
        })
        .filter(point => typeof point === 'number') as number[],
    [arrTimelines],
  );

  const removeSelectedColors = useCallback(() => {
    if (!echartOptions.current?.series) return;

    echartOptions.current.series = (echartOptions.current.series as echarts.LineSeriesOption[]).map(serie => {
      const serieIndex = extractLastNumber(String(serie.name));
      return {
        ...serie,
        markArea: serie.markArea
          ? {
              ...serie.markArea,
              data: (serie.markArea?.data as MarkArea2DDataItemOption[]).map(items => {
                const color = getMarkAreaColor(undefined, false);
                const opacity = getMarkAreaOpacity(
                  undefined,
                  false,
                  typeof serieIndex === 'number' ? serieIndex % 2 === 0 : false,
                );
                return items.map(item => ({
                  ...item,
                  itemStyle: {
                    ...item.itemStyle,
                    color,
                    opacity,
                  },
                }));
              }),
            }
          : undefined,
      };
    }) as echarts.LineSeriesOption[];

    echartInstance.current?.setOption(echartOptions.current);
  }, []);

  const updateSegmentColors = useCallback(
    (selectedIndex?: number) => {
      if (!echartOptions.current?.series) return;

      echartOptions.current.graphic = (
        echartOptions.current.graphic as echarts.GraphicComponentOption[] | undefined
      )?.map(option => ({
        ...option,
        elements:
          'elements' in option
            ? option.elements?.map(el => ({
                ...el,
                children: [
                  {
                    style: {
                      stroke: markAreaUI.selected.color,
                      lineWidth: 2,
                      fill: 'transparent',
                    },
                  },
                  {
                    style: {
                      fill: markAreaUI.selected.color,
                      opacity: typeof selectedIndex === 'number' && el.id === `circles_${selectedIndex}` ? 100 : 0,
                    },
                  },
                ],
              }))
            : [],
      }));

      if (typeof selectedIndex !== 'number') {
        removeSelectedColors();
      } else {
        echartOptions.current.series = (echartOptions.current.series as echarts.LineSeriesOption[]).map(serie => {
          const serieIndex = extractLastNumber(String(serie.name));
          return {
            ...serie,
            markArea: serie.markArea
              ? {
                  ...serie.markArea,
                  data: (serie.markArea?.data as MarkArea2DDataItemOption[]).map(items => {
                    const color = getMarkAreaColor(selectedIndex, serieIndex === selectedIndex);
                    const opacity = getMarkAreaOpacity(
                      selectedIndex,
                      serieIndex === selectedIndex,
                      typeof serieIndex === 'number' ? serieIndex % 2 === 0 : false,
                    );
                    return items.map(item => ({
                      ...item,
                      itemStyle: {
                        ...item.itemStyle,
                        color,
                        opacity,
                      },
                    }));
                  }),
                }
              : undefined,
          };
        }) as echarts.LineSeriesOption[];

        echartInstance.current?.setOption(echartOptions.current);
      }
    },
    [removeSelectedColors],
  );

  const calculateGraphicPosition = useCallback(
    (data: number[][]): [number, number] => {
      // when position cannot be calculated we return [NaN, NaN] so echart will not render this graphic
      if (!echartOptions.current) return [NaN, NaN];
      if (!echartInstance.current) return [NaN, NaN];

      const gridOption = echartOptions.current.grid as echarts.GridComponentOption;
      const echartDom = echartInstance.current.getDom();

      const chartWidth = echartDom.clientWidth;

      const gridLeft = (parseFloat(String(gridOption.left)) / 100) * chartWidth;
      const gridRight = chartWidth - (parseFloat(String(gridOption.right ?? 0)) / 100) * chartWidth;

      const middleIndex = Math.floor(data.length / 2);
      const middleX = data[middleIndex][0];
      const topY = yMax * 0.8;

      const pixelPos = echartInstance.current.convertToPixel({ xAxisIndex: 0, yAxisIndex: 0 }, [middleX, topY]);

      if (!pixelPos || pixelPos.some(isNaN)) {
        return [NaN, NaN];
      }

      if (pixelPos[0] > gridLeft * 2 + R * 2 && pixelPos[0] < gridRight - R * 2) {
        return pixelPos as [number, number];
      }

      return [NaN, NaN];
    },
    [yMax],
  );

  const createGraphicItem = useCallback(
    (segmentIndex: number, pos: [number, number]) => {
      if (!echartOptions.current) return {};
      if (!echartInstance.current) return {};

      return {
        id: `circles_${segmentIndex}`,
        type: 'group',
        children: [
          {
            type: 'circle',
            id: `outerCircle_${segmentIndex}`,
            z: 5,
            shape: {
              cx: pos[0],
              cy: pos[1],
              r: R,
            },
            style: {
              stroke: markAreaUI.selected.color,
              lineWidth: 2,
              fill: 'transparent',
            },
          },
          {
            type: 'circle',
            id: `innerCircle_${segmentIndex}`,
            shape: {
              cx: pos[0],
              cy: pos[1],
              r: R / 2,
            },
            style: {
              fill: markAreaUI.selected.color,
              opacity: internalSelectedSegment.value === segmentIndex ? 100 : 0,
            },
          },
        ],
      };
    },
    [internalSelectedSegment.value],
  );

  const getDataSeries = useCallback(
    (timelines: ChannelMap<TimeLinePoint[]>, segmentIndex: number) => {
      if (!echartInstance.current || !echartOptions.current) {
        return {};
      }
      const selected = internalSelectedSegment.value;
      const markAreaData = segmentEndXPoints.map((endX, index, arr) => {
        const color = getMarkAreaColor(selected, selected === index);
        const opacity = getMarkAreaOpacity(selected, selected === index, index % 2 === 0);
        const xAxis = index === 0 ? 0 : arr[index - 1] / 1000;
        return [
          {
            xAxis,
            itemStyle: {
              color,
              opacity,
            },
          },
          {
            xAxis: endX / 1000,
            itemStyle: {
              color,
              opacity,
            },
          },
        ];
      });

      const graphic: echarts.EChartsOption['graphic'] = [];

      const series = timelines
        .entries()
        .filter(v => !hideChannels.includes(v[0]))
        .map(([channelIndex, timeLinePoints], i) => {
          const data = timeLinePoints
            // we start with additional 0.1 sec of data to made chart correctly display continuity of segments
            .map((v, i) => (i === 0 ? [v[0] - 100, v[1]] : v))
            .map(v => [v[0] / 1000, toMicroVolts(v[1], 'V')]);
          const pos = calculateGraphicPosition(data);

          if (i === 0 && pos) {
            graphic.push(createGraphicItem(segmentIndex, pos));
          }

          return {
            name: `line${channelIndex}_${segmentIndex}`,
            type: 'line' as const,
            z: 4,
            showSymbol: false,
            data,
            markArea:
              i === 0
                ? {
                    animation: false,
                    emphasis: {
                      itemStyle: {
                        color: markAreaUI.selected.color,
                        opacity: markAreaUI.selected.opacity,
                      },
                    },
                    data: [markAreaData[segmentIndex]],
                  }
                : undefined,
            animation: false,
            showAllSymbol: false,
            legendHoverLink: false,
            sampling: 'none',
            triggerLineEvent: true,
            progressive: false,
            smooth: false,
            color: colors.channel[(channelIndex + 1) as Channel],
          } as echarts.SeriesOption;
        });

      return { series, graphic };
    },
    [internalSelectedSegment.value, segmentEndXPoints, hideChannels, calculateGraphicPosition, createGraphicItem],
  );

  const initializeChart = useCallback(() => {
    if (!echartInstance.current) {
      return;
    }

    const max = Math.max(xMax, windowWidth);

    echartOptions.current = {
      [Symbol.toStringTag]: 'Function',
      xAxis: {
        type: 'value',
        name: '',
        nameGap: 30,
        nameLocation: 'middle',
        nameTextStyle: {
          fontSize,
          fontWeight: 'bold',
          color: colors.gray[400],
        },
        min: 0,
        max,
        splitNumber: windowWidth,
        animation: false,
        splitLine: {
          show: true,
          showMaxLine: false,
          showMinLine: false,
          lineStyle: {
            color: colors.chartAxisColor,
          },
        },
        axisLine: {
          show: true,
          lineStyle: {
            color: colors.chartAxisColor,
          },
        },
        axisLabel: {
          animation: false,
          formatter: (_time: number) => {
            const time = Math.floor(_time);
            const seconds = time % 60;
            const minutes = (time - seconds) / 60;
            return `${minutes}:${seconds.toString().padStart(2, '0')}`;
          },
          showMinLabel: false,
          showMaxLabel: false,
          color: colors.gray[400],
          fontSize,
        },
      },
      yAxis: {
        type: 'value',
        nameGap: 30,
        nameLocation: 'middle',
        nameTextStyle: {
          fontSize,
          fontWeight: 'bold',
          color: colors.gray[400],
        },
        axisLabel: {
          animation: false,
          formatter: (value: number) => {
            return `${value}` + (yAxisUnit ? ` ${yAxisUnit}` : '');
          },
          showMaxLabel: false,
          color: colors.gray[400],
          fontSize,
        },
        axisLine: {
          show: true,
          lineStyle: {
            color: colors.chartAxisColor,
          },
        },
        splitLine: {
          show: true,
          showMaxLine: false,
          showMinLine: false,
          lineStyle: {
            color: colors.chartAxisColor,
          },
        },
        splitNumber: 3,
        animation: false,
        min: 0,
        max: yMax,
      },
      grid: {
        left: '5%',
        right: '5%',
        top: '5%',
        bottom: '10%',
        containLabel: true,
      },
      animation: false,
      tooltip: { show: false },
      toolbox: { show: false },
      title: { show: false },
      dataZoom: [],
    } as echarts.EChartsOption;
    echartInstance.current.setOption<echarts.EChartsOption>(echartOptions.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fontSize, windowWidth, yAxisUnit, yMax]);

  const updateChartData = useCallback(() => {
    if (!echartOptions.current) {
      return;
    }
    const chartData = arrTimelines.map((timelines, index) => getDataSeries(timelines, index));
    echartOptions.current.series = chartData.flatMap(data => data.series ?? []);
    echartOptions.current.graphic = chartData.flatMap(data => data.graphic ?? []);
    echartInstance.current?.setOption<echarts.EChartsOption>(echartOptions.current);
  }, [getDataSeries, arrTimelines]);

  const updateChartRange = useCallback(() => {
    if (xMax <= windowWidth) {
      return;
    }
    if (!echartOptions.current) {
      return;
    }
    const min = xMax - windowWidth;
    const start = toPercentage(min, xMax);

    echartOptions.current.xAxis = {
      ...echartOptions.current.xAxis,
      max: xMax,
      splitNumber: windowWidth - 1,
    } as echarts.XAXisComponentOption;

    echartOptions.current.dataZoom = [
      {
        type: 'slider',
        show: false,
        start,
        end: 100, // percentage
      },
    ];

    echartInstance.current?.setOption<echarts.EChartsOption>(echartOptions.current);
  }, [windowWidth, xMax]);

  const recalculateGraphicPosition = useCallback(() => {
    if (!echartOptions.current) {
      return;
    }

    const arrData = (echartOptions.current.series as echarts.SeriesOption[])
      .filter(option => !!option.markArea)
      .map(option => option.data) as number[][][];

    const graphics = arrData.map(data => calculateGraphicPosition(data));

    echartOptions.current.graphic = graphics.map((pos, i) => createGraphicItem(i, pos));

    echartInstance.current?.setOption<echarts.EChartsOption>(echartOptions.current);
  }, [calculateGraphicPosition, createGraphicItem]);

  const updateGraphicColors = useCallback((opacity: number) => {
    if (!echartOptions.current) return;

    echartOptions.current.graphic = (echartOptions.current.graphic as echarts.GraphicComponentOption[]).map(option => ({
      ...option,
      elements:
        'elements' in option
          ? option.elements?.map(el => ({
              ...el,
              children: [
                {
                  style: { opacity },
                },
                {
                  style: { opacity },
                },
              ],
            }))
          : [],
    })) as echarts.GraphicComponentOption;

    echartInstance.current?.setOption(echartOptions.current);
  }, []);

  useEffect(() => {
    const resizeHandler = () => {
      echartInstance.current?.resize();
      updateChartData();
    };
    window.addEventListener('resize', resizeHandler);
    return () => {
      window.removeEventListener('resize', resizeHandler);
    };
    // add event listeners only once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!chartRef.current) return;

    echartInstance.current = echarts.init(chartRef.current, null, {
      renderer: 'canvas',
      devicePixelRatio: 1,
      useDirtyRect: true,
    });
    echartInstance.current.on('click', function (params) {
      if (emgExercise?.programData.running.value) return;
      let clickedIndex: number | undefined;
      if (params.componentType === 'graphic' && params.event) {
        clickedIndex = extractLastNumber(String(params.event.target.parent.id));
      } else {
        clickedIndex = params.seriesName ? extractLastNumber(String(params.seriesName)) : undefined;
      }
      internalSelectedSegment.value = clickedIndex;
      if (selectedSegment) {
        selectedSegment.value = clickedIndex;
      }
      recalculateGraphicPosition();
      updateSegmentColors(clickedIndex);
    });

    initializeChart();
    updateChartData();

    if (arrTimelines.length > 0) {
      updateChartRange();
      recalculateGraphicPosition();
      updateSegmentColors(internalSelectedSegment.value);
    }

    return () => {
      echartInstance.current?.dispose();
    };
    // do not subscribe to update callbacks but execute them when chart instance is changed
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initializeChart, hideChannels]);

  useEffect(() => {
    if (arrTimelines.length > 0) {
      internalSelectedSegment.value = arrTimelines.length - 1;
      if (selectedSegment) {
        selectedSegment.value = undefined;
      }
      updateChartData();
      updateChartRange();
      recalculateGraphicPosition();
    }

    // this effect update chart on new timelines, but should not fire when we change other dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [arrTimelines.length]);

  useSignalEffect(() => {
    const running = emgExercise?.programData.running.value;

    if (typeof running !== 'boolean') return;

    if (running) {
      if (selectedSegment) {
        selectedSegment.value = undefined;
      }
      internalSelectedSegment.value = undefined;
    }

    updateGraphicColors(running ? 0 : 100);
    updateSegmentColors(internalSelectedSegment.value);
  });

  return {
    chartRef,
    echartInstance,
    echartOptions,
    recalculateGraphicPosition,
    currentSegment: internalSelectedSegment,
  };
};
