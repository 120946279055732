import { FunctionComponent } from 'react';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ModalProps, ModalType, ModalTypeDefinition } from 'containers/modals/Modal';

/**
 * Modal severity represents what direct user actions should be taken before continuing
 * - none - means that no direct user actions is required, the modal is informational or an form for the user to fill,
 *     this kind of modal can be closed automatically
 * - warninig - means that normal behavior of app will be suspendend (for example training should be paused), but user
 *     can recover from this alert and continue normally, this kind of modal can be closed automatically only if it is
 *     not currently displayed
 * - critical - means that normal behavior of app will be suspendend and user cannot recover and must recover from this situation
 *     this kind of modal can be closed automatically only if it is not currently displayed
 */
export type ModalSeverity = 'critical' | 'warning' | 'none';

export interface ModalState {
  isOpened: boolean;
  severity: ModalSeverity;
  name: string | null;
  queue: SingleModalState[];
}

export interface SingleModalState {
  type: ModalTypeDefinition<ModalProps>;
  id?: string;
  doNotCloseOnOverlayClickOrEsc?: boolean;
  additionalData?: Omit<ModalProps, 'close'>;
}

export interface OpenModalPayload<T extends ModalProps> {
  id?: T['id'];
  type: ModalTypeDefinition<T>;
  doNotCloseOnOverlayClickOrEsc?: boolean;
  additionalData?: Omit<T, 'close'>;
}

const initialState: ModalState = {
  isOpened: false,
  severity: 'none',
  name: null,
  queue: [],
};

const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    closeModal: (state, { payload }: PayloadAction<ModalTypeDefinition<any>>) => {
      const modalIndex = state.queue.findIndex(v => v.type.name === payload.name);

      if (modalIndex < 0) {
        return;
      }

      const modalToRemove = state.queue[modalIndex];

      if (
        modalIndex === state.queue.length - 1 &&
        (modalToRemove?.type.options?.severity === 'warning' || modalToRemove?.type.options?.severity === 'critical')
      ) {
        // We cannot close automatically modals that are currently displayed and have warning or critical severity
        return;
      }

      state.queue = [...state.queue.slice(0, modalIndex), ...state.queue.slice(modalIndex + 1, state.queue.length)];

      if (state.queue.length === 0) {
        state.isOpened = false;
        state.severity = 'none';
        state.name = null;
      } else {
        state.isOpened = true;
        state.severity = state.queue.at(-1)?.type.options?.severity ?? 'none';
        state.name = state.queue.at(-1)?.type.name ?? null;
      }
    },
    /**
     * forceCloseModal should only be used to close modal from user action (like clicking a button) and not any
     * automatic actions (like changes in appliaction state). This action allows only closing currently visible
     * modal.
     */
    forceCloseModal: (state, { payload }: PayloadAction<string>) => {
      const modalIndex = state.queue.findIndex(v => v.type.name === payload);

      if (modalIndex < 0 || modalIndex !== state.queue.length - 1) {
        return;
      }

      state.queue = [...state.queue.slice(0, modalIndex), ...state.queue.slice(modalIndex + 1, state.queue.length)];

      if (state.queue.length === 0) {
        state.isOpened = false;
        state.severity = 'none';
        state.name = null;
      } else {
        state.isOpened = true;
        state.severity = state.queue.at(-1)?.type.options?.severity ?? 'none';
        state.name = state.queue.at(-1)?.type.name ?? null;
      }
    },
    updateModal: (
      state: ModalState,
      {
        payload,
      }: PayloadAction<{
        ids?: string[];
        components?: FunctionComponent<ModalProps>[];
        data: SingleModalState['additionalData'];
      }>,
    ) => {
      const { id, ...rest } = payload.data ?? {};

      if (!payload.ids || !payload.components) {
        state.queue = state.queue.map(v => ({
          ...v,
          id: id ?? v.id,
          additionalData: {
            ...v.additionalData,
            ...rest,
          },
        }));
      }
    },
    openModal: (state: ModalState, { payload }: PayloadAction<SingleModalState>) => {
      const existingModalIndex = state.queue.findIndex(v => v.type.name === payload.type.name);

      if (existingModalIndex >= 0) {
        // When there is an existing modal, we want to update it instead of opening new modal
        const existingModal = state.queue[existingModalIndex];

        state.queue = [
          ...state.queue.slice(0, existingModalIndex),
          {
            ...existingModal,
            id: payload.id,
            additionalData: {
              ...existingModal.additionalData,
              ...payload.additionalData,
            },
          },
          ...state.queue.slice(existingModalIndex + 1, state.queue.length),
        ];
        return;
      }

      const modal: SingleModalState = {
        type: payload.type,
        id: payload.id,
        doNotCloseOnOverlayClickOrEsc: payload.doNotCloseOnOverlayClickOrEsc,
        additionalData: {
          ...payload.additionalData,
        },
      };

      const currentModal = state.queue.at(-1);
      const newQueue = [...state.queue];

      if (
        currentModal?.type.options?.canBeOmitted &&
        (modal.type.options?.priority ?? 0) > (currentModal.type.options.priority ?? 0)
      ) {
        // When current modal has 'canBeOmitted' option set and new modal priority is higher than
        // currently displayed modal priority, we replace current modal with new modal

        newQueue[newQueue.length - 1] = modal;
      } else if (
        currentModal &&
        modal.type.options?.canBeOmitted &&
        (modal.type.options.priority ?? 0) < (currentModal.type.options?.priority ?? 0)
      ) {
        // When new modal has 'canBeOmitted' option set and its priority is lower than currently
        // displayed modal priority, we ignore displaying new modal

        return;
      } else {
        newQueue.push(modal);
      }

      state.queue = newQueue.sort((a, b) => (a.type.options?.priority ?? 0) - (b.type.options?.priority ?? 0));

      state.isOpened = true;
      state.severity = state.queue.at(-1)?.type.options?.severity ?? 'none';
      state.name = state.queue.at(-1)?.type.name ?? null;
    },
  },
});

export const {
  closeModal,
  forceCloseModal,
  openModal: sliceOpenModal,
  updateModal: sliceUpdateModal,
} = modalSlice.actions;

export function openModal<T extends ModalProps>(payload: OpenModalPayload<T>) {
  return sliceOpenModal(payload as Omit<SingleModalState, 'isOpened'>);
}

export function updateModal<T extends ModalProps>(payload: { types: ModalTypeDefinition<T>[]; data: Partial<T> }) {
  return sliceUpdateModal({ ids: payload.types.map(v => v.name), data: payload.data });
}

export function updateAllModals<T extends ModalProps>(payload: {
  components: FunctionComponent<T>[];
  data: Partial<T>;
}): ReturnType<typeof sliceUpdateModal>;
export function updateAllModals(payload: { data: Record<string, unknown> }): ReturnType<typeof sliceUpdateModal>;
export function updateAllModals<T extends ModalProps>(payload: {
  components?: FunctionComponent<T>[];
  data: Partial<T> | Record<string, unknown>;
}) {
  return sliceUpdateModal({ components: payload.components as FunctionComponent<ModalProps>[], data: payload.data });
}

export function openUnexpectedErrorModal(id: string) {
  return openModal({ type: ModalType.UNEXPECTED_ERROR, doNotCloseOnOverlayClickOrEsc: true, id });
}

export default modalSlice.reducer;
