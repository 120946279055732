import { useState } from 'react';
import { Box, Center, Flex, Input, ModalBody, ModalContent, Textarea, VStack } from '@chakra-ui/react';
import { __ } from 'helpers/i18n';

import { MainButton } from 'components/buttons/MainButton';
import { MainHeading } from 'components/texts/MainHeading';
import { TranslateText } from 'components/texts/TranslateText';

import { ModalProps } from './Modal';

interface AddNoteModal extends ModalProps {
  initialName: string;
  initialDescription: string;
  save: (title: string, description: string) => void;
}

export function AddNoteModal({ initialName, initialDescription, save, close }: AddNoteModal) {
  const [title, setTitle] = useState(initialName);
  const [description, setDescription] = useState(initialDescription);

  return (
    <ModalContent maxW="lg" borderRadius="rSm" p="4">
      <ModalBody>
        <VStack w="full" justify="space-between" gap="6" pt="4" flex="1 0">
          <Center>
            <MainHeading variant="subheading36" text="modals.addNote.heading" />
          </Center>
          <VStack w="full" gap="10" px="4">
            <Box w="full">
              <TranslateText text="modals.addNote.title" />
              <Input value={title} onChange={e => setTitle(e.target.value)} placeholder={__('modals.addNote.title')} />
            </Box>
            <Box w="full">
              <TranslateText text="modals.addNote.description" />
              <Textarea
                value={description}
                onChange={e => setDescription(e.target.value)}
                placeholder={__('modals.addNote.description')}
              />
            </Box>
            <Flex w="full" justify="space-between">
              <MainButton
                type="button"
                text="common.cancel"
                variant={{ base: 'smOutlinedPrimaryButton', '2xl': 'mdOutlinedPrimaryButton' }}
                onClick={() => close()}
                minW={32}
              />
              <MainButton
                type="submit"
                text="common.save"
                variant={{ base: 'smPrimaryButton', '2xl': 'mdPrimaryButton' }}
                isDisabled={title.length === 0 && description.length === 0}
                onClick={() => {
                  save(title, description);
                  close();
                }}
                width={32}
              />
            </Flex>
          </VStack>
        </VStack>
      </ModalBody>
    </ModalContent>
  );
}
