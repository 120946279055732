import { DeepReadonly } from '@egzotech/exo-session';
import { PresentationTemplateName } from 'views/+patientId/training/+trainingId/_components/leg-exercise/ExerciseConfigurableProgressBar';
import { GameId } from 'views/+patientId/training/+trainingId/_containers/game/Game';

import { CAMProgramDefinition } from './GeneratedCAMProgramDefinition';
import { CPMProgramDefinition } from './GeneratedCPMProgramDefinition';
import { ElectrostimProgramDefinition } from './GeneratedElectrostimProgramDefinition';
import { EMGProgramDefinition } from './GeneratedEmgProgramDefinition';
import { MotorPlacement, ProgramParameterDefinition } from './GeneratedProgramDefinition';

/**
 * Supported program types
 */
export const exerciseTypes = [
  'cpm',
  'cpm-emg',
  'cpm-ems',
  'cpm-ems-emg',
  'cpm-force',
  'cam-isokinetic',
  'cam-torque',
  'cam-turn-key',
  'emg',
  'emg-pelvic',
  'ems',
  'cam-game-force',
  'cam-game-position',
  'cam-game',
  'ems-emg',
  'emg-game',
] as const;

export type ExerciseType = (typeof exerciseTypes)[number];

export type GeneratedExerciseDefinitionTag =
  | 'cpm'
  | 'ems'
  | 'emg'
  | 'cam'
  | 'force'
  | 'driving-game'
  | 'cosmic-mission'
  | 'trigger'
  | 'pelvic'
  | 'fes'
  | 'exo-run';

export type PresentationTemplate =
  | PresentationTemplateName
  | {
      time: PresentationTemplateName;
      repetitions: PresentationTemplateName;
    };

export interface ExerciseDefinition {
  type: ExerciseType;
  calibrationFlow: string; // TODO: Can be TrainigType if all calibration flow are defined
  tags: GeneratedExerciseDefinitionTag[];
  presentationTemplate: PresentationTemplate;
}

/**
 * Full definition of CPM exercise (Sidra LEG / Meissa OT)
 */
export interface CPMExerciseDefinition extends ExerciseDefinition {
  type: Extract<ExerciseType, 'cpm' | 'cpm-emg' | 'cpm-ems' | 'cpm-ems-emg' | 'cpm-force'>;
  cpm: {
    [type in MotorPlacement]?: CPMProgramDefinition;
  };
  ems?: ElectrostimProgramDefinition;
}
/**
 * Full definition of CAM exercise (Sidra LEG / Meissa OT)
 */
export interface CAMExerciseDefinition extends ExerciseDefinition {
  type: Extract<
    ExerciseType,
    'cam-isokinetic' | 'cam-torque' | 'cam-turn-key' | 'cam-game' | 'cam-game-position' | 'cam-game-force'
  >;
  cam: {
    [type in MotorPlacement]?: CAMProgramDefinition;
  };
  // TODO: electrostim
  // ems?: GeneratedElectrostimProgramDefinition;
}

export type BlockerValue =
  | 'driving-game-blokers-none'
  | 'driving-game-blokers-little'
  | 'driving-game-blokers-normal'
  | 'driving-game-blokers-lot';
export type CarColors = 'blue' | 'gray' | 'green' | 'orange' | 'pink' | 'purple' | 'red' | 'yellow';
export type GameBackgrounds = 'random' | 'city' | 'desert' | 'mountains' | 'winter';
export type GameSteeringModes = 'normalControl' | 'invertControl';
export type GameParameters = {
  speed?: ProgramParameterDefinition<'%'>;
  routeWidth?: ProgramParameterDefinition<'number'>;
  oponent?: ProgramParameterDefinition<'none', 'yes' | 'no'>;
  blockers?: ProgramParameterDefinition<'none', BlockerValue>;
  gameBackground?: ProgramParameterDefinition<'none', GameBackgrounds>;
  carColor?: ProgramParameterDefinition<'none', CarColors>;
  gameSteeringMode?: ProgramParameterDefinition<'none', GameSteeringModes>;
};

export interface GameExerciseDefinition {
  game: {
    id: GameId;
    parameters: {
      phases: GameParameters[];
    };
    program: {
      phases: {
        speed: number;
        routeWidth?: number;
        oponent?: 'yes' | 'no';
        blockers?: BlockerValue;
        gameBackground?: GameBackgrounds;
        carColor?: CarColors;
        gameSteeringMode?: GameSteeringModes;
      }[];
    };
  };
}

export interface CAMGameExerciseDefinition extends Omit<CAMExerciseDefinition, 'type'>, GameExerciseDefinition {
  type: Extract<ExerciseType, 'cam-game' | 'cam-game-force'>;
}

/**
 * Full definition of EMG exercise (Stella BIO)
 */
export interface EMGExerciseDefinition extends ExerciseDefinition {
  type: Extract<ExerciseType, 'emg' | 'emg-pelvic' | 'emg-game'>;
  emg: EMGProgramDefinition;
}
export interface EMGGameExerciseDefinition extends EMGExerciseDefinition, GameExerciseDefinition {
  type: Extract<ExerciseType, 'emg-game'>;
}
/**
 * Full definition of EMS exercise (Stella BIO)
 */
export interface EMSExerciseDefinition extends ExerciseDefinition {
  type: Extract<ExerciseType, 'ems' | 'ems-emg'>;
  ems: ElectrostimProgramDefinition;
}

/**
 * Helper function that determines whether the given exercise definition is of the appropriate type
 * @param definition Exercise definition
 * @param type Exercise type we want to check
 */
export function isSpecificExerciseDefinition(
  definition: ExerciseDefinition,
  type: Extract<ExerciseType, 'cpm' | 'cpm-emg' | 'cpm-ems' | 'cpm-ems-emg' | 'cpm-force'>[],
): definition is CPMExerciseDefinition;
export function isSpecificExerciseDefinition(
  definition: ExerciseDefinition,
  type: Extract<ExerciseType, 'cam-isokinetic' | 'cam-torque' | 'cam-turn-key'>[],
): definition is CAMExerciseDefinition;
export function isSpecificExerciseDefinition(
  definition: ExerciseDefinition,
  type: Extract<ExerciseType, 'emg' | 'emg-pelvic'>[],
): definition is EMGExerciseDefinition;
export function isSpecificExerciseDefinition(
  definition: ExerciseDefinition,
  type: Extract<ExerciseType, 'ems' | 'ems-emg'>[],
): definition is EMSExerciseDefinition;
export function isSpecificExerciseDefinition(definition: ExerciseDefinition, type: ExerciseType[]) {
  return type.includes(definition.type);
}

// Generated types - readonly
export type GeneratedExerciseDefinition = DeepReadonly<ExerciseDefinition>;
export type GeneratedCPMExerciseDefinition = DeepReadonly<CPMExerciseDefinition>;
export type GeneratedCAMExerciseDefinition = DeepReadonly<CAMExerciseDefinition>;
export type GeneratedCAMGameExerciseDefinition = DeepReadonly<CAMGameExerciseDefinition>;
export type GeneratedEMGExerciseDefinition = DeepReadonly<EMGExerciseDefinition>;
export type GeneratedEMGGameExerciseDefinition = DeepReadonly<EMGGameExerciseDefinition>;
export type GeneratedEMSExerciseDefinition = DeepReadonly<EMSExerciseDefinition>;

export type GeneratedCPMLikeExerciseDefinition = GeneratedExerciseDefinition &
  Omit<GeneratedCPMExerciseDefinition, 'type'>;
export type GeneratedCAMLikeExerciseDefinition = GeneratedExerciseDefinition &
  Omit<GeneratedCAMExerciseDefinition, 'type'>;
export type GeneratedEMGLikeExerciseDefinition = GeneratedExerciseDefinition &
  Omit<GeneratedEMGExerciseDefinition, 'type'>;
export type GeneratedElectrostimLikeExerciseDefinition = GeneratedExerciseDefinition &
  Omit<GeneratedEMSExerciseDefinition, 'type'>;
export type GeneratedGameLikeExerciseDefinition = GeneratedExerciseDefinition & DeepReadonly<GameExerciseDefinition>;
export type GeneratedEMSLikeExerciseDefinition = GeneratedExerciseDefinition &
  Omit<GeneratedEMSExerciseDefinition, 'type'>;

/**
 * Helper function that determines whether the given genrated exercise definition is of the appropriate type
 * @param definition Exercise definition
 * @param type Exercise type we want to check
 */
export function isSpecificGeneratedExerciseDefinition(
  definition: GeneratedExerciseDefinition,
  type: Extract<ExerciseType, 'cpm' | 'cpm-emg' | 'cpm-ems' | 'cpm-ems-emg' | 'cpm-force'>[],
): definition is GeneratedCPMExerciseDefinition;
export function isSpecificGeneratedExerciseDefinition(
  definition: GeneratedExerciseDefinition,
  type: Extract<
    ExerciseType,
    'cam-isokinetic' | 'cam-torque' | 'cam-turn-key' | 'cam-game' | 'cam-game-position' | 'cam-game-force'
  >[],
): definition is GeneratedCAMExerciseDefinition;
export function isSpecificGeneratedExerciseDefinition(
  definition: GeneratedExerciseDefinition,
  type: Extract<ExerciseType, 'emg' | 'emg-pelvic' | 'emg-game'>[],
): definition is GeneratedEMGExerciseDefinition;
export function isSpecificGeneratedExerciseDefinition(
  definition: GeneratedExerciseDefinition,
  type: Extract<ExerciseType, 'ems' | 'ems-emg'>[],
): definition is GeneratedEMSExerciseDefinition;
export function isSpecificGeneratedExerciseDefinition(definition: GeneratedExerciseDefinition, type: ExerciseType[]) {
  return type.includes(definition.type);
}

export function isCPMExerciseDefinition(
  definition: GeneratedExerciseDefinition,
): definition is GeneratedCPMLikeExerciseDefinition {
  return 'cpm' in definition;
}

export function isCAMExerciseDefinition<T extends GeneratedExerciseDefinition>(
  definition: T,
): definition is T & GeneratedCAMLikeExerciseDefinition {
  return 'cam' in definition;
}

export function isEMGExerciseDefinition(
  definition: GeneratedExerciseDefinition,
): definition is GeneratedEMGLikeExerciseDefinition {
  return 'emg' in definition;
}

export function isEMSExerciseDefinition(
  definition: GeneratedExerciseDefinition,
): definition is GeneratedEMSLikeExerciseDefinition {
  return 'ems' in definition;
}

export function isGameExerciseDefinition(
  definition: GeneratedExerciseDefinition,
): definition is GeneratedGameLikeExerciseDefinition {
  return 'game' in definition;
}
