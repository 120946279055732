import { GeneratedEMGExerciseDefinition } from '../../types/GeneratedExerciseDefinition';

export const EmgExerciseIds = [
  'emg_10swork_10srest',
  'emg_5swork_5srest',
  'emg_biofeedback',
  'emg_endurance_training',
  'emg_glazer_protocol',
  'emg_low_back_pain_test',
  'emg_quick_flicks',
  'emg_relaxation_analysis',
  'emg_sorens_test',
  'emg_view',
  'emg_view_with_mvc',
] as const;

export type EMGExerciseId = (typeof EmgExerciseIds)[number];

export const getPredefinedStellaBioEMGExercise = (): { [key in EMGExerciseId]: GeneratedEMGExerciseDefinition } => ({
  emg_5swork_5srest: {
    tags: ['emg'],
    type: 'emg',
    calibrationFlow: 'emg/stella',
    presentationTemplate: 'time',
    emg: {
      program: {
        minRequiredChannels: 1,
        maxSupportedChannels: 4,
        emgCalibration: true,
        allowedCableChannelFeatures: ['emg', 'emg-pelvic'],
        hasGuideline: true,
        steps: [
          {
            repetitions: 5,
            initialRelaxation: 10,
            workTime: 5,
            restTime: 5,
            threshold: 50,
          },
        ],
      },
      parameters: {},
    },
  },
  emg_view: {
    calibrationFlow: 'emg-view/stella',
    presentationTemplate: 'time-increment',
    tags: ['emg'],
    type: 'emg',
    emg: {
      program: {
        minRequiredChannels: 1,
        maxSupportedChannels: 8,
        allowedCableChannelFeatures: ['emg', 'emg-pelvic'],
        emgCalibration: false,
        hasGuideline: false,
        steps: [],
      },
    },
  },
  emg_biofeedback: {
    calibrationFlow: 'emg/stella',
    presentationTemplate: 'time',
    tags: ['emg'],
    type: 'emg',
    emg: {
      program: {
        minRequiredChannels: 1,
        maxSupportedChannels: 8,
        allowedCableChannelFeatures: ['emg', 'emg-pelvic'],
        emgCalibration: true,
        hasGuideline: true,
        steps: [
          {
            repetitions: 5,
            initialRelaxation: 10,
            workTime: 5,
            restTime: 10,
            threshold: 50,
          },
        ],
      },
    },
  },
  emg_view_with_mvc: {
    calibrationFlow: 'emg-view-mvc/stella',
    presentationTemplate: 'time-increment',
    type: 'emg',
    tags: ['emg'],
    emg: {
      program: {
        minRequiredChannels: 1,
        maxSupportedChannels: 8,
        allowedCableChannelFeatures: ['emg', 'emg-pelvic'],
        emgCalibration: true,
        hasGuideline: false,
        steps: [],
      },
    },
  },
  emg_relaxation_analysis: {
    calibrationFlow: 'emg-view/stella',
    presentationTemplate: 'time-increment',
    type: 'emg',
    tags: ['emg'],
    emg: {
      program: {
        minRequiredChannels: 1,
        maxSupportedChannels: 8,
        allowedCableChannelFeatures: ['emg', 'emg-pelvic'],
        steps: [],
        emgCalibration: false,
        hasGuideline: false,
      },
    },
  },
  emg_low_back_pain_test: {
    calibrationFlow: 'emg/stella',
    presentationTemplate: 'time',
    type: 'emg',
    tags: ['emg'],
    emg: {
      program: {
        minRequiredChannels: 1,
        maxSupportedChannels: 8,
        allowedCableChannelFeatures: ['emg'],
        emgCalibration: true,
        hasGuideline: true,
        steps: [
          {
            repetitions: 1,
            initialRelaxation: 10,
            workTime: 5,
            restTime: 10,
            threshold: 50,
          },
          {
            repetitions: 1,
            initialRelaxation: 10,
            workTime: 5,
            restTime: 10,
            threshold: 50,
          },
          {
            repetitions: 1,
            initialRelaxation: 10,
            workTime: 5,
            restTime: 10,
            threshold: 50,
          },
          {
            repetitions: 1,
            initialRelaxation: 10,
            workTime: 5,
            restTime: 10,
            threshold: 50,
          },
          {
            repetitions: 1,
            initialRelaxation: 10,
            workTime: 5,
            restTime: 10,
            threshold: 50,
          },
        ],
      },
    },
  },
  emg_sorens_test: {
    calibrationFlow: 'emg/stella',
    presentationTemplate: 'time',
    type: 'emg',
    tags: ['emg'],
    emg: {
      program: {
        minRequiredChannels: 1,
        maxSupportedChannels: 8,
        allowedCableChannelFeatures: ['emg'],
        emgCalibration: true,
        hasGuideline: true,
        steps: [
          {
            repetitions: 1,
            initialRelaxation: 60,
            workTime: 120,
            restTime: 60,
            threshold: 20,
          },
        ],
      },
    },
  },
  emg_glazer_protocol: {
    calibrationFlow: 'emg-static/stella',
    presentationTemplate: 'time',
    type: 'emg',
    tags: ['emg', 'pelvic'],
    emg: {
      program: {
        minRequiredChannels: 1,
        maxSupportedChannels: 8,
        allowedCableChannelFeatures: ['emg', 'emg-pelvic'],
        emgCalibration: true,
        hasGuideline: true,
        steps: [
          {
            repetitions: 5,
            initialRelaxation: 60,
            workTime: 1,
            restTime: 10,
            threshold: 70,
          },
          {
            repetitions: 5,
            initialRelaxation: 0,
            workTime: 10,
            restTime: 10,
            threshold: 40,
          },
          {
            repetitions: 1,
            initialRelaxation: 0,
            workTime: 60,
            restTime: 60,
            threshold: 20,
          },
        ],
      },
    },
  },

  emg_10swork_10srest: {
    calibrationFlow: 'emg/stella',
    presentationTemplate: 'time',
    type: 'emg',
    tags: ['emg'],
    emg: {
      program: {
        minRequiredChannels: 1,
        maxSupportedChannels: 8,
        allowedCableChannelFeatures: ['emg', 'emg-pelvic'],
        emgCalibration: true,
        hasGuideline: true,
        steps: [
          {
            repetitions: 10,
            initialRelaxation: 10,
            workTime: 10,
            restTime: 10,
            threshold: 50,
          },
        ],
      },
    },
  },
  emg_quick_flicks: {
    calibrationFlow: 'emg/stella',
    presentationTemplate: 'time',
    type: 'emg',
    tags: ['emg'],
    emg: {
      program: {
        minRequiredChannels: 1,
        maxSupportedChannels: 8,
        allowedCableChannelFeatures: ['emg', 'emg-pelvic'],
        emgCalibration: true,
        hasGuideline: true,
        steps: [
          {
            repetitions: 5,
            initialRelaxation: 5,
            workTime: 1,
            restTime: 2,
            threshold: 70,
          },
          {
            repetitions: 5,
            initialRelaxation: 5,
            workTime: 1,
            restTime: 2,
            threshold: 70,
          },
          {
            repetitions: 5,
            initialRelaxation: 5,
            workTime: 1,
            restTime: 2,
            threshold: 70,
          },
        ],
      },
    },
  },
  emg_endurance_training: {
    calibrationFlow: 'emg/stella',
    presentationTemplate: 'time',
    type: 'emg',
    tags: ['emg'],
    emg: {
      program: {
        minRequiredChannels: 1,
        maxSupportedChannels: 8,
        allowedCableChannelFeatures: ['emg', 'emg-pelvic'],
        emgCalibration: true,
        hasGuideline: true,
        steps: [
          {
            repetitions: 2,
            initialRelaxation: 20,
            workTime: 30,
            restTime: 10,
            threshold: 20,
          },
          {
            repetitions: 2,
            initialRelaxation: 20,
            workTime: 30,
            restTime: 10,
            threshold: 20,
          },
        ],
      },
    },
  },
});
