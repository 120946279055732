import { DeepReadonly } from '@egzotech/exo-session';
import { CAMActiveMovementDirection, CAMProgram, CAMProgramPhase } from '@egzotech/exo-session/features/cam';
import { DurationBasedOn } from 'libs/exo-session-manager/core/settings/SettingsTemplates';

import { SensorsName } from '../types';

import { MotorPlacement, ProgramParameterDefinition, ProgramParameters } from './GeneratedProgramDefinition';

/**
 * Program parameters structure for CPM programs
 */
export interface CAMParameters {
  maxTime?: ProgramParameterDefinition<'s'>;
  maxTorque?: ProgramParameterDefinition<'kg'>;
  maxBackwardForceLimit?: ProgramParameterDefinition<'kg'>;
  durationBasedOn?: ProgramParameterDefinition<'none', DurationBasedOn>;
  phases?: ({
    time?: ProgramParameterDefinition<'s'>;
    maxSpeed?: ProgramParameterDefinition<'deg/s'>;
    repetitions?: ProgramParameterDefinition<'number'>;
    endActiveMovementForce?: ProgramParameterDefinition<'%'>;
    startActiveMovementForce?: ProgramParameterDefinition<'%'>;
    activeMovementDirection: ProgramParameterDefinition<'none', CAMActiveMovementDirection>;
    forceSource?: ProgramParameterDefinition<'none', SensorsName>;
    deadband?: ProgramParameterDefinition<'kg'>;
  } | null)[];
}

export type CAMProgramParameters = ProgramParameters & CAMParameters;

export interface CAMProgramAdditionalConfiguration {
  maxTime?: number;
  maxTorque?: number;
  maxBackwardForceLimit?: number;
  durationBasedOn?: DurationBasedOn;
  phases: (CAMProgramPhase & {
    endActiveMovementForce?: number;
    startActiveMovementForce?: number;
    forceSource?: SensorsName;
    forceTriggerSource?: SensorsName;
    maxSensitivity?: number;
    deadband?: number;
  })[];
}

/**
 * The interface that defines the full definition of the CPM program specified in parameters sheet
 */
export interface CAMProgramDefinitionPrimary {
  program: CAMProgram & CAMProgramAdditionalConfiguration;
  parameters?: CAMProgramParameters;
}

export interface CAMProgramDefinitionSynchronized {
  program: null;
  synchronized: MotorPlacement;
}

export interface CAMProgramDefinitionPassive {
  program: null;
}

export type CAMProgramDefinition =
  | CAMProgramDefinitionPrimary
  | CAMProgramDefinitionSynchronized
  | CAMProgramDefinitionPassive;

export type GeneratedCAMProgramDefinitionPrimary = DeepReadonly<CAMProgramDefinitionPrimary>;
export type GeneratedCAMProgramDefinitionSynchronized = DeepReadonly<CAMProgramDefinitionSynchronized>;
export type GeneratedCAMProgramDefinitionPassive = DeepReadonly<CAMProgramDefinitionPassive>;
export type GeneratedCAMProgramDefinition = DeepReadonly<CAMProgramDefinition>;

export function isGeneratedCAMProgramDefinitionPrimary(
  obj: GeneratedCAMProgramDefinition,
): obj is DeepReadonly<CAMProgramDefinitionPrimary> {
  return obj.program !== null && !('synchronized' in obj);
}

export function isGeneratedCAMProgramDefinitionSynchronized(
  obj: GeneratedCAMProgramDefinition,
): obj is DeepReadonly<CAMProgramDefinitionSynchronized> {
  return obj.program === null && 'synchronized' in obj;
}

export function isGeneratedCAMProgramDefinitionPassive(
  obj: GeneratedCAMProgramDefinition,
): obj is DeepReadonly<CAMProgramDefinitionSynchronized> {
  return obj.program === null && !('synchronized' in obj);
}
