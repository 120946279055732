import { DeepReadonly, ExoSession } from '@egzotech/exo-session';
import { Recordable } from '@egzotech/exo-session/features/common';
import { ExoCPMFeature, MovementDirection } from '@egzotech/exo-session/features/cpm';
import { ExoMotorFeature } from '@egzotech/exo-session/features/motor';
import { Logger } from '@egzotech/universal-logger-js';
import { defaultSensorNameByDevice } from 'config/defaultConfigProps';
import { Timers } from 'config/timers';
import { getMotorNameByProgram } from 'helpers/getMotorNameByProgram';
import { Signal, signal } from 'helpers/signal';
import { SettingsParameterId } from 'libs/exo-session-manager/core/settings/SettingsTemplates';
import { ConnectedChannelToMuscle } from 'types';
import {
  ChannelRoleInstances,
  getChannelRoles,
} from 'views/+patientId/training/+trainingId/_components/ConnectElectrodes';
import { ForceCalibrationData } from 'views/+patientId/training/+trainingId/_components/force-calibration/ForceCalibration';

import { CalibrationFlowStatesTypedData } from '../common/CalibrationFlow';
import EMGSignal from '../common/EMGSignal';
import { RemotePlugin } from '../common/RemotePlugin';
import { Recordings, SignalRecorderController } from '../common/SignalRecorderController';
import { Trigger } from '../common/Trigger';
import { TriggerManager } from '../common/TriggerManager';
import { TriggerThresholdEMG } from '../common/TriggerThresholdEMG';
import { TriggerThresholdForce } from '../common/TriggerThresholdForce';
import { exerciseActionTracker } from '../exerciseActionTracker';
import { DeviceType } from '../global/DeviceManager';
import { SensorForcePlugin } from '../global/SensorForcePlugin';
import { SpasticismPlugin } from '../global/SpasticismPlugin';
import { SettingsBuilder } from '../settings/SettingsBuilder';
import { CPMBasingData, CPMProgramData, CPMProgramDataOld, DeepWritable, MotorRange, SensorsName } from '../types';
import {
  GeneratedCPMProgramDefinitionPrimary,
  GeneratedCPMProgramDefinitionSynchronized,
  isGeneratedCPMProgramDefinitionPassive,
} from '../types/GeneratedCPMProgramDefinition';
import {
  CPMExerciseDefinition,
  GeneratedCPMExerciseDefinition,
  GeneratedExerciseDefinition,
} from '../types/GeneratedExerciseDefinition';
import { MotorPlacement } from '../types/GeneratedProgramDefinition';

import { Exercise, ExerciseFeature, ExericseInterruptType } from './Exercise';
import { FinishedReason } from './FinishedReason';

/**
 * @deprecated We will use signals instead, remove later
 */
export type CPMProgramEvents = {
  onCpmEnd: (payload: FinishedReason) => void;
  onCpmInit: () => void;
  onCpmRunningChange: (payload: boolean) => void;
  onCpmPrimaryMotorChange: (payload: MotorPlacement | null) => void;
  onCpmSecondaryMotorChange: (payload: MotorPlacement | null) => void;
  onCpmCurrentRepetitionChange: (payload: number) => void;
  onCpmEstimatedTotalDurationChange: (payload: number) => void;
  onCpmDefinitionChange: (payload: DeepWritable<GeneratedCPMExerciseDefinition>) => void;
  onCpmSnapshotChange: (
    payload: Pick<CPMProgramDataOld, 'currentDuration' | 'primaryAngle' | 'secondaryAngle'>,
  ) => void;
};

/**
 * @deprecated We will use signals instead, remove later
 */
export type CPMBasingEvents = {
  onCpmRangeChange: (payload: { motor: 'primary' | 'secondary'; range: MotorRange }) => void;
};

/**
 * @deprecated Events are not used anywhere
 */
export type CPMExerciseEvents = {
  onTotalRepetitionChange: (payload: { totalRepetition: number }) => void;
  onTotalDurationChange: (payload: { totalDuration: number }) => void;
};

export class CPMExercise implements Exercise {
  static readonly logger = Logger.getInstance('CPMExercise');

  private _cpmPrimaryFeature: ExoCPMFeature | null = null;
  private _cpmSynchronizedFeature: ExoMotorFeature | null = null;
  private _recorderController: SignalRecorderController | null = null;
  private _recordings: Recordings<'knee-angle' | 'ankle-angle'> = {};
  private _passiveMotorFeatures: ExoMotorFeature[] = [];

  private _triggerManager = new TriggerManager();
  private _waitForRelaxationFlag = false;

  private _primaryCPMProgramKey: MotorPlacement;
  private _synchronizedCPMProgramKey: MotorPlacement | null;
  private _programData: {
    cpm: CPMProgramData;
  };

  private _exerciseData: {
    readonly exerciseName: string | null;
    totalRepetition: Signal<number>;
    totalDuration: Signal<number>;
  };

  private _exerciseDefinition: CPMExerciseDefinition;

  // TODO: CPMBasingData could be changed into separated signals like CPMProgramData
  private _basingData: Signal<CPMBasingData> = signal({}, 'CPMExercise._basingData');
  private calibrationData: DeepReadonly<CalibrationFlowStatesTypedData> | null = null;

  private _settingsBuilder: SettingsBuilder;

  private _emgSignal: EMGSignal | null = null;
  private _sensorForcePlugin: SensorForcePlugin | null = null;
  private _spasticismPlugin: SpasticismPlugin | null = null;
  private _triggers: Trigger[] = [];
  private _remotePlugin: RemotePlugin;

  private _currentPhaseIndex = 0;

  private _timeInterval: NodeJS.Timeout | null = null;

  readonly prepared = signal(false, 'CPMExercise.prepared');
  readonly interrupt = signal<ExericseInterruptType>(null, 'CPMExercise.interrupt');

  constructor(
    exerciseDefinition: GeneratedCPMExerciseDefinition,
    private readonly exerciseName: string | null,
    private readonly session: ExoSession,
    private readonly deviceType: DeviceType,
  ) {
    this._programData = {
      cpm: {
        initialized: signal(false, 'CPMExercise._programData.initialized'),
        active: signal(false, 'CPMExercise._programData.active'),
        running: signal(false, 'CPMExercise._programData.running'),
        finished: signal(false, 'CPMExercise._programData.finished'),
        finishedReason: signal('exerciseFinished', 'CPMExercise._programData.finishedReason'),
        currentRepetition: signal(0, 'CPMExercise._programData.currentRepetition'),
        currentDuration: signal(0, 'CPMExercise._programData.currentDuration'),
        estimatedTotalDuration: signal(0, 'CPMExercise._programData.estimatedTotalDuration'),
        spasticismMechanismActived: signal(false, 'CPMExercise._programData.spasticismMechanismActived'),
        spasticismModalActive: signal(false, 'CPMExercise._programData.spasticismModalActive'),

        primaryAngle: signal(null, 'CPMExercise._programData.primaryAngle'),
        secondaryAngle: signal(null, 'CPMExercise._programData.secondaryAngle'),

        currentDirection: signal(null, 'CPMExercise._programData.currentDirection'),

        primaryMotor: signal(null, 'CPMExercise._programData.primaryMotor'),
        secondaryMotor: signal(null, 'CPMExercise._programData.secondaryMotor'),
        triggersTimestamps: [],
      },
    };

    this._exerciseData = {
      exerciseName,
      totalDuration: signal(0, 'CPMExercise.totalDuration'),
      totalRepetition: signal(0, 'CPMExercise.totalRepetition'),
    };

    this._exerciseDefinition = structuredClone(exerciseDefinition) as CPMExerciseDefinition;

    const primaryKey = getMotorNameByProgram(this._exerciseDefinition, 'primary');

    if (!primaryKey || typeof primaryKey !== 'string') {
      throw new Error('Cannot find primary CPM program in exercise definition');
    }

    this._primaryCPMProgramKey = primaryKey;

    const synchronizedKey = getMotorNameByProgram(this._exerciseDefinition, 'synchronized');
    this._synchronizedCPMProgramKey = synchronizedKey ? synchronizedKey : null;

    if (
      this.synchronizedProgramDefinition &&
      this.synchronizedProgramDefinition.synchronized !== this._primaryCPMProgramKey
    ) {
      throw new Error('Synchronized motor is different than the primary. This is not supported.');
    }

    exerciseActionTracker.timePoints.calibrationFlow.start = new Date();

    this._settingsBuilder = new SettingsBuilder(exerciseDefinition);
    this._remotePlugin = new RemotePlugin(this.session, this);
  }

  get programData() {
    return this._programData;
  }

  get programCpmData() {
    return this._programData.cpm;
  }

  get finished() {
    return this._programData.cpm.finished;
  }

  get exerciseData() {
    return this._exerciseData;
  }

  get basingData() {
    return this._basingData;
  }

  get settings() {
    return this._settingsBuilder;
  }

  get emgSignal() {
    return this._emgSignal;
  }

  get sensorForcePlugin() {
    return this._sensorForcePlugin;
  }

  get recordings() {
    return this._recordings;
  }

  get definition() {
    return this._exerciseDefinition as GeneratedCPMExerciseDefinition;
  }

  get triggers(): readonly Trigger[] {
    return this._triggers;
  }

  get triggeringType() {
    return this.primaryProgramDefinition.program.triggeringType ?? 'uni-directional';
  }

  get primaryProgramDefinition() {
    return this._exerciseDefinition.cpm[this._primaryCPMProgramKey]! as GeneratedCPMProgramDefinitionPrimary;
  }

  get connectedEMGChannels() {
    if (!this.calibrationData) {
      throw new Error('This exercise was never prepared');
    }

    const channelRolesInstances = this.calibrationData['channel-role-selector']?.channelRolesInstances as
      | ChannelRoleInstances
      | undefined;

    if (channelRolesInstances) {
      return Object.values(channelRolesInstances).reduce((prev, v) => {
        Object.entries(v).forEach(([k, v]) => {
          if (v.includes('emg')) prev.push(+k);
        });
        return prev;
      }, [] as number[]);
    }

    const connectedChannelsToMuscles = this.calibrationData['connect-electrodes']?.connectedChannelsToMuscles as
      | ConnectedChannelToMuscle[]
      | undefined;

    return (
      connectedChannelsToMuscles
        ?.filter(v => v.muscle.channelFeature.includes('emg') || v.muscle.channelFeature.includes('emg-pelvic'))
        .map(channel => channel.channelIndex) ?? []
    );
  }

  get remotePlugin() {
    return this._remotePlugin;
  }

  private get connectedTriggerChannels() {
    if (!this.calibrationData) {
      throw new Error('This exercise was never prepared');
    }

    const channelRoles = getChannelRoles(
      this.calibrationData['channel-role-selector']?.channelRolesInstances,
      this._cpmPrimaryFeature?.currentDirection === 'toStart' && this.triggeringType === 'bi-directional' ? 1 : 0,
    );

    if (!channelRoles) {
      return [];
    }

    return Object.entries(channelRoles)
      .filter(([_, v]) => v.includes('trigger'))
      .map(([k, _]) => +k);
  }

  private get synchronizedProgramDefinition() {
    return this._synchronizedCPMProgramKey
      ? (this._exerciseDefinition.cpm[this._synchronizedCPMProgramKey] as GeneratedCPMProgramDefinitionSynchronized)
      : null;
  }

  private get triggerForceSensors() {
    if (!this.calibrationData) {
      throw new Error('This exercise was never prepared');
    }

    const exerciseParameters = this.calibrationData['basing-settings']?.exerciseParameters as Partial<
      Record<SettingsParameterId, number | string>
    >;

    return exerciseParameters?.['cpm.0.forceTriggerSource']
      ? [exerciseParameters?.['cpm.0.forceTriggerSource'] as string]
      : [];
  }

  init() {
    if (this._programData.cpm.active.peek()) {
      CPMExercise.logger.debug('start', 'Program is already started');
      return;
    }

    this._cpmPrimaryFeature = this.session.activate(ExoCPMFeature, {
      motor: this._primaryCPMProgramKey,
    });

    this._passiveMotorFeatures = Object.entries(this._exerciseDefinition.cpm)
      .filter(([_, v]) => isGeneratedCPMProgramDefinitionPassive(v))
      .map(([k, _]) => this.session.activate(ExoMotorFeature, { name: k }));

    this.initializeTriggerables();
    this.initializeInterval();

    this._remotePlugin.init();

    this._programData.cpm.initialized.value = true;
    this._programData.cpm.active.value = true;
    this._programData.cpm.finished.value = false;

    this._basingData.value = {
      ...this._basingData.peek(),
      active: true,
    };

    this._programData.cpm.primaryMotor.value = this._primaryCPMProgramKey;
    this._programData.cpm.secondaryMotor.value = this._synchronizedCPMProgramKey;
  }

  prepare(calibrationData: DeepReadonly<CalibrationFlowStatesTypedData>) {
    this.calibrationData = calibrationData;

    if (this.deviceType === 'sidra-leg') {
      const primaryMotor = this._primaryCPMProgramKey as 'knee' | 'ankle';
      const primaryMotorRange =
        this.calibrationData[`leg-basing-max-flexion-and-extension-measurement-passive-${primaryMotor}`];

      if (typeof primaryMotorRange?.min !== 'number' || typeof primaryMotorRange.max !== 'number') {
        throw new Error(`Missing passive range for primary motor '${primaryMotor}'`);
      }

      this.setCPMRange({ min: primaryMotorRange.min, max: primaryMotorRange.max }, primaryMotor);

      if (this._synchronizedCPMProgramKey) {
        const synchronizedMotor = this._synchronizedCPMProgramKey as 'knee' | 'ankle';
        const synchronizedMotorRange =
          this.calibrationData[`leg-basing-max-flexion-and-extension-measurement-passive-${synchronizedMotor}`];

        if (typeof synchronizedMotorRange?.min !== 'number' || typeof synchronizedMotorRange.max !== 'number') {
          throw new Error(`Missing passive range for synchronized motor '${synchronizedMotor}'`);
        }

        this.setCPMRange({ min: synchronizedMotorRange.min, max: synchronizedMotorRange.max }, synchronizedMotor);
      }
    } else if (this.deviceType === 'meissa-ot') {
      const primaryMotorRange = this.calibrationData[`meissa-basing-range-of-movement`];

      if (typeof primaryMotorRange?.min !== 'number' || typeof primaryMotorRange.max !== 'number') {
        throw new Error(`Missing range for primary motor 'main'`);
      }

      this.setCPMRange({ min: primaryMotorRange.min, max: primaryMotorRange.max }, 'main');
    }

    const recordables: Recordable<'single' | 'multi'>[] = [];

    if (this.connectedEMGChannels.length > 0 || this.connectedTriggerChannels.length > 0) {
      this._emgSignal = EMGSignal.createFromCalibrationFlow(this.session, calibrationData, [this._triggerManager]);
      this._emgSignal.setTriggerChannels(this.connectedTriggerChannels);
      recordables.push(...this._emgSignal.getRecordables());
    }

    const sensorForcePluginRequiredSensors =
      this.triggerForceSensors.length > 0
        ? this.triggerForceSensors
        : // We need to pass here default sensor because we need to create SensorForcePlugin instance which is need to disable setReliefMode for cpm_classic exercise
          ([defaultSensorNameByDevice[this.deviceType]] as SensorsName[]);

    this._sensorForcePlugin = new SensorForcePlugin(this.session, sensorForcePluginRequiredSensors, [
      this._triggerManager,
    ]);

    const forceSensor = this.triggerForceSensors[0] as SensorsName;
    if (forceSensor === 'extension' && this._exerciseDefinition.type === 'cpm-force') {
      // This is fix for cpm force with extension trigger. We need to active torque sensor that spasticism works.
      this._sensorForcePlugin.activateAdditionalSensor('torque');
    }

    const motorName = this.programData.cpm.primaryMotor.peek();
    if (!motorName) {
      throw new Error('Motor Name is required');
    }
    if (!this.session.options.motor?.[motorName]) {
      throw new Error('Motor is not available');
    }
    const maxSpeed = this.session.options.motor[motorName].maxSpeed;
    const movementType = this._exerciseDefinition.cpm[this._primaryCPMProgramKey]?.program?.phases[0].movementType;
    this._spasticismPlugin = new SpasticismPlugin(
      defaultSensorNameByDevice[this.deviceType as DeviceType],
      this.primaryProgramDefinition.program.maxBackwardForceLimit,
      this._cpmPrimaryFeature,
      maxSpeed,
      movementType ?? 'normal',
      this.primaryProgramDefinition.program.phases[this._currentPhaseIndex].triggeringMethod,
    );
    this._spasticismPlugin.setSpasticismMechanismEnabled(true);

    this._sensorForcePlugin.onSensorData = data => this._spasticismPlugin?.handleSensorData(data);

    if (this.triggerForceSensors.length > 0) {
      recordables.push(...this._sensorForcePlugin.getRecordables());
    }

    if (recordables.length > 0) {
      this._recorderController = new SignalRecorderController(recordables, this.connectedEMGChannels);
    }
    this._settingsBuilder = new SettingsBuilder(
      (calibrationData['basing-settings']?.definition as GeneratedExerciseDefinition | undefined) ??
        this._exerciseDefinition,
    );

    const calibration = this.calibrationData?.['force-calibration'] as ForceCalibrationData | undefined;

    if (calibration && this.triggerForceSensors.length > 0) {
      this._sensorForcePlugin.signalsAdditionalData[forceSensor].positiveMaxValue.value =
        calibration.mvc?.[forceSensor] ?? 1;
      this._sensorForcePlugin.signalsAdditionalData[forceSensor].negativeMaxValue.value =
        calibration.mvc?.[`${forceSensor}Negative`] ?? 1;

      this._sensorForcePlugin.signalsAdditionalData[forceSensor].positiveThreshold.value =
        calibration.threshold?.[forceSensor] ?? 0.5;
      this._sensorForcePlugin.signalsAdditionalData[forceSensor].negativeThreshold.value =
        calibration.threshold?.[`${forceSensor}Negative`] ?? 0.5;
    }

    this.initializeFeatureCallbacks();
    this.initializeTriggers({ atExerciseStart: true });
    this.prepared.value = true;
  }

  setProgram(options?: { update?: boolean }) {
    if (!this._cpmPrimaryFeature) {
      throw new Error('Cannot set CPM program without activated CPM feature');
    }

    this._settingsBuilder.updateDefinition(this._exerciseDefinition);

    this._exerciseData.totalDuration.value = this.primaryProgramDefinition.program?.phases[0].time ?? 0;

    this._exerciseData.totalRepetition.value = this.primaryProgramDefinition.program?.phases[0].repetitions ?? 0;

    const pauseTimeInROMMax = this.primaryProgramDefinition.program?.phases[0].pauseTimeInROMMax ?? 0;
    const pauseTimeInROMMin = this.primaryProgramDefinition.program?.phases[0].pauseTimeInROMMin ?? 0;

    if (pauseTimeInROMMin || pauseTimeInROMMax) {
      if (!this.primaryProgramDefinition.program.phases.some(v => v.trigger === 'direction-change')) {
        throw new Error(
          'Invalid program definition. In order to set pause time in ROM min/max at least 1 phase must have defined trigger when direction change.',
        );
      }
    }

    const movementType = this.primaryProgramDefinition.program?.phases[0].movementType ?? 'normal';

    this._triggerManager.setTriggerDelay(
      'cpm/direction-change-at-start',
      (movementType === 'normal' ? pauseTimeInROMMin : pauseTimeInROMMax) * 1000,
    );
    this._triggerManager.setTriggerDelay(
      'cpm/direction-change-at-end',
      (movementType === 'normal' ? pauseTimeInROMMax : pauseTimeInROMMin) * 1000,
    );
    this.primaryProgramDefinition.program.phases[0].pauseTimeInROMMax = pauseTimeInROMMax;
    this.primaryProgramDefinition.program.phases[0].pauseTimeInROMMin = pauseTimeInROMMin;

    if (options?.update) {
      this._cpmPrimaryFeature.updateProgram(this.primaryProgramDefinition.program);
    } else {
      this._cpmPrimaryFeature.setProgram(this.primaryProgramDefinition.program);
    }
  }

  supports(feature: ExerciseFeature): boolean {
    switch (feature) {
      case 'cable':
        return Boolean(this.emgSignal);
    }

    return false;
  }

  play() {
    if (this.finished.peek()) {
      CPMExercise.logger.warn('play', 'Cannot play program that was finished');
      return;
    }

    if (!this._programData.cpm.active.peek()) {
      CPMExercise.logger.debug('play', 'Cannot play program that is ended or not yet started');
      return;
    }

    if (this._programData.cpm.running.peek()) {
      CPMExercise.logger.debug('play', 'Program is already running');
      return;
    }

    if (!this._cpmPrimaryFeature) {
      throw new Error('Cannot play program without activated CPM feature');
    }

    const { primaryCpmRange, secondaryCpmRange } = this._basingData.peek();

    if (!primaryCpmRange) {
      throw new Error('Cannot play program without set CPM range for primary motor');
    }

    if (this._synchronizedCPMProgramKey && !secondaryCpmRange) {
      throw new Error('Cannot play program without set CPM range for secondary motor');
    }

    if (!this.calibrationData) {
      throw new Error('Cannot play program on during calibration');
    }

    if (this._programData.cpm.spasticismMechanismActived.peek()) {
      CPMExercise.logger.debug('play', 'Cannot play program because spasticism mechanism is active');
      return;
    }

    if (this.programData.cpm.spasticismModalActive.peek()) {
      CPMExercise.logger.debug('play', 'Cannot play program because spasticism modal is display');
      return;
    }

    const maxBackwardForceLimit = this.primaryProgramDefinition.program.maxBackwardForceLimit;
    if (maxBackwardForceLimit) {
      this._spasticismPlugin?.setMaxBackwardForceLimit(maxBackwardForceLimit);
    }

    if (
      !this.programData.cpm.spasticismModalActive.peek() &&
      !this._programData.cpm.spasticismMechanismActived.peek()
    ) {
      const spasticismSensorName = defaultSensorNameByDevice[this.deviceType as DeviceType];
      if (!spasticismSensorName) return;
      if (this._cpmPrimaryFeature && this._cpmPrimaryFeature.motorFeature) {
        this._cpmPrimaryFeature.motorFeature.setCoupling('force', spasticismSensorName, {
          positive: {
            sensitivity: 0,
            deadband: 0,
          },
          negative: {
            sensitivity: 0,
            deadband: 0,
          },
        });
      } else {
        throw new Error("Force coupling reset doesn't work.");
      }
    }

    this.initializeInterval();
    this._triggerManager.resume();
    this.setCPMRange({ min: primaryCpmRange.min, max: primaryCpmRange.max }, this._primaryCPMProgramKey);
    if (secondaryCpmRange && this._synchronizedCPMProgramKey) {
      this.setCPMRange({ min: secondaryCpmRange.min, max: secondaryCpmRange.max }, this._synchronizedCPMProgramKey);
    }

    this._cpmPrimaryFeature.start();
    this._emgSignal?.enable(this._emgSignal.channels);
    this._sensorForcePlugin?.startSensorForceMeasurement();

    if (this._recorderController?.started) {
      this._recorderController?.resume();
    } else {
      this._recorderController?.start();
    }

    if (this._cpmPrimaryFeature.reachedStart) {
      this._cpmPrimaryFeature.onDirectionChange?.('toEnd');
    } else if (this._cpmPrimaryFeature.reachedEnd) {
      this._cpmPrimaryFeature.onDirectionChange?.('toStart');
    }

    if (!exerciseActionTracker.timePoints.exercise?.start) {
      exerciseActionTracker.timePoints.exercise.start = new Date();
    }

    exerciseActionTracker.add('activity', 'play');

    const primaryCPMRange = this._basingData.peek().primaryCpmRange;
    if (this._cpmSynchronizedFeature && primaryCPMRange && this._synchronizedCPMProgramKey) {
      this._cpmSynchronizedFeature?.setCoupling('motor', this._primaryCPMProgramKey, {
        leadingRange: [primaryCPMRange.min, primaryCPMRange.max],
      });
    }

    this._programData.cpm.running.value = true;
    const currentDirection = this._programData.cpm.currentDirection.value;
    if (currentDirection !== null) {
      this._spasticismPlugin?.setCurrentDirection(currentDirection);
    }
    const triggeringMethod = this.primaryProgramDefinition.program.phases[this._currentPhaseIndex].triggeringMethod;
    if (triggeringMethod) {
      this._spasticismPlugin?.setTriggeringMethod(
        this.primaryProgramDefinition.program.phases[this._currentPhaseIndex].triggeringMethod,
      );
    }
  }

  hold() {
    if (!this._programData.cpm.active.peek()) {
      CPMExercise.logger.debug('hold', 'Cannot hold program that is ended or not yet started');
      return;
    }
    if (!this._programData.cpm.running.peek()) {
      CPMExercise.logger.debug('hold', 'Program is already stopped');
      return;
    }
    if (!this._cpmPrimaryFeature) {
      throw new Error('Cannot hold program without fully activated CPM features');
    }
    this._cpmPrimaryFeature.hold();
  }

  pause(options?: { endPause?: boolean; spasticism?: boolean }) {
    if (!this._cpmPrimaryFeature) {
      throw new Error('Cannot pause program without activated CPM feature');
    }
    if (!this._programData.cpm.active.peek()) {
      CPMExercise.logger.warn('pause', 'Cannot pause program that is ended or not yet started');
      return;
    }
    if (!this._programData.cpm.running.peek()) {
      CPMExercise.logger.debug('play', 'Program is already stopped');
      return;
    }

    this._triggerManager.pause();

    if (!options?.spasticism) {
      this._cpmPrimaryFeature.stop();
      this._cpmSynchronizedFeature?.stop();
    }

    this._emgSignal?.disable();
    this._recorderController?.pause();

    if (!options?.endPause) {
      exerciseActionTracker.add('activity', 'pause');
    }

    this._programData.cpm.running.value = false;
  }

  end(reason: FinishedReason = 'exerciseFinished') {
    if (!this._programData.cpm.active.peek()) {
      CPMExercise.logger.debug('end', 'Cannot end program that is not started');
      return;
    }
    if (this._programData.cpm.finished.peek()) {
      CPMExercise.logger.debug('end', 'Program is already finished');
      return;
    }
    if (!this._cpmPrimaryFeature) {
      throw new Error('Cannot end program without activated CPM feature');
    }

    this.pause({ endPause: true });
    this._sensorForcePlugin?.setReliefMode(false);

    if (this._recorderController) {
      this._recordings = this._recorderController.stop();
    }

    if (exerciseActionTracker.timePoints.exercise?.start && !exerciseActionTracker.timePoints.exercise?.end) {
      exerciseActionTracker.activeChannels = this.connectedEMGChannels;
    }

    this._programData.cpm.finishedReason.value = reason;
    this._programData.cpm.running.value = false;
    this._programData.cpm.active.value = false;
    this._programData.cpm.finished.value = true;

    this._basingData.value = {
      ...this._basingData.peek(),
      active: false,
    };
  }

  destroy() {
    this.destroyInterval();

    if (!this._cpmPrimaryFeature) {
      CPMExercise.logger.debug('destroy', 'CPM Feature is already disposed');
      return;
    }

    this.end();
    this._programData.cpm.initialized.value = false;

    this._triggerManager.cleanup();
    this._emgSignal?.dispose();
    this._sensorForcePlugin?.dispose();
    this._cpmPrimaryFeature.dispose();
    this._cpmPrimaryFeature = null;
    this._cpmSynchronizedFeature?.dispose();
    this._cpmSynchronizedFeature = null;
    this._passiveMotorFeatures.forEach(v => v.dispose());
    this._remotePlugin.dispose();
    this._recorderController = null;
  }

  clearInterrupt(): void {}

  setCPMRange(range: DeepReadonly<MotorRange>, motorId: MotorPlacement) {
    if (motorId === this._primaryCPMProgramKey) {
      if (!this._cpmPrimaryFeature) {
        throw new Error('Cannot set CPM range for primary motor without activated feature');
      }

      this._basingData.value = {
        ...this._basingData.peek(),
        primaryCpmRange: { ...range },
      };

      this._cpmPrimaryFeature.setRange(range.min, range.max);
      if (this._settingsBuilder.data.cpm) {
        this._programData.cpm.estimatedTotalDuration.value = this._settingsBuilder.data.cpm.estimateTotalDuration(
          range,
          this._exerciseDefinition,
        );
      }
    } else if (motorId == this._synchronizedCPMProgramKey) {
      if (!this._cpmSynchronizedFeature) {
        throw new Error('Cannot set CPM range for synchronized motor without activated feature');
      }

      this._basingData.value = {
        ...this._basingData.peek(),
        secondaryCpmRange: { ...range },
      };

      this._cpmSynchronizedFeature.setRange(range.min, range.max);
    } else {
      const motorFeature = this._passiveMotorFeatures.find(v => v.name === motorId);

      if (!motorFeature) {
        throw new Error(`Motor '${motorId}' is not available on this exercise`);
      }

      CPMExercise.logger.info('setCPMRange', 'Setting motor range for passive motor.');
      motorFeature.setRange(range.min, range.max);
    }
  }

  getCPMRange(motorId: MotorPlacement) {
    if (motorId === this._primaryCPMProgramKey) {
      return this._basingData.peek().primaryCpmRange ?? null;
    }

    if (motorId === this._synchronizedCPMProgramKey) {
      return this._basingData.peek().secondaryCpmRange ?? null;
    }

    throw new Error(`Motor ${motorId} is not available for this CPM exercise.`);
  }

  getMotor(motorId: MotorPlacement) {
    if (motorId === this._primaryCPMProgramKey) {
      return this._cpmPrimaryFeature?.motorFeature ?? null;
    }

    if (motorId === this._synchronizedCPMProgramKey) {
      return this._cpmSynchronizedFeature ?? null;
    }

    throw new Error(`Motor ${motorId} is not available for this CPM exercise.`);
  }

  private initializeInterval() {
    if (this._timeInterval !== null) {
      return;
    }
    this._timeInterval = setInterval(this.onTimeChange.bind(this), Timers.EXERCISE_DATA_REFRESH_INTERVAL);
  }

  private destroyInterval() {
    if (this._timeInterval !== null) {
      clearInterval(this._timeInterval);
      this._timeInterval = null;
    }
  }

  private initializeFeatureCallbacks() {
    if (!this._cpmPrimaryFeature) {
      throw new Error('Cannot initialize features callbacks without fully activated features');
    }

    const onPrimaryAngleCallback = (values: Float32Array) => {
      this.updateAngleStateData(this._primaryCPMProgramKey, values.at(-1));
    };

    const onSynchronizedAngleCallback = (values: Float32Array) => {
      if (!this._synchronizedCPMProgramKey) {
        throw new Error('Cannot run synchronized angle callback without synchronized motor');
      }

      this.updateAngleStateData(this._synchronizedCPMProgramKey, values.at(-1));
    };

    const onRepetitionCallback = (_: number) => {
      this._triggerManager.executeTrigger('cpm/repetition-change');

      const phases = this.primaryProgramDefinition.program.phases;

      if (this._currentPhaseIndex >= phases.length) {
        this.end();
        return;
      }
    };

    const onFinishCallback = () => {
      this.end();
    };

    const onInterruptCallback = (reason: 'emergency' | 'spasticism') => {
      if (reason === 'emergency') {
        this.pause();
      }

      if (reason === 'spasticism' && this._primaryCPMProgramKey !== 'main') {
        this.pause({ spasticism: true });
        this._programData.cpm.spasticismMechanismActived.value = true;
        if (!this.calibrationData) {
          throw new Error('CalibrationData is null.');
        }
        const primaryMotorRange =
          this.calibrationData[
            `leg-basing-max-flexion-and-extension-measurement-passive-${this._primaryCPMProgramKey}`
          ];

        if (this._cpmSynchronizedFeature) {
          if (primaryMotorRange?.min && primaryMotorRange?.max) {
            this._cpmSynchronizedFeature?.setCoupling('motor', this._primaryCPMProgramKey, {
              leadingRange: [primaryMotorRange.min, primaryMotorRange.max],
            });
          }

          const motorName = this.programData.cpm.secondaryMotor.peek();
          if (motorName) {
            const maxSpeed = this.session.candidate.options.motor?.[motorName].maxSpeed;
            if (maxSpeed) {
              this._cpmSynchronizedFeature.setMaxSpeed(maxSpeed);
            } else {
              throw new Error('MaxSpeed is undefined');
            }
          } else {
            throw new Error('Motor name is null');
          }
        }
        exerciseActionTracker.add('alerts', 'spasticism-active');
      } else if (reason === 'spasticism' && this._primaryCPMProgramKey === 'main') {
        this.pause({ spasticism: true });
        this._programData.cpm.spasticismMechanismActived.value = true;
        exerciseActionTracker.add('alerts', 'spasticism-active');
      }
    };

    const onReleaseSpasticismCallback = () => {
      this._programData.cpm.spasticismMechanismActived.value = false;
      exerciseActionTracker.add('alerts', 'spasticism-inactive');
    };

    this._cpmPrimaryFeature.onAngle = onPrimaryAngleCallback;
    this._cpmPrimaryFeature.onRepetition = onRepetitionCallback;
    this._cpmPrimaryFeature.onFinish = onFinishCallback;
    if (this._spasticismPlugin) {
      this._spasticismPlugin.onDetectSpasticism = onInterruptCallback;
      this._spasticismPlugin.onReleaseSpasticism = onReleaseSpasticismCallback;
    }

    if (this._cpmSynchronizedFeature) {
      this._cpmSynchronizedFeature.onAngle = onSynchronizedAngleCallback;
    }

    this._cpmPrimaryFeature.onDirectionChange = (movementDirection: MovementDirection) => {
      if (
        movementDirection === 'toEnd' &&
        this.primaryProgramDefinition.program.phases[this._currentPhaseIndex].trigger === 'direction-change'
      ) {
        // if there is the first repetition, there is no delay before executing trigger 'cpm/direction-change-at-start'
        this._triggerManager.executeTrigger('cpm/direction-change-at-start', !this._cpmPrimaryFeature?.repetition ?? 0);
      }
      if (
        movementDirection === 'toStart' &&
        this.primaryProgramDefinition.program.phases[this._currentPhaseIndex].trigger === 'direction-change'
      ) {
        this._triggerManager.executeTrigger('cpm/direction-change-at-end');
      }
      if (this.primaryProgramDefinition.program.phases[this._currentPhaseIndex].triggeringMethod) {
        // The reason for this is explained in the SpasticismPlugin, where we set setSpasticismMechanismEnabled to true.
        this._spasticismPlugin?.setSpasticismMechanismEnabled(false);
      }
    };

    this._passiveMotorFeatures.forEach(
      v =>
        (v.onAngle = (values: Float32Array) => {
          this.updateAngleStateData(v.name as MotorPlacement, values.at(-1));
        }),
    );
  }

  private onTimeChange() {
    const phases = this.primaryProgramDefinition.program.phases ?? [];
    if (this._currentPhaseIndex < phases.length) {
      const currentDuration = this._cpmPrimaryFeature?.currentDuration ?? 0;
      this._programData.cpm.currentDuration.value = currentDuration;
    }
  }

  private updateAngleStateData(motor: MotorPlacement, data: number | undefined) {
    if (typeof data === 'number') {
      switch (motor) {
        case this._primaryCPMProgramKey:
          this._programData.cpm.primaryAngle.value = data;
          break;
        case this._synchronizedCPMProgramKey:
          this._programData.cpm.secondaryAngle.value = data;
          break;
      }
    }
  }

  private conditionalySetWaitingRelaxationFlag() {
    // set flag for triggered exercised
    if (this._exerciseDefinition.type === 'cpm-emg' || this._exerciseDefinition.type === 'cpm-force') {
      this._waitForRelaxationFlag = true;
    }
  }

  private initializeTriggerables() {
    if (!this._cpmPrimaryFeature) {
      throw new Error('Cannot initialize triggerables without activated CPM feature');
    }

    this._triggerManager.cleanup();

    if (this.primaryProgramDefinition.program.phases?.some(phase => !!phase.trigger)) {
      this._triggerManager.addCondition('cpm/can-trigger', () => Boolean(this._cpmPrimaryFeature?.canTrigger()));
      this._triggerManager.addTrigger('cpm/start-movement', [], () => this._cpmPrimaryFeature?.trigger());

      this._triggerManager.addTrigger('cpm/direction-change-at-start', [], () => {
        this._programData.cpm.currentDirection.value = this._cpmPrimaryFeature?.currentDirection ?? null;
        const currentDirection = this._programData.cpm.currentDirection.value;
        if (currentDirection !== null) {
          this._spasticismPlugin?.setCurrentDirection(currentDirection);
        }
        const repetition = this._cpmPrimaryFeature?.repetition ?? 0;
        this._programData.cpm.currentRepetition.value = repetition;
        this.conditionalySetWaitingRelaxationFlag();

        this.initializeTriggers();
        if (this._exerciseDefinition.type === 'cpm') {
          this._triggerManager.executeTrigger('cpm/movement-from-start');
        }
      });

      this._triggerManager.addTrigger('cpm/repetition-change', [], () => {
        if (
          this.primaryProgramDefinition.program.phases[this._currentPhaseIndex].trigger === 'repetition-start' &&
          this._exerciseDefinition.type === 'cpm'
        ) {
          this._triggerManager.executeTrigger('cpm/movement-from-start');
        }

        if (this.primaryProgramDefinition.program.phases[this._currentPhaseIndex].trigger === 'repetition-start') {
          const repetition = this._cpmPrimaryFeature?.repetition ?? 0;
          this._programData.cpm.currentRepetition.value = repetition;
          if (this._exerciseDefinition.type === 'cpm') {
            this._triggerManager.executeTrigger('cpm/movement-from-start');
          }
        }
      });

      this._triggerManager.addTrigger('cpm/direction-change-at-end', [], () => {
        this.conditionalySetWaitingRelaxationFlag();
        this._programData.cpm.currentDirection.value = this._cpmPrimaryFeature?.currentDirection ?? null;
        const currentDirection = this._programData.cpm.currentDirection.value;
        if (currentDirection !== null) {
          this._spasticismPlugin?.setCurrentDirection(currentDirection);
        }
        this.initializeTriggers();
        if (this.triggeringType === 'uni-directional' && !this._waitForRelaxationFlag) {
          this._triggerManager.executeTrigger('cpm/movement-from-end');
        }
      });

      if (this.primaryProgramDefinition.program.phases?.some(phase => phase.trigger === 'direction-change')) {
        this._triggerManager.addTrigger('cpm/movement-from-end', [], () =>
          this._triggerManager.executeTrigger('cpm/start-movement'),
        );
      }
      if (
        this.primaryProgramDefinition.program.phases?.some(
          phase => phase.trigger === 'direction-change' || phase.trigger === 'repetition-start',
        )
      ) {
        this._triggerManager.addTrigger('cpm/movement-from-start', [], () =>
          this._triggerManager.executeTrigger('cpm/start-movement'),
        );
      }

      this._triggerManager.addCondition('active-signal/can-trigger', () => false);
    }

    // onHold and resume
    this._triggerManager.addCondition(
      'cpm/is-returning',
      () => this._cpmPrimaryFeature?.currentDirection === 'toStart',
    );
    this._triggerManager.addCondition('cpm/is-held', () => Boolean(this._cpmPrimaryFeature?.isHeld));
    this._triggerManager.addCondition('cpm/can-hold', () => {
      const canHold = Boolean(
        this._cpmPrimaryFeature &&
          !this._cpmPrimaryFeature.canTrigger() &&
          this.primaryProgramDefinition.program.phases[this._currentPhaseIndex].triggeringMethod === 'triggerAndHold' &&
          this._cpmPrimaryFeature.currentDirection === 'toEnd' &&
          this._cpmPrimaryFeature.enabled,
      );
      return canHold;
    });
    this._triggerManager.addCondition('cpm/can-hold-return', () => {
      const canHoldReturn = Boolean(
        this._cpmPrimaryFeature &&
          !this._cpmPrimaryFeature.canTrigger() &&
          this.primaryProgramDefinition.program.phases[this._currentPhaseIndex].triggeringMethod === 'triggerAndHold' &&
          this._cpmPrimaryFeature.currentDirection === 'toStart' &&
          this._cpmPrimaryFeature.enabled &&
          this.triggeringType === 'bi-directional',
      );
      return canHoldReturn;
    });

    // these triggers should have only one difference cpm/can-hold / cpm/can-hold-return
    this._triggerManager.addTrigger('cpm/on-hold', ['!active-signal/can-trigger', 'cpm/can-hold'], () => this.hold());
    this._triggerManager.addTrigger('cpm/on-hold-return', ['!active-signal/can-trigger', 'cpm/can-hold-return'], () =>
      this.hold(),
    );
    // these triggers should have only one difference in conditions - cpm/is-returning
    this._triggerManager.addTrigger(
      'cpm/on-resume',
      ['active-signal/can-trigger', '!cpm/is-returning', 'cpm/is-held', 'cpm/can-trigger'],
      () => this._triggerManager.executeTrigger('cpm/start-movement'),
    );
    this._triggerManager.addTrigger(
      'cpm/on-resume-return',
      ['active-signal/can-trigger', 'cpm/is-returning', 'cpm/is-held', 'cpm/can-trigger'],
      () => this._triggerManager.executeTrigger('cpm/start-movement'),
    );

    if (this._synchronizedCPMProgramKey) {
      if (!this.primaryProgramDefinition.program.phases.every(v => !!v.trigger)) {
        throw new Error(
          'Invalid program definition. Synchronized program requires all phases to have a defined trigger.',
        );
      }

      this._cpmSynchronizedFeature = this.session.activate(ExoMotorFeature, { name: this._synchronizedCPMProgramKey });
    }
  }

  /**
   * It sets up triggers when exercise starts and when direction of the movement changes
   * @param args  optional `atExerciseStart` if set to true, changes force threshold source from `SensorForcePlugin` additional signals to calibration flow data
   */
  private initializeTriggers(args: { atExerciseStart: boolean } = { atExerciseStart: false }) {
    // we have to maintain the initial reference of this._triggers in order to properly clear not used signals inside TriggerThresholdEMG every time we create new set of triggers
    this._triggers.length = 0;

    if (this._emgSignal) {
      for (let i = 0; i < this.connectedTriggerChannels.length; i++) {
        const emgChannelTrigger = this.connectedTriggerChannels[i];

        this._triggers.push(new TriggerThresholdEMG(this._emgSignal, emgChannelTrigger));
      }
    }
    const movementType = this.primaryProgramDefinition.program?.phases[0].movementType ?? 'normal';

    const forcePrefix = movementType === 'normal' ? 'positive' : 'negative';

    if (this._sensorForcePlugin && this.triggerForceSensors.length > 0) {
      for (let i = 0; i < this.triggerForceSensors.length; i++) {
        const forceSensor = this.triggerForceSensors[i] as SensorsName;
        const trigger = new TriggerThresholdForce(this._sensorForcePlugin, forceSensor);
        const calibration = this.calibrationData?.['force-calibration'] as ForceCalibrationData | undefined;
        const forceThreshold =
          (args.atExerciseStart
            ? calibration?.threshold[forceSensor]
            : this._sensorForcePlugin.signalsAdditionalData[forceSensor][`${forcePrefix}Threshold`].peek()) ?? 0.5;

        trigger.setValue(calibration?.mvc[forceSensor] ?? 1);
        trigger.setThreshold(forceThreshold);
        this._triggers.push(trigger);
      }
    }

    if (this._triggers.length > 0 && this.primaryProgramDefinition.program.phases?.some(phase => !!phase.trigger)) {
      this._triggerManager.addCondition('active-signal/can-trigger', () => {
        if (this._waitForRelaxationFlag) {
          // wait until all signals are below their thresholds
          this._waitForRelaxationFlag = this._triggers.some(v => v.isTriggered());
          if (this._waitForRelaxationFlag) {
            return false;
          }
        }
        if (this.triggeringType === 'uni-directional' && this._cpmPrimaryFeature?.currentDirection === 'toStart') {
          // don't wait for trigger for backward movement
          return true;
        }
        const canTrigger = this._triggers.every(v => v.isTriggered());
        return canTrigger;
      });
      this._triggerManager
        .addTrigger(
          'active-signal/start-action',
          ['cpm/can-trigger', 'active-signal/can-trigger', '!cpm/is-held'],
          () => {
            this._programData.cpm.triggersTimestamps.push(new Date());
            if (!this._cpmPrimaryFeature) {
              return;
            }
            switch (this._cpmPrimaryFeature.currentDirection) {
              case 'toEnd':
                this._triggerManager.executeTrigger('cpm/movement-from-start');
                break;
              case 'toStart':
                this._triggerManager.executeTrigger('cpm/movement-from-end');
                break;
            }
          },
        )
        .whenTriggerIsNotWaiting('cpm/direction-change-at-end')
        .whenTriggerIsNotWaiting('cpm/direction-change-at-start');
    }
    const forceSensor = this.triggerForceSensors[0] as SensorsName;
    const spasticismTrigger = this._triggers.find(t => t.name === forceSensor);
    if (spasticismTrigger) {
      this._spasticismPlugin?.setSpasticismTrigger(spasticismTrigger);
    }
  }
}
